import axios from "axios"
import { toast } from "react-toastify"

const axiosVaylaClient = axios.create({
	baseURL: "https://avoinapi.vaylapilvi.fi/viitekehysmuunnin",
	headers: {
		Accept: "application/json",
		"Content-type": "application/json"
	}
})

axiosVaylaClient.interceptors.request.use(
	(req) => {
		return req
	},
	(error) => {
		const res = error.response
		if (res.status >= 500) {
			toast.error("Ei yhteyttä väyläviraston data palveluun.", { role: "global" })
		}
		console.error(error)
		return error
	}
)

axiosVaylaClient.interceptors.response.use(
	(res) => {
		// console.log(res)
		return res
	},
	(error) => {
		console.log(error)
		return error
	}
)

export default axiosVaylaClient
