import React from "react"
import { Typography, Box } from "@mui/material"

// Määrittele tyyppi yhdelle chat-kohteelle
interface CommentProps {
	userEmailFromLocal: string
	comment: {
		id?: number
		chatUser?: string
		createdOn?: any
		content?: string
		userEmail?: string
	}
}

const Comment: React.FC<CommentProps> = ({ comment, userEmailFromLocal }) => {
	const isCurrentUser = comment.userEmail === userEmailFromLocal
	const backgroundColor = isCurrentUser ? "#c6ffba" : "#F9EFEF"
	return (
		<Box
			sx={{
				backgroundColor: { backgroundColor },
				width: "calc(100% - 20px)",
				padding: "10px",
				borderRadius: "10px",
				overflow: "hidden",
				fontSize: "1rem",
				lineHeight: "1.5",
				paddingTop: "2px",
				whiteSpace: "pre-line",
				wordWrap: "break-word"
			}}
		>
			<Typography sx={{ fontWeight: "700", width: "100%", whiteSpace: "pre-line", wordWrap: "break-word" }}>
				{comment.chatUser} -{" "}
				{new Date(comment.createdOn).toLocaleString([], {
					hour12: false,
					year: "numeric",
					month: "numeric",
					day: "numeric",
					hour: "2-digit",
					minute: "2-digit"
				})}
			</Typography>
			<Typography sx={{ width: "100%", fontWeight: "500", whiteSpace: "pre-line", wordWrap: "break-word" }}>{comment.content}</Typography>
		</Box>
	)
}

export default Comment
