import React from "react"

import { Box, Dialog, DialogContent, IconButton, TableCell, TableRow, Typography } from "@mui/material"
import { useState } from "react"

import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import EditIcon from "@mui/icons-material/Edit"

import CustomDialogActions from "../../../../reusables/CustomDialogActions"
import CustomDialogTitle from "../../../../reusables/CustomDialogTitle"
import DialogCloseButton from "../../../../reusables/DialogCloseButton"
import EditEstablishmentDialog from "../../EditEstablishmentDialog"
import { tableCellHeaderStyles, tableCellStyles, tableCellTypographyStyles } from "../customStyles"
import { MutationIconButton } from "../../../../reusables/MutationButtonChipFab"

const PartnersEstablishmentRow = ({ unit, refetch, unitsViewType }: { unit: any; refetch: any; unitsViewType: UnitsViewType }) => {
	const [open, setOpen] = useState(false)
	const additionalInformation = unit?.additionalInformation
		? unit.additionalInformation.length > 40
			? unit.additionalInformation.slice(0, 40) + "..."
			: unit.additionalInformation
		: ""

	const propertyName = unit.propertyByPropertyId?.propertyName
		? unit.propertyByPropertyId?.propertyName.length > 15
			? unit.propertyByPropertyId?.propertyName.slice(0, 15) + "..."
			: unit.propertyByPropertyId?.propertyName
		: ""

	return (
		<>
			<Dialog open={open} fullWidth sx={{ zIndex: "1300" }}>
				<DialogCloseButton closeFunction={() => setOpen(false)} />
				<CustomDialogTitle>Yksikkötiedon muokkaaminen</CustomDialogTitle>
				<DialogContent>
					<EditEstablishmentDialog functionCallOnDataChange={refetch} id={unit.id} setOpenEdit={setOpen} />
				</DialogContent>
				<CustomDialogActions>
					<></>
				</CustomDialogActions>
			</Dialog>

			<TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
				{/* <TableCell sx={{ paddingBottom: "25px", paddingTop: "25px", width: "10px" }}>
					<Box
						sx={{
							width: "12px",
							height: "12px",
							background: unit.costCenterColor,
							borderRadius: "8px",
							marginRight: "15px",
							marginLeft: "10px",
						}}
					/>
				</TableCell> */}
				<TableCell sx={tableCellStyles}></TableCell>
				<TableCell>
					<Typography sx={tableCellTypographyStyles}>
						{unit.propertyByPropertyId?.mmlPropertyId} {propertyName}{" "}
					</Typography>
				</TableCell>
				{unitsViewType == "professional" && (
					<TableCell>
						<Typography sx={tableCellTypographyStyles}>{unit.roadUsageUnitByRoadUsageUnitId.roadUsageUnit}</Typography>
					</TableCell>
				)}
				<TableCell>
					<Typography sx={{ fontSize: "0.9rem", fontWeight: "500" }}> {additionalInformation}</Typography>
				</TableCell>
				<TableCell>
					<Typography sx={tableCellTypographyStyles}>{unit.roadUnits}</Typography>
				</TableCell>
				<TableCell sx={{ textAlign: "end", width: "20px" }}>
					<MutationIconButton size="small" onClick={() => setOpen(!open)}>
						<EditIcon sx={{ width: "20px", color: "rgba(0, 0, 0, 0.54)" }} />
					</MutationIconButton>
				</TableCell>
			</TableRow>
		</>
	)
}

export default PartnersEstablishmentRow
