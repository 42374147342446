/**
 * Yksiköinnin matkanmittaustyökalun markkereiden localStoragen avaimen alkuosa.
 * Koko avain on muodossa "unitizationRoadDistanceCalculationMarkers" + yksiköinnin id(unitizationId)
 */
const distanceCalculationMarkersKey = "unitizationRoadDistanceCalculationMarkers"

/**
 * Tallentaa markkereiden koordinaattipisteet(markersCoordinatepoints) localStorageen avaimella "unitizationRoadDistanceCalculationMarkers" + unitizationId
 * @param markersCoordinatepoints
 * @param unitizationId
 */
const setMarkersInStorage = (markersCoordinatepoints: [[number, number], [number, number]], unitizationId: number) => {
	localStorage.setItem(distanceCalculationMarkersKey + unitizationId, JSON.stringify(markersCoordinatepoints))
}

/**
 *  palauttaa markkereiden koordinaattipisteet listana
 * @param unitizationId
 * @returns [ [lat, lng], [lat, lng] ]
 */
const getMarkersFromStorage = (unitizationId: number): [[number, number], [number, number]] | [] => {
	const markers = localStorage.getItem(distanceCalculationMarkersKey + unitizationId)
	return markers ? JSON.parse(markers) : []
}

/**
 * Poistaa yksiköinnin markkereiden koordinaattipisteet localStoragesta
 * @param unitizationId number
 */
const removeAllMarkersFromStorage = () => {
	// Käy läpi kaikki localStorageen tallennetut avaimet
	for (let i = 0; i < localStorage.length; i++) {
		// Haetaan avain indeksin perusteella
		const key = localStorage.key(i)
		// Tarkistetaan, alkaako avain halutulla merkkijonolla
		if (key?.startsWith(distanceCalculationMarkersKey)) {
			// Jos alkaa, poistetaan avain ja arvo localStoragesta
			localStorage.removeItem(key)
		}
	}
}

export { distanceCalculationMarkersKey, setMarkersInStorage, getMarkersFromStorage, removeAllMarkersFromStorage }
