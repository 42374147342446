const unitizationIdKey = "activeUnitization"
const ActiveUnitizationCostCenterKey = "activeUnitizationCostCenterId"

const storeActiveUnitization = (unitization: UnitizationType) => {
	window.localStorage.setItem(unitizationIdKey, JSON.stringify(unitization))
}

const getStoredActiveUnitization = () => {
	const unitization = window.localStorage.getItem(unitizationIdKey)
	if (unitization) {
		return JSON.parse(unitization)
	} else {
		return undefined
	}
}

const storeActiveCostCenterId = (costCenterId: number) => {
	window.localStorage.setItem(ActiveUnitizationCostCenterKey, costCenterId.toString())
}

const getStoredActiveCostCenterId = () => {
	const costCenter = window.localStorage.getItem(ActiveUnitizationCostCenterKey)
	if (costCenter) {
		return JSON.parse(costCenter)
	} else {
		return undefined
	}
}

const clearActiveCostCenterIdStorage = () => {
	window.localStorage.removeItem(ActiveUnitizationCostCenterKey)
}

const clearActiveUnitizationStorage = () => {
	window.localStorage.removeItem(unitizationIdKey)
}

const clearWholeStorage = () => {
	clearActiveCostCenterIdStorage()
	clearActiveUnitizationStorage()
}

export {
	storeActiveUnitization,
	getStoredActiveUnitization,
	storeActiveCostCenterId,
	getStoredActiveCostCenterId,
	clearWholeStorage,
	clearActiveUnitizationStorage,
	clearActiveCostCenterIdStorage
}
