const addSearchPropertyLayer = (map: any) => {
	// Add a source and layer to the map.current
	if (map && map.current) {
		// Define the GeoJSON source
		const source = {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		}

		// Define the layer
		const layer = {
			id: "search-property-layer",
			type: "line", // Set the layer type to 'line' for LineString geometries
			source: "search-property-source", // Reference the source by its ID
			paint: {
				"line-color": "#FF0000", // Set the color for the LineStrings
				"line-width": 2 // Set the width for the LineStrings
			}
		}

		// Check if the source already exists and remove it before adding
		if (map.current.getSource("search-property-source")) {
			map.current.removeSource("search-property-source")
		}

		// Check if the layer already exists and remove it before adding
		if (map.current.getLayer("search-property-layer")) {
			map.current.removeLayer("search-property-layer")
		}

		// Add the source to the map
		map.current.addSource("search-property-source", source)

		// Add the layer to the map
		map.current.addLayer(layer)
	} else {
		console.error("Map or map.current is not defined in addSearchPropertyLayer function")
	}
}

export default addSearchPropertyLayer
