import { MapProviderDispatch, mapProviderStateType } from "../../utils/providers/mapProvider/mapProvider"

export const addMapCostCentersLayersToProvider = (state: mapProviderStateType, data: Record<string, any>, dispatch: MapProviderDispatch) => {
	const tempMapLayers = [...state.mapLayers]
	for (let i = 0, len = data?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
		const cc = data?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId.nodes[i]
		if (state.selectedCostCenterId === cc.id || state.selectedCostCenterId === undefined) {
			if (tempMapLayers.some((layer) => layer.displayName == cc.name)) continue
			tempMapLayers.push({
				type: "line",
				allowOpacityChange: true,
				userCanToggle: true,
				displayName: cc.name,
				name: `costCenterRoadLinks${cc.name}`,
				visibility: state.mapFeatures.showRoadLinks ? "visible" : "none",
				opacity: 1,
				featured: true
			})
		}
	}
	dispatch({ type: "setMapLayers", payload: tempMapLayers })
}
