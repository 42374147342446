import maplibregl from "maplibre-gl"
import { MapProviderDispatch, mapProviderStateType } from "../../utils/providers/mapProvider/mapProvider"

export const setCostCenterBoundsAndVisibility = (
	map: MapInstance,
	selectedCostCenterId: number | undefined,
	allRCGeoData: Record<string, any>,
	setBounds: React.Dispatch<React.SetStateAction<maplibregl.LngLatBounds | null | undefined>>,
	state: mapProviderStateType,
	dispatch: MapProviderDispatch
) => {
	let bounds = null
	const unFeaturedLayers: string[] = []
	if (selectedCostCenterId === undefined) {
		const cc = allRCGeoData?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId.nodes[0]
		bounds = new maplibregl.LngLatBounds(
			{ lat: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lng, lng: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lat },
			{ lat: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lng, lng: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lat }
		)
	}
	for (let i = 0, len = allRCGeoData?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
		const cc = allRCGeoData?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId.nodes[i]
		if (selectedCostCenterId === cc.id || selectedCostCenterId === undefined) {
			if (selectedCostCenterId === cc.id) {
				bounds = new maplibregl.LngLatBounds(
					{ lat: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lng, lng: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lat },
					{ lat: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lng, lng: cc.roadLinksByCostCenterId.nodes[0].coordinates[0].lat }
				)
			}

			map.current.setLayoutProperty(`costCenterRoadLinks${cc.name}`, "visibility", "visible")
			for (const lineString of cc.roadLinksByCostCenterId.nodes) {
				for (const coord of lineString.coordinates) {
					bounds?.extend([coord.lat, coord.lng])
				}
			}
		} else {
			unFeaturedLayers.push(`costCenterRoadLinks${cc.name}`)
			map.current.setLayoutProperty(`costCenterRoadLinks${cc.name}`, "visibility", "none")
		}
	}
	const tempMapLayers = [...state.mapLayers]
	for (let i = 0, len = tempMapLayers.length; len > i; i++) {
		if (unFeaturedLayers.includes(tempMapLayers[i].name)) {
			tempMapLayers[i].featured = false
		} else {
			tempMapLayers[i].featured = true
		}
	}
	dispatch({ type: "setMapLayers", payload: tempMapLayers })
	setBounds(bounds)
}
