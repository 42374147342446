import { Collapse } from "@mui/material"
import { SxProps } from "@mui/system"
import { Typography } from "@mui/material"
import { Alert, AlertTitle } from "@mui/material"
import React, { useEffect, useState } from "react"

interface NotificationProps {
	severity: "info" | "error" | "warning"
	children?: React.ReactNode
	title?: string
	autoClose?: number
	sx?: SxProps
	TypographyProps?: SxProps
	CollapseProps?: SxProps
}

const Notification = ({ severity, children, title, autoClose, sx, TypographyProps, CollapseProps }: NotificationProps) => {
	const [openAlert, setOpenAlert] = useState(true)

	useEffect(() => {
		if (autoClose) {
			setTimeout(() => {
				setOpenAlert(false)
			}, autoClose)
		}
	}, [])

	return (
		<>
			<Collapse sx={CollapseProps} in={openAlert}>
				<Alert onClose={() => setOpenAlert(false)} severity={severity} sx={sx}>
					{title ? <AlertTitle sx={{ fontWeight: "bold" }}>{title}</AlertTitle> : null}
					<Typography sx={{ fontWeight: "500", TypographyProps }}>{children}</Typography>
				</Alert>
			</Collapse>
		</>
	)
}

export default Notification
