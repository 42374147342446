import { Button } from "@mui/material"
import { pulseAnimation } from "../../utils/animations"
import React, { useEffect, useState } from "react"

type LinkButtonProps = {
	title: string
	url: string
}
const LinkButton = ({ title, url }: LinkButtonProps) => {
	function redirectToOfferPage() {
		window.open(url, "_blank")
	}
	return (
		<Button
			variant="contained"
			onClick={redirectToOfferPage}
			sx={{
				fontSize: 15,
				animation: `${pulseAnimation} 2s infinite`,
				backgroundColor: "#22c3f3"
			}}
		>
			{title}
		</Button>
	)
}
export default LinkButton
