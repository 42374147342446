import React, { useCallback, useState } from "react"

//import { onError } from "apollo-link-error";
import { toast } from "react-toastify"

interface ErrorNotifierProps {
	children: any
}

const ErrorNotifier = ({ children }: ErrorNotifierProps): JSX.Element => {
	const [doShowError, setDoShowError] = useState<boolean>(false)
	const showError = useCallback(() => setDoShowError(true), [])

	if (doShowError) {
		console.log(" ---------------------- ERROR PITÄISI NÄKYÄ JOSSAIN ------------------------------ ")

		const notificationObj = <div> Virhe jossain </div>

		toast.error(notificationObj, { role: "global" })
	}

	return (
		<div>
			{doShowError ? (
				<div style={{ zIndex: 1000, right: "20px" }}>
					<h1 role="alert">Error!</h1>
					<button style={{ zIndex: 1000, right: "20px", position: "absolute" }} onClick={() => setDoShowError(false)}>
						Dismiss
					</button>
				</div>
			) : null}
			{children(showError)}
		</div>
	)
}

export default ErrorNotifier
