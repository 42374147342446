import React from "react"
import { toast } from "react-toastify"

const checkRegisterEventValues = (userObj: any, agreedTerms: boolean) => {
	// const passw = userObj.password
	// const passwAgain = userObj.passwordAgain
	// const email = userObj.email
	// const fullName = userObj.fullName

	//console.log("passw: ",passw, " passwAgain: ", passwAgain, " email: ", email, " fullname: ", fullName);
	const validateEmail = (email: string) => {
		const re = /\S+@\S+\.\S+/
		return re.test(email)
	}

	const errors: string[] = []

	if (!userObj.fullName) {
		errors.push("Käyttäjän nimi vaaditaan rekisteröityessä")
	}
	if (!userObj.email) {
		errors.push("Sähköpostiosoite vaaditaan rekisteröityessä")
	}
	if (!validateEmail(userObj.email)) {
		errors.push("Väärin muotoiltu sähköposti")
	}
	if (!userObj.password) {
		errors.push("Salasana vaaditaan")
	} else {
		if (userObj?.password?.length < 7) {
			errors.push("Salasanan minimi pituus on 8(kahdeksan) merkkiä")
		}
	}
	if (userObj.password !== userObj.passwordAgain) {
		errors.push("Salasanat eivät täsmää")
	}

	if (!agreedTerms) {
		errors.push("Rekisteröityminen vaatii käyttöehtojen hyväksymisen")
	}

	if (errors.length > 0) {
		const errorObj = (
			<div>
				{errors.map((e: string) => {
					return <li key={e}>{e}</li>
				})}
			</div>
		)
		toast.error(errorObj, { role: "global-top-center" })
		return false
	} else {
		return true
	}
}

export default checkRegisterEventValues
