import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./TopNavBar.css"
import logo from "./Tievahti-logo-vaaka-RGB-04.svg"
import { useEffect } from "react"
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined"
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined"
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined"
import { IconButton } from "@mui/material"
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined"

import { Stack } from "@mui/material"
import ChatBot from "../SideNavBar/ChatBot"
import NotesPage from "../NotesPage/NotesPage"
import RCContactPage from "../HomePage/RoadCooperativeContactsPage/RoadCooperativeContacts"

const TopNavBar = (active: any) => {
	// console.log("top nav bar toimii");
	// console.log(active);
	const sectionNameColor = "#151515"

	const [openChatDialog, setOpenChatDialog] = useState(false)
	const [openNotesDialog, setOpenNotesDialog] = useState(false)
	const [openContactsDialog, setOpenContactsDialog] = useState(false)

	useEffect(() => {
		const burger = document.querySelector(".burger")
		const navContent = document.querySelector(".top-nav-dropdown-content")
		const navLinks = document.querySelectorAll(".top-nav-links")

		burger?.addEventListener("click", () => {
			// console.log(navContent);

			navContent?.classList.toggle("top-nav-dropdown-content-active")

			burger.classList.toggle("toggle")

			navLinks?.forEach((e: Element) => {
				e.addEventListener("click", () => {
					// console.log(navContent, " ---------- nav content ----------- ");
					navContent?.classList.remove("top-nav-dropdown-content-active")
					burger.classList.remove("toggle")
				})
			})
		})
	}, [])

	return (
		<div className="top-nav-dropdown">
			<div className="top-nav-header top-nav-logo" style={{ display: "flex" }}>
				<img src={logo} />
				<div className="burger">
					<div className="burger-line-1"></div>
					<div className="burger-line-2"></div>
					<div className="burger-line-3"></div>
				</div>
			</div>
			<div className="top-nav-dropdown-content top-nav-grid">
				<div className="top-nav-links">
					<Link to="" className="top-nav-link-1" style={{ textDecoration: "none" }}>
						<div className="top-nav-link-logo">
							<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={sectionNameColor} className=" bi bi-house" viewBox="0 0 16 16">
								<path
									fillRule="evenodd"
									d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
								/>
								<path
									fillRule="evenodd"
									d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
								/>
							</svg>
						</div>
						<div className="top-nav-link-text">Koti</div>
					</Link>
				</div>

				<div className="top-nav-links">
					<Link to="finances" className="top-nav-link-2" style={{ textDecoration: "none" }}>
						<div className="top-nav-link-logo">
							<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={sectionNameColor} className="bi bi-bank" viewBox="0 0 16 16">
								<path d="M8 .95 14.61 4h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.379l.5 2A.5.5 0 0 1 15.5 17H.5a.5.5 0 0 1-.485-.621l.5-2A.5.5 0 0 1 1 14V7H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 4h.89L8 .95zM3.776 4h8.447L8 2.05 3.776 4zM2 7v7h1V7H2zm2 0v7h2.5V7H4zm3.5 0v7h1V7h-1zm2 0v7H12V7H9.5zM13 7v7h1V7h-1zm2-1V5H1v1h14zm-.39 9H1.39l-.25 1h13.72l-.25-1z" />
							</svg>
						</div>
						<div className="top-nav-link-text">Talous</div>{" "}
					</Link>
				</div>

				{/* <div className='top-nav-links' >
                <Link to='partners_info' className='top-nav-link-2' style={{ textDecoration: 'none' }}>
                <div className="top-nav-link-logo">
                    <GroupsIcon fontSize='large' />
                    </div>
                <div className='top-nav-link-text' >Osakkaat</div> </Link>
                </div> */}

				{/* <div className='top-nav-links' >
                <Link to='unravel' className='top-nav-link-3' style={{'textDecoration': 'none'}}>                    <div className="top-nav-link-logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                        </svg>
                    </div>
                <div className='top-nav-link-text' >SelvitÃ¤</div> </Link>
                </div> */}

				{/* <div className='top-nav-links' >
                <Link to='chat'  className='top-nav-link-4' style={{'textDecoration': 'none'}}>                    <div className="top-nav-link-logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-chat-left-text" viewBox="0 0 16 16">
                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    </div>
                <div className='top-nav-link-text' >Chat</div> </Link>
                </div> */}

				{/* <div className='top-nav-links' >
                <Link to='map' className='top-nav-link-5'  style={{'textDecoration': 'none'}}>                    <div className="top-nav-link-logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-map" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z"/>
                            </svg> 
                    </div>
                <div className='top-nav-link-text' >Kartta</div> </Link>
                </div> */}

				<div className="top-nav-links">
					<Link to="calendar" className="top-nav-link-6" style={{ textDecoration: "none" }}>
						{" "}
						<div className="top-nav-link-logo">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="40"
								height="40"
								fill={sectionNameColor}
								className="bi bi-calendar3"
								viewBox="0 0 16 16"
							>
								<path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
								<path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
							</svg>
						</div>
						<div className="top-nav-link-text">Kokoukset</div>{" "}
					</Link>
				</div>

				<div className="top-nav-links">
					<Link to="documents" className="top-nav-link-6" style={{ textDecoration: "none" }}>
						<Stack direction="row" className="top-nav-link-logo">
							<FolderOutlinedIcon style={{ width: "47px", height: "47px", marginLeft: "-3px", paddingRight: "15px" }} />
							<div className="top-nav-link-logo">Asiakirjat</div>{" "}
						</Stack>
					</Link>
				</div>

				{/* <div className='top-nav-links' >
                <Link to='roadcooperative' className='top-nav-link-7' style={{'textDecoration': 'none'}} >                    <div className="top-nav-link-logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-signpost" viewBox="0 0 16 16">
                            <path d="M7 1.414V4H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h5v6h2v-6h3.532a1 1 0 0 0 .768-.36l1.933-2.32a.5.5 0 0 0 0-.64L13.3 4.36a1 1 0 0 0-.768-.36H9V1.414a1 1 0 0 0-2 0zM12.532 5l1.666 2-1.666 2H2V5h10.532z"/>
                        </svg>
                    </div>
                <div className='top-nav-link-text' >Tiekunta</div> </Link>
                </div> */}

				<div className="top-nav-links">
					<Link to="options" className="top-nav-link-9" style={{ textDecoration: "none" }}>
						{" "}
						<div className="top-nav-link-logo">
							<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={sectionNameColor} className="bi bi-gear" viewBox="0 0 16 16">
								<path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
								<path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
							</svg>
						</div>
						<div className="top-nav-link-text">Asetukset</div>{" "}
					</Link>
				</div>

				<div className="top-nav-links">
					<Stack direction="row" className="top-nav-link-logo">
						<IconButton
							className="top-nav-link-10"
							style={{ backgroundColor: "transparent" }}
							onClick={() => setOpenContactsDialog(!openNotesDialog)}
						>
							<PermContactCalendarOutlinedIcon className="helpSupport" sx={{ fontSize: "45px", marginLeft: "-10px" }} />
							<div className="top-nav-link-text-ChatBotSupport">Yhteystiedot</div>{" "}
						</IconButton>
					</Stack>
				</div>

				<div className="top-nav-links">
					<Stack direction="row" className="top-nav-link-logo">
						<IconButton className="top-nav-link-10" style={{ backgroundColor: "transparent" }} onClick={() => setOpenChatDialog(!openChatDialog)}>
							<ContactSupportOutlinedIcon className="helpSupport" sx={{ fontSize: "45px", marginLeft: "-10px" }} />
							<div className="top-nav-link-text-ChatBotSupport">Tuki</div>{" "}
						</IconButton>
					</Stack>
				</div>

				<div className="top-nav-links">
					<Stack direction="row" className="top-nav-link-logo">
						<IconButton className="top-nav-link-10" style={{ backgroundColor: "transparent" }} onClick={() => setOpenNotesDialog(!openNotesDialog)}>
							<FactCheckOutlinedIcon className="helpSupport" sx={{ fontSize: "45px", marginLeft: "-10px" }} />
							<div className="top-nav-link-text-ChatBotSupport">Muistio</div>{" "}
						</IconButton>
					</Stack>
				</div>

				<div className="top-nav-links">
					<Link to="profile" className="top-nav-link-8" style={{ textDecoration: "none" }}>
						{" "}
						<div className="top-nav-link-logo">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="40"
								height="40"
								fill={sectionNameColor}
								className="bi bi-person-circle"
								viewBox="0 0 16 16"
							>
								<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
								<path
									fillRule="evenodd"
									d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
								/>
							</svg>
						</div>
						<div className="top-nav-link-text">Profiili</div>{" "}
					</Link>
				</div>
			</div>
			{openNotesDialog ? <NotesPage openNotesDialog={openNotesDialog} setOpenNotesDialog={setOpenNotesDialog} /> : null}
			{openChatDialog ? <ChatBot setOpenChatDialog={setOpenChatDialog} openChatDialog={openChatDialog} /> : null}
			{openContactsDialog ? <RCContactPage setOpenRCContactsDialog={setOpenContactsDialog} openRCContactsDialog={openContactsDialog} /> : null}
		</div>
	)
}

export default TopNavBar
