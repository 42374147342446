// import { AnyLayer } from "maplibre-gl"

import { AnyLayer } from "mapbox-gl"

interface KipaLayerId {
	id: string
	name: string
}

const kipaLayersId: KipaLayerId[] = [
	{ id: "KiinteistotunnuksenSijaintitiedot", name: "Kiinteistötunnukset" },
	{ id: "RajamerkinSijaintitiedot_tunnus", name: "Rajamerkin tunnus" },
	{ id: "PalstanSijaintitiedot", name: "palstat" },
	{ id: "KiinteistorajanSijaintitiedot", name: "kiinteistörajat" },
	{ id: "RajamerkinSijaintitiedot", name: "Rajamerkit" }
]

const kipaLayers: AnyLayer[] = [
	{
		id: "KiinteistorajanSijaintitiedot",
		type: "line",
		source: "kipa",
		layout: {
			visibility: "none"
		},
		"source-layer": "KiinteistorajanSijaintitiedot",
		filter: ["==", ["geometry-type"], "LineString"],
		minzoom: 3,
		paint: {
			"line-color": "#505050",
			"line-width": 1
		}
	},
	{
		id: "RajamerkinSijaintitiedot_tunnus",
		type: "symbol",
		source: "kipa",
		"source-layer": "RajamerkinSijaintitiedot",
		minzoom: 12,
		layout: {
			"text-field": "{numero}",
			visibility: "none",
			"text-font": ["Liberation Sans NLSFI"],
			"symbol-placement": "point",
			"text-size": 12
		},
		paint: {
			"text-color": "#800000",
			"text-halo-color": "#fff",
			"text-halo-width": 1.5,
			"text-halo-blur": 1
		}
	},
	{
		id: "KiinteistotunnuksenSijaintitiedot",
		type: "symbol",
		source: "kipa",
		"source-layer": "KiinteistotunnuksenSijaintitiedot",
		minzoom: 3,
		layout: {
			"text-field": "{kiinteistotunnuksenEsitysmuoto}",
			visibility: "none",
			"text-font": ["Liberation Sans NLSFI"],
			"symbol-placement": "point",
			"text-size": 15
		},
		paint: {
			"text-color": "#000",
			"text-halo-color": "#fff",
			"text-halo-width": 1.5,
			"text-halo-blur": 1
		}
	},
	{
		id: "RajamerkinSijaintitiedot",
		type: "circle",
		source: "kipa",
		layout: {
			visibility: "none"
		},
		"source-layer": "RajamerkinSijaintitiedot",
		minzoom: 11,
		filter: ["==", ["geometry-type"], "Point"],
		paint: {
			"circle-stroke-color": "rgba(255,255,255,1)",
			"circle-color": "rgba(255,0,0,1)",
			"circle-radius": 3
		}
	},
	{
		id: "PalstanSijaintitiedot",
		type: "fill",
		source: "kipa",
		layout: {
			visibility: "none"
		},
		"source-layer": "PalstanSijaintitiedot",
		minzoom: 9,
		paint: {
			"fill-color": [
				"case",
				["all", ["boolean", ["feature-state", "selected"], false], ["boolean", ["feature-state", "searched"], false]],
				"#00ff2b",
				["boolean", ["feature-state", "searched"], false],
				"#0009fc",
				["boolean", ["feature-state", "selected"], false],
				"#e74c3c",

				"#505050"
			],

			"fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.3, 0.0]
		}
	}
]

export default {
	kipaLayers,
	kipaLayersId
}
