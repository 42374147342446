import { AxiosResponse } from "axios"
import axiosClient from "../axiosClient/axiosClient"

interface DBNote {
	title: string
	content: string
	road_cooperative_name: string
	id: number
}

interface DBTask extends DBNote {
	task_datetime: string
	completed: boolean
}

const createUserTask = async (task: Task) => {
	try {
		const res = await axiosClient({
			method: "post",
			url: "/api/user/notes/createTask",
			data: task
		})
		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const updateTask = async (task: Task) => {
	try {
		if (task.id == undefined) throw 403

		const res = await axiosClient({
			method: "post",
			url: "/api/user/notes/updateTask",
			data: task
		})

		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const deleteTask = async (id: number) => {
	try {
		if (id == undefined) throw 403

		const res = await axiosClient({
			method: "post",
			url: "/api/user/notes/deleteTask",
			data: { id: id }
		})

		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const getUserNotes = async () => {
	try {
		const res: AxiosResponse<DBNote[]> = await axiosClient({
			method: "get",
			url: "/api/user/notes/allNotes"
		})
		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const getUserTasks = async () => {
	try {
		const res: AxiosResponse<DBTask[]> = await axiosClient({
			method: "get",
			url: "/api/user/notes/allTasks"
		})
		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const createUserNote = async (note: Note) => {
	try {
		const res = await axiosClient({
			method: "post",
			url: "/api/user/notes/createNote",
			data: note
		})

		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const updateNote = async (note: Note) => {
	try {
		if (note.id == undefined) throw 403

		const res = await axiosClient({
			method: "post",
			url: "/api/user/notes/updateNote",
			data: note
		})

		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

const deleteNote = async (id: number) => {
	try {
		if (id == undefined) throw 403

		const res = await axiosClient({
			method: "post",
			url: "/api/user/notes/deleteNote",
			data: { id: id }
		})

		return res
	} catch (error) {
		console.error(error)
		return null
	}
}

export { getUserNotes, getUserTasks, createUserNote, updateNote, deleteNote, createUserTask, updateTask, deleteTask }
