// Usage e.g
// userProfileStorage({type: 'setUserProfile', payload: {name: data.userByEmail.name, email: data.userByEmail.email}})
// console.log(userProfileStorage({type: 'getUserProfile'}));

interface UserProfileInfo {
	name: string | null
	email: string | null
}

interface costCenter {
	name: string
	roadLinks: {
		coordinates: Array<[number, number]>
		linkId: string
	}
}
interface ActiveRoadCooperative {
	roadCooperative: string | null
	role: string | null
	id: string | null
	costCenters?: costCenter[]
	permission?: any
}

type Action =
	| { type: "setUserProfile"; payload: UserProfileInfo }
	| { type: "setActiveRoadCooperative"; payload: ActiveRoadCooperative | null }
	| { type: "getUserProfile" }
	| { type: "getActiveRoadCooperative" }
	| { type: "logout" }

const parseData = (data: string | null) => {
	if (data) {
		return JSON.parse(data)
	}
	return data
}

const logout = () => {
	window.localStorage.removeItem("userProfileInfo")
	window.localStorage.removeItem("userActiveRoadCooperative")
}

const userProfileStorage = (action: Action) => {
	switch (action.type) {
		case "setUserProfile":
			window.localStorage.setItem("userProfileInfo", JSON.stringify(action.payload))
			return
		case "setActiveRoadCooperative":
			window.localStorage.setItem("userActiveRoadCooperative", JSON.stringify(action.payload))
			return
		case "getUserProfile":
			return parseData(window.localStorage.getItem("userProfileInfo"))
		case "getActiveRoadCooperative":
			return parseData(window.localStorage.getItem("userActiveRoadCooperative"))
		case "logout":
			console.log(" ------ LOGOUT ------- ")

			return logout()
	}
}

export default userProfileStorage
