import { gql, useMutation, useQuery } from "@apollo/client"
import { MenuItem, Paper, Table, TableRow, TableBody, Box, Stack, Fab, TableContainer, TableHead, TableCell, Typography } from "@mui/material"

import React, { useEffect, useState } from "react"
import AddBoxIcon from "@mui/icons-material/AddBox"
import LibraryAddIcon from "@mui/icons-material/LibraryAdd"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import { TextField } from "@mui/material"
import { Grid } from "@mui/material"
import readXlsxFile from "read-excel-file"
import BulkImportUnitRow from "./BulkImportUnitRow"
import { toast } from "react-toastify"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import { Backdrop } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { v4 } from "uuid"
import { Button } from "@mui/material"
import { useLocation } from "react-router-dom"
import HelpWithInitialization from "../../reusables/HelpWithInitialization"
import PopUpForIntroductionSupport from "../../reusables/PopUpForIntroductionSupport"

const GET_ALL_ROAD_COOPERATIVE_PROPERTIES = gql`
	query GetAllRoadCooperativeProperties {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					id
					idColor
					name
					propertiesByCostCenterId {
						nodes {
							mmlPropertyId
							propertyTag
							propertyName
							id
							costCenterByCostCenterId {
								idColor
								name
							}
						}
					}
				}
			}
		}
	}
`

const GET_ALL_MEMBERS = gql`
	query GetAllMembers {
		roadCooperativeWithJWT {
			roadCooperativeMembersByRoadCooperativeId(orderBy: NAME_ASC) {
				nodes {
					userEmail
					name
					id
				}
			}
		}
	}
`

const CREATE_USAGE_UNIT = gql`
	mutation CreateUsageUnit($roadCooperativeId: UUID, $roadUsageUnit: String = "", $roadUsageUnitType: String = "", $weight: Float = 1.0) {
		createRoadUsageUnit(
			input: {
				roadUsageUnit: { roadCooperativeId: $roadCooperativeId, roadUsageUnit: $roadUsageUnit, roadUsageUnitType: $roadUsageUnitType, weight: $weight }
			}
		) {
			roadUsageUnit {
				id
				roadUsageUnit
				roadUsageUnitType
				weight
			}
		}
	}
`

const CREATE_ESTABLISHMENTS = gql`
	mutation CreateEstablishments($input: [EstablishmentInput!]!) {
		createSeveralEstablishments(input: $input)
	}
`

const GET_ALL_USAGE_UNITS = gql`
	query GetAllUsageUnits {
		roadCooperativeWithJWT {
			roadUsageUnitsByRoadCooperativeId {
				nodes {
					id
					roadUsageUnit
					roadUsageUnitType
					weight
				}
			}
		}
	}
`

interface UnitsFile extends HTMLElement {
	files?: File[]
}

interface Unit {
	identifier: string
	partnerId: number
	property: Property | { mmlPropertyId: string; propertyName: string; id: number }
	roadUsageUnitType: string
	roadUsageUnit: string
	weight: number
	amount: number
	operatingDistance: number
	discretionalyCorrectionMultiplier: number
	distanceCorrectionMultiplier: number
	weightCorrectionMultiplier: number
	additionalInformation: string
}

const turnValueToString = (value: any) => {
	if (value === null || value === undefined) {
		return ""
	} else {
		return value.toString()
	}
}

const turnValueToFloat = (value: any) => {
	if (value == undefined || value == null) return 1
	console.log(value)
	const returnValue = parseFloat(value.replace(",", ".")) ?? 1
	if (isNaN(returnValue)) {
		return 1
	} else {
		return returnValue
	}
}

const BulkUnitImportTool = ({
	setOpenBulkUnitDataImport,
	setRoadCooperativeStatusValues
}: {
	setOpenBulkUnitDataImport: React.Dispatch<React.SetStateAction<boolean>>
	setRoadCooperativeStatusValues: React.Dispatch<
		React.SetStateAction<{
			createRoad: boolean
			createMembers: boolean
			createEstablishments: boolean
		}>
	>
}) => {
	// Deactivate window.alert
	window.alert = function () {
		null
	}

	const [allCostCenters, setAllCostCenters] = useState<CostCenter[]>([])
	const [selectedCostCenter, setSelectedCostCenter] = useState({})
	const [selectedCostCenterId, setSelectedCostCenterId] = useState(0)
	const [allUnits, setAllUnits] = useState<Unit[]>([])
	const [allMembers, setAllMembers] = useState<RoadCooperativeMemberType[]>([])
	const [costCenterProperties, setCostCenterProperties] = useState<Property[]>([])
	const [allUsageUnits, setAllUsageUnits] = useState<RoadUsageUnit[]>([])
	const [loading, setLoading] = useState(false)

	const [createEstablishments] = useMutation(CREATE_ESTABLISHMENTS)
	const [createRoadUsageUnit] = useMutation(CREATE_USAGE_UNIT)

	const activeRoadcooperative = userProfileStorage({ type: "getActiveRoadCooperative" })
	useQuery(GET_ALL_USAGE_UNITS, {
		onCompleted: (data) => {
			setAllUsageUnits(data.roadCooperativeWithJWT.roadUsageUnitsByRoadCooperativeId.nodes)
		}
	})

	const { loading: costCentersLoading, error: costCenterError } = useQuery(GET_ALL_ROAD_COOPERATIVE_PROPERTIES, {
		onCompleted: (data) => {
			setAllCostCenters(data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes)
		}
	})

	useQuery(GET_ALL_MEMBERS, {
		onCompleted: (data) => {
			console.log(data)
			setAllMembers(data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.nodes)
		}
	})

	const location = useLocation()
	const CustomCloseButton = ({ closeToast }: any) => (
		<Button
			sx={{
				position: "absolute", // Sijoittaa napin absoluuttisesti
				top: "5px", // Asettaa napin yläreunaan
				right: "5px", // Asettaa napin oikeaan reunaan
				minWidth: "24px", // Pienentää napin leveyttä
				height: "24px", // Pienentää napin korkeutta
				padding: 0, // Poistaa ylimääräiset marginaalit
				fontSize: "14px", // Pienentää fonttikokoa
				fontWeight: 900,
				lineHeight: 1, // Vähentää riviväliä
				backgroundColor: "transparent", // Taustaväri läpinäkyvä
				color: "#b0b0b0", // Tekstin väri
				"&:hover": {
					backgroundColor: "rgba(0, 0, 0, 0.1)" // Muuttaa hover-tilan taustaa
				}
			}}
			onClick={closeToast}
		>
			╳
		</Button>
	)

	useEffect(() => {
		toast(
			`Tämän työkalun avulla voit tuoda yksikkötiedot ohjelmaan joko tuomalla tiedot ulkoisesta taulukosta tai syöttämällä ne riveille manuaalisesti. Aloita tietojen syöttö valitsemalla tie, jolle yksiköitä halutaan lisätä!`,
			{
				role: "global",
				type: toast.TYPE.INFO,
				draggable: true,
				autoClose: false,
				closeButton: <CustomCloseButton closeToast={() => toast.dismiss()} />
			}
		)

		// Sulje toast, kun sivu vaihtuu poistamalla se
		return () => {
			toast.dismiss()
		}
	}, [location])

	useEffect(() => {
		const costCenter: CostCenter = allCostCenters.filter((cc: CostCenter) => cc.id === selectedCostCenterId)[0]
		console.log(costCenter)
		if (costCenter && costCenter.propertiesByCostCenterId) {
			setCostCenterProperties(costCenter?.propertiesByCostCenterId?.nodes)
		}
	}, [selectedCostCenterId])

	useEffect(() => {
		if (allCostCenters.length == 0 || selectedCostCenterId) return
		if (allCostCenters.length == 1 && allCostCenters[0].id) setSelectedCostCenterId(allCostCenters[0].id)
	}, [allCostCenters])

	const parseMultiplier = (multiplier: any) => {
		const strVal = turnValueToString(multiplier)
		const amountWithoutSeparator = strVal.replace(/\s/g, "")
		const numeric = parseFloat(amountWithoutSeparator.replace(",", "."))
		return isNaN(numeric) ? 1 : numeric
	}

	const handleReadFile = async () => {
		setLoading(true)
		const membersFile: UnitsFile | null = document.getElementById("membersFile")
		if (membersFile?.files?.length === 0) return
		if (membersFile && membersFile.files) {
			try {
				const rows = await readXlsxFile(membersFile.files[0], { sheet: "Yksikot" })

				console.log(rows)
				rows.splice(0, 2)
				const tempUnits = [...allUnits]
				for (let i = 0; i < rows.length; i++) {
					console.log(rows[i])
					const partnerId = allMembers.find((member) => member.name === turnValueToString(rows[i][0]))
					const property = costCenterProperties.find((property) => property.mmlPropertyId === turnValueToString(rows[i][1]))
					const randomId = v4()
					const newUnit: Unit = {
						identifier: randomId,
						partnerId: partnerId?.id ? partnerId?.id : -1,
						property: property ? property : { mmlPropertyId: "", propertyName: "", id: -1 },
						roadUsageUnitType: turnValueToString(rows[i][2]),
						roadUsageUnit: turnValueToString(rows[i][3]),
						weight: parseMultiplier(rows[i][4]),
						amount: parseMultiplier(rows[i][5]),
						operatingDistance: parseMultiplier(rows[i][6]),
						distanceCorrectionMultiplier: parseMultiplier(rows[i][7]),
						weightCorrectionMultiplier: parseMultiplier(rows[i][8]),
						discretionalyCorrectionMultiplier: parseMultiplier(rows[i][9]),
						additionalInformation: turnValueToString(rows[i][10])
					}
					tempUnits.push(newUnit)
				}
				setAllUnits(tempUnits)
			} catch (error) {
				toast.error("Virheellinen tiedosto", { role: "global" })
			}
		} else {
			return
		}
		setLoading(false)
	}

	const handleAddNewUnit = (e: any) => {
		setLoading(true)
		const randomId = v4()
		// console.log(randomId);

		e.preventDefault()
		setAllUnits([
			...allUnits,
			{
				identifier: randomId,
				partnerId: -1,
				property: { mmlPropertyId: "", propertyName: "", id: -1 },
				roadUsageUnitType: "",
				roadUsageUnit: "",
				weight: 1,
				amount: 1,
				operatingDistance: 1,
				discretionalyCorrectionMultiplier: 1,
				distanceCorrectionMultiplier: 1,
				weightCorrectionMultiplier: 1,
				additionalInformation: ""
			}
		])
		setLoading(false)
	}

	const getUsageUnitId = async (allUpdatedUsageUnits: RoadUsageUnit[], unit: Unit): Promise<[number, RoadUsageUnit[]]> => {
		let roadUsageUnitId: number | -1 = -1
		let usageUnits: RoadUsageUnit[] = await allUsageUnits.concat(allUpdatedUsageUnits)
		console.log(usageUnits, " ------------------------- ALL USAGEUNITS ----------------------- ")

		for (let i2 = 0; usageUnits.length > i2; i2++) {
			console.log(" -------------- FOR LOOPISSA --------------------")
			if (usageUnits[i2].id === undefined) continue
			if (
				usageUnits[i2].roadUsageUnitType === unit.roadUsageUnitType &&
				usageUnits[i2].roadUsageUnit === unit.roadUsageUnit &&
				usageUnits[i2].weight == unit.weight
			) {
				roadUsageUnitId = Number(usageUnits[i2].id)
				console.log(usageUnits[i2].id, " -------------------- USAGE UNIT LÖYTY -----------------")
				break
			} else {
				roadUsageUnitId = -1
			}
		}
		if (roadUsageUnitId === -1) {
			const createUsageUnitRes = await createRoadUsageUnit({
				variables: {
					roadUsageUnitType: unit.roadUsageUnitType,
					roadUsageUnit: unit.roadUsageUnit,
					weight: parseFloat(unit.weight.toString()),
					roadCooperativeId: activeRoadcooperative.id
				},
				onError: (data) => {
					roadUsageUnitId = -1
					// toast.error("Liikennelajin luonti epäonnistui")
				},
				onCompleted: (data: any) => {
					console.log(data)
					// toast.success('Liikennelaji luotu')
					roadUsageUnitId = data.createRoadUsageUnit.roadUsageUnit.id
				}
			})
			console.log(createUsageUnitRes, " ----------------- USAGEUNIT REs ----------------------------")

			usageUnits = [...usageUnits, createUsageUnitRes.data.createRoadUsageUnit.roadUsageUnit]
			setAllUsageUnits((prev) => [...prev, createUsageUnitRes.data.createRoadUsageUnit.roadUsageUnit])
		}
		return [roadUsageUnitId, usageUnits]
	}

	const checkUnitValidity = () => {
		const unitsWithInvalidData = []
		for (let i = 0; allUnits.length > i; i++) {
			const errorMessage = []
			if (allUnits[i].partnerId === null || allUnits[i].partnerId == -1) {
				errorMessage.push("osakas ")
			}
			// if (allUnits[i].property.id === undefined || allUnits[i].property.id == -1) {
			// 	errorMessage.push("kiinteistö ")
			// }
			if (allUnits[i].amount.toString().trim().length === 0) {
				errorMessage.push("lukumäärä ")
			}
			if (allUnits[i].roadUsageUnit === "") {
				errorMessage.push("liikennelaji tai lyhenne ")
			}
			if (allUnits[i].weight.toString().trim().length === 0) {
				errorMessage.push("painoluku ")
			}
			if (allUnits[i].operatingDistance.toString().trim().length === 0) {
				errorMessage.push("käyttömatka ")
			}
			const discretionalyCorrectionMultiplier = parseFloat(allUnits[i].discretionalyCorrectionMultiplier.toString().trim())
			if (isNaN(discretionalyCorrectionMultiplier) || discretionalyCorrectionMultiplier <= 0) {
				errorMessage.push("painoluvun korjauskerroin")
			}

			const weightCorrectionMultiplier = parseFloat(allUnits[i].weightCorrectionMultiplier.toString().trim())
			if (isNaN(weightCorrectionMultiplier) || weightCorrectionMultiplier <= 0) {
				errorMessage.push("matkan korjauskerroin")
			}

			const distanceCorrectionMultiplier = parseFloat(allUnits[i].distanceCorrectionMultiplier.toString().trim())
			if (isNaN(distanceCorrectionMultiplier) || distanceCorrectionMultiplier <= 0) {
				errorMessage.push("harkinnanvarainen korjauskerroin")
			}
			if (errorMessage.length > 0) {
				unitsWithInvalidData.push(allUnits[i])
				if (errorMessage.length > 1) {
					const string = errorMessage.reduce((acc, text, index) => {
						if (index === 0) return (acc = acc + ` ${text}`)
						if (index + 1 === errorMessage.length) return (acc = acc + ` ja ${text}`)
						return (acc = acc + `, ${text}`)
					}, "")
					const toastId = "toastWarning"
					if (!toast.isActive(toastId)) {
						toast.warning(`Rivillä ${i + 1} ${string} puuttuu!`, { toastId, autoClose: false })
					}
				} else {
					const toastId = "toastWarning"
					if (!toast.isActive(toastId)) {
						toast.warning(`Rivillä ${i + 1} ${errorMessage[0]} puuttuu!`, { toastId, autoClose: false })
					}
				}
			}
		}
		if (unitsWithInvalidData.length > 0) {
			return false
		} else {
			return true
		}
	}

	const roundFloatValue = (num: number) => {
		return Math.round(num * 1000) / 1000
	}

	const handleCreateNewUnits = async () => {
		// func that unit object values is valid and existing
		if (!checkUnitValidity()) return
		setLoading(true)
		let allUpdatedUsageUnits: RoadUsageUnit[] = []
		const establishments = []
		for (let i = 0, len = allUnits.length; len > i; i++) {
			// then func check if road usage unit is existing allready, if not => create one
			const [roadUsageUnitId, usageUnits] = await getUsageUnitId(allUpdatedUsageUnits, allUnits[i])
			allUpdatedUsageUnits = usageUnits
			// then func create establishment
			establishments.push({
				additionalInformation: allUnits[i].additionalInformation,
				amount: roundFloatValue(allUnits[i].amount),
				costCenterId: selectedCostCenterId,
				discretionalyCorrectionMultiplier: roundFloatValue(allUnits[i].discretionalyCorrectionMultiplier),
				distanceCorrectionMultiplier: roundFloatValue(allUnits[i].distanceCorrectionMultiplier),
				lateralDirection: 1,
				lateralDirectionCorrectionMultiplier: 1,
				operatingDistance: roundFloatValue(allUnits[i].operatingDistance),
				propertyId: allUnits[i].property.id == -1 ? null : allUnits[i].property.id,
				roadCooperativeMemberId: allUnits[i].partnerId,
				roadUsageUnitId: roadUsageUnitId,
				weightCorrectionMultiplier: roundFloatValue(allUnits[i].weightCorrectionMultiplier)
			})
		}
		createEstablishments({
			variables: { input: establishments },
			onCompleted: () => {
				// toast.success("Yksiköt luotu onnistuneesti!")
				setLoading(false)
				setRoadCooperativeStatusValues((prevValue) => {
					return { ...prevValue, createEstablishments: false }
				})
				setOpenBulkUnitDataImport(false)
				// window.location.reload()
			},
			onError: () => {
				// toast.error("Yksiköiden luonti epäonnistui!")
				setLoading(false)
			}
		})
	}

	return (
		<>
			<Box sx={{ overflow: "hidden", height: "100%" }}>
				<Backdrop open={loading} sx={{ zIndex: 1000000 }}>
					<CircularProgress></CircularProgress>
					<Typography sx={{ color: "white" }}> Ladataan tietoja...</Typography>
				</Backdrop>

				<Grid direction="row" spacing={5} container xs={12}>
					<Grid item md={6} xs={12} sx={{}}>
						<Box sx={{ height: "80px", display: "flex", alignItems: "center", justifyContent: "center" }}>
							{costCentersLoading || costCenterError ? (
								<>Ladataan tiekunnan tiet...</>
							) : (
								<TextField
									inputProps={{
										maxLength: 100
									}}
									sx={{ width: "300px" }}
									variant={"outlined"}
									value={selectedCostCenterId}
									select
									disabled={selectedCostCenterId && allCostCenters.length <= 1}
									label={"Tie"}
									onChange={(e: { target: { value: number } }) => setSelectedCostCenterId(e.target.value)}
								>
									{allCostCenters.map((cc: CostCenter, index) => {
										return (
											<MenuItem key={index} value={cc.id}>
												<Stack direction="row">
													<Box
														sx={{
															width: "12px",
															height: "12px",
															background: cc.idColor,
															borderRadius: "8px",
															marginRight: "15px",
															marginLeft: "10px",
															alignSelf: "center"
														}}
													/>
													{cc.name}
												</Stack>
											</MenuItem>
										)
									})}
								</TextField>
							)}
							<Fab
								sx={{ borderRadius: "20px" }}
								color="secondary"
								variant="extended"
								href={process.env.PUBLIC_URL + "/files/Yksiköt_tuontipohja.xlsx"}
							>
								Lataa tuontikaavio <FileDownloadIcon sx={{ paddingLeft: "10px" }} />
							</Fab>
							<Grid sx={{ paddingLeft: "20px" }}>
								<PopUpForIntroductionSupport
									pointerDirection="top"
									subclass="ob-after-properties"
									top={225}
									left={21}
									title={"Pyydä apua Tievahdin käyttöönottoon."}
									popUpText={`Mikäli tarvitset apua Tievahdin käyttöönotossa, autamme mielellämme. Lisäämme puolestanne tiekunnan tien, kiinteistöt, osakkaat sekä yksiköt
					olemassa olevien tietojenne pohjalta. Autamme teitä pääsemään alkuun Tievahdin talous-osion kanssa, jota hyödyntämällä voitte hoitaa
					tiekuntanne kirjanpidon ja tiemaksujen laskutuksen. Käyttöönoton jälkeen käymme vielä yhdessä läpi ohjelman loputkin ominaisuudet, jonka
					tavoitteena on että pystyisitte jatkossa käyttämään Tievahtia itsenäisesti. Palvelun hinta on <b>250 € (alv 0 %)</b>`}
								></PopUpForIntroductionSupport>
							</Grid>
						</Box>
					</Grid>
					<Grid item md={6} xs={12}>
						<Box sx={{ height: "80px", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Fab
								sx={{ borderRadius: "20px", marginRight: "5px" }}
								color="primary"
								variant="extended"
								component="label"
								disabled={selectedCostCenterId === 0 ? true : false}
							>
								Tuo tuontikaaviolla
								<input style={{ display: "none" }} onChange={handleReadFile} type="file" id="membersFile" />
								<LibraryAddIcon sx={{ paddingLeft: "10px" }} />
							</Fab>
							<Fab
								sx={{ borderRadius: "20px" }}
								disabled={selectedCostCenterId === 0 ? true : false}
								color="primary"
								variant="extended"
								onClick={handleAddNewUnit}
							>
								Lisää yksikkörivi <AddBoxIcon sx={{ paddingLeft: "10px" }} />
							</Fab>
						</Box>
					</Grid>
				</Grid>

				<Button
					disabled={selectedCostCenterId === 0 ? true : false}
					color="primary"
					variant="contained"
					onClick={handleCreateNewUnits}
					sx={{ position: "absolute", bottom: "25px", right: "25px" }}
				>
					Tallenna tiedot
					{/* <SaveIcon sx={{ paddingLeft: '10px' }} /> */}
				</Button>

				<TableContainer component={Paper} sx={{ height: "calc(100% - 80px)" }}>
					<Table stickyHeader size="small" sx={{ overflow: "scroll" }}>
						<TableHead>
							<TableRow>
								<TableCell sx={{ width: "3px" }}></TableCell>
								<TableCell sx={{ width: "3px" }}></TableCell>
								<TableCell sx={{ minWidth: "120px" }}>
									<Typography>Osakas</Typography>
								</TableCell>
								<TableCell sx={{ minWidth: "180px" }}>
									<Typography>Kiinteistö </Typography>
								</TableCell>
								<TableCell>
									<Typography>Liikennelaji</Typography>
								</TableCell>
								<TableCell>
									<Typography>Lyhenne</Typography>
								</TableCell>
								<TableCell>
									<Typography>Painoluku (t)</Typography>
								</TableCell>
								<TableCell>
									<Typography>Lukumäärä (lkm/ha)</Typography>
								</TableCell>
								<TableCell>
									<Typography>Käyttömatka (km)</Typography>
								</TableCell>
								<TableCell>
									<Typography>Matkan korjauskerroin</Typography>
								</TableCell>
								<TableCell>
									<Typography>Painoluvun korjauskerroin</Typography>
								</TableCell>
								<TableCell>
									<Typography>Harkinnanvarainen korjauskerroin</Typography>
								</TableCell>
								<TableCell>
									<Typography>Yksiköt</Typography>
								</TableCell>
								<TableCell>
									<Typography>Lisätieto</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{allUnits.map((unit: Unit, index: number) => {
								return (
									<React.Fragment key={unit.identifier}>
										<tr className="bulk-row">
											<td className="bulk-cell first-cell">{index + 1}.</td>
											<BulkImportUnitRow
												allMembers={allMembers}
												index={index}
												unit={unit}
												setAllUnits={setAllUnits}
												costCenterProperties={costCenterProperties}
											/>
										</tr>
									</React.Fragment>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	)
}

export default BulkUnitImportTool
