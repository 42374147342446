import isMobile from "ismobilejs"
import { FeatureCollection, Position } from "geojson"
import { MapMouseEvent, MapTouchEvent } from "maplibre-gl"
import vaylaDataService from "../../../network/dataServices/vaylaDataService"

const mapClickFunction = (map: MapInstance) => {
	let latLngG = null
	let geoJsonData: FeatureCollection
	let geoJsonCoordinates: Position[]

	if (isMobile().any) {
		let timer: NodeJS.Timer

		map.current.on("touchstart", (e: MapTouchEvent) => {
			if (e.originalEvent.touches.length > 1) {
				return
			}

			timer = setTimeout(() => {
				const mapLayer = map.current.getLayer("testi2")
				console.log(mapLayer)

				const latLng = [e.lngLat.lat, e.lngLat.lng]
				latLngG = latLng
				map.current.flyTo({
					center: [e.lngLat.lng, e.lngLat.lat]
				})

				const road = vaylaDataService.selectRoadByClick(latLngG[0], latLngG[1])
				road.then((response) => {
					if (response == "virhe") return
					geoJsonData = response.features[0]
					geoJsonCoordinates = response.features[0].geometry.coordinates

					geoJsonData = {
						type: "FeatureCollection",
						features: [
							{
								properties: {},
								type: "Feature",
								geometry: {
									type: "LineString",
									coordinates: geoJsonCoordinates
								}
							}
						]
					}
				}).then(() => {
					if (map.current._loaded) {
						if (map.current.getLayer("clickRoad")) {
							map.current.removeLayer("clickRoad")
						}

						if (map.current.getSource("clickRoad")) {
							map.current.removeSource("clickRoad")
						}

						map.current.addSource("clickRoad", {
							type: "geojson",
							data: geoJsonData
						})

						map.current.addLayer({
							id: "clickRoad",
							type: "line",
							source: "clickRoad",
							layout: {
								"line-join": "round",
								"line-cap": "round"
							},
							paint: {
								"line-color": "#505050",
								"line-width": 5
							}
						})
					}
				})

				console.log(geoJsonData, " ----- geojson data ---- ")
			}, 500)
		})

		map.current.on("touchend", () => clearTimeout(timer))
		map.current.on("touchcancel", () => clearTimeout(timer))
		map.current.on("touchmove", () => clearTimeout(timer))
		map.current.on("pointerdrag", () => clearTimeout(timer))
		map.current.on("pointermove", () => clearTimeout(timer))
		map.current.on("moveend", () => clearTimeout(timer))
		map.current.on("gesturestart", () => clearTimeout(timer))
		map.current.on("gesturechange", () => clearTimeout(timer))
		map.current.on("gestureend", () => clearTimeout(timer))
	}

	if (!isMobile().any) {
		map.current.on("dblclick", (e: MapMouseEvent) => {
			latLngG = [e.lngLat.lat, e.lngLat.lng]

			map.current.flyTo({
				center: [e.lngLat.lng, e.lngLat.lat]
			})

			const road = vaylaDataService.selectRoadByClick(latLngG[0], latLngG[1])
			road.then((response) => {
				if (response == "virhe") return
				geoJsonData = response.features[0]
				geoJsonCoordinates = response.features[0].geometry.coordinates

				geoJsonData = {
					type: "FeatureCollection",
					features: [
						{
							properties: {},
							type: "Feature",
							geometry: {
								type: "LineString",
								coordinates: geoJsonCoordinates
							}
						}
					]
				}
			}).then(() => {
				if (map.current._loaded) {
					if (map.current.getLayer("clickRoad")) {
						map.current.removeLayer("clickRoad")
					}

					if (map.current.getSource("clickRoad")) {
						map.current.removeSource("clickRoad")
					}

					map.current.addSource("clickRoad", {
						type: "geojson",
						data: geoJsonData
					})

					map.current.addLayer({
						id: "clickRoad",
						type: "line",
						source: "clickRoad",
						layout: {
							"line-join": "round",
							"line-cap": "round"
						},
						paint: {
							"line-color": "#505050",
							"line-width": 5
						}
					})
				}
			})
		})
	}
}

export default mapClickFunction
