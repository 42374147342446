import React, { useMemo } from "react"
import { ApolloClient, ApolloProvider } from "@apollo/client"
import { cache } from "./apolloClientInMemoryCache"
import additiveLinkList from "./apolloLinks/additiveLinkList"
interface ApolloProviderProps {
	showError: JSX.Element
	children: React.ReactElement
}

export const client = new ApolloClient({
	cache: cache,
	link: additiveLinkList(),
	defaultOptions: {
		watchQuery: { errorPolicy: "none" },
		query: { errorPolicy: "none" },
		mutate: { errorPolicy: "none" }
	}
})

const CustomApolloProvider = ({ showError, children }: ApolloProviderProps) => {
	const providerClient = useMemo(() => {
		return client
	}, [showError])

	return <ApolloProvider client={providerClient}>{children}</ApolloProvider>
}

export default CustomApolloProvider
