import React, { useEffect, useState } from "react"

import { Box, Dialog, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, Stack, Button } from "@mui/material"

import { ApolloQueryResult, gql, OperationVariables, useMutation, useQuery } from "@apollo/client"
import { CircularProgress, TableSortLabel, TextField } from "@mui/material"
import { toast } from "react-toastify"

import CustomDialogActions from "../../../../reusables/CustomDialogActions"
import CustomDialogTitle from "../../../../reusables/CustomDialogTitle"
import DeleteChip from "../../../../reusables/DeleteChip"
import DialogCloseButton from "../../../../reusables/DialogCloseButton"
import NotificationDialog from "../../../../reusables/NotificationDialog"
import EditEstablishmentDialog from "../../EditEstablishmentDialog"
import PropertiesTableRow from "./PropertiesTableRow"
import propertiesSorting from "../../../../../utils/propertiesSorting"

import AddIcon from "@mui/icons-material/Add"
import { MutationButton } from "../../../../reusables/MutationButtonChipFab"
import { PROPERTY_MUTATION } from "../../../../Map/mapComponents/MapPropertyInfoPopoup"

const GET_ALL_PROPERTIES = gql`
	query MyQuery4 {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					id
					idColor
					propertiesByCostCenterId {
						nodes {
							id
							mmlPropertyId
							plotId
							propertyName
							propertyTag
							costCenterByCostCenterId {
								idColor
							}
							establishmentsByPropertyId {
								nodes {
									additionalInformation
									amount
									costCenterId
									discretionalyCorrectionMultiplier
									distanceCorrectionMultiplier
									id
									lateralDirection
									lateralDirectionCorrectionMultiplier
									operatingDistance
									roadCooperativeMemberId
									roadUnits
									roadUsageUnitByRoadUsageUnitId {
										id
										roadUsageUnit
										roadUsageUnitType
										weight
									}
									propertyByPropertyId {
										mmlPropertyId
										plotId
										propertyName
										id
									}
									weightCorrectionMultiplier
									roadCooperativeMemberByRoadCooperativeMemberId {
										name
										id
									}
								}
							}
							costCenterId
						}
					}
				}
			}
		}
	}
`

const UPDATE_PROPERTY = gql`
	mutation Update_property($id: Int = 10, $propertyName: String = "") {
		updatePropertyById(input: { propertyPatch: { propertyName: $propertyName }, id: $id }) {
			clientMutationId
		}
	}
`

// const DELETE_PROPERTY = gql`
// 	mutation DeleteProperty($id: Int = 10) {
// 		deletePropertyById(input: { id: $id }) {
// 			clientMutationId
// 		}
// 	}
// `

const GET_UPDATE_UNITS_VIEW_TYPE = gql`
	query GetUnitsViewType {
		roadCooperativeWithJWT {
			id
			roadCooperativeSettingsByRoadCooperativeId {
				nodes {
					id
					unitsPresentationType
				}
			}
		}
	}
`

type TableFilterEnumType = "asc" | "desc"

const PropertiesTable = ({
	activeCostCenterId,
	wholeRoadCooperative,
	activeCostCenterColor,
	refetchRCGeoJson,
	handlePopoverClick
}: {
	activeCostCenterId: number | null
	wholeRoadCooperative: boolean
	activeCostCenterColor: string | undefined
	refetchRCGeoJson: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
	handlePopoverClick: any
}) => {
	const [noPropertyData, setNoPropertyData] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const [selectedEst, setSelectedEst] = useState<Establishment>()
	const [selectedProperty, setSelectedProperty] = useState<Property>()
	const [allProperties, setAllProperties] = useState<Property[]>([])
	const [openEditProperty, setOpenEditProperty] = useState(false)
	const [selectedPropertyName, setSelectedPropertyName] = useState("")
	const [selectedPropertyMmlPropertyId, setSelectedPropertyMmlPropertyId] = useState("")
	const [openDeleteNoti, setOpenDeleteNoti] = useState(false)
	const [estSortDirection, setEstSortDirection] = useState<TableFilterEnumType>("asc")
	const [propertiesDirection, setPropertiesDirection] = useState<TableFilterEnumType>("desc")
	const [farmNameDirection, setFarmNameDirection] = useState<TableFilterEnumType>("asc")
	const [propertyAreaDirection, setPropertyAreaDirection] = useState<TableFilterEnumType>("asc")
	const [unitsViewType, setUnitsViewType] = useState<UnitsViewType>("professional")
	const [selectedProperties, setSelectedProperties] = useState<Property[]>([])
	const [filteredPropertiesList, setFilteredPropertiesList] = useState<Property[]>([])

	const { data: unitsViewTypeData } = useQuery(GET_UPDATE_UNITS_VIEW_TYPE, {
		onCompleted(data) {
			setUnitsViewType(data.roadCooperativeWithJWT.roadCooperativeSettingsByRoadCooperativeId.nodes[0].unitsPresentationType)
		}
	})

	const { data: propertiesData, refetch } = useQuery(GET_ALL_PROPERTIES, {
		variables: { id: activeCostCenterId }
	})

	useEffect(() => {
		if (!propertiesData) return
		let propertyList: Property[] = []
		for (let i = 0, len = propertiesData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length; len > i; i++) {
			const properties: Property = propertiesData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[i].propertiesByCostCenterId.nodes
			propertyList = propertyList.concat(properties)
		}

		if (propertyList.length == 0) {
			setNoPropertyData(true)
		}
		propertiesSorting(propertyList, setAllProperties)
	}, [propertiesData])

	const [updatePropertyData] = useMutation(PROPERTY_MUTATION, {
		onCompleted: () => {
			toast.success("Tiedot tallennettu", { role: "global" })
			setOpenEditProperty(false)
			refetch()
		}
	})

	const [deleteProperty] = useMutation(PROPERTY_MUTATION)

	useEffect(() => {
		if (!allProperties) return
		if (wholeRoadCooperative) {
			setSelectedProperties(allProperties)
		} else {
			setSelectedProperties(allProperties.filter((property: Property) => property.costCenterId == activeCostCenterId))
		}
	}, [activeCostCenterId, allProperties])

	const handleSavePropertyChanges = () => {
		console.log("SELECTED:", selectedProperty)
		if (selectedProperty) {
			updatePropertyData({
				variables: { id: selectedProperty.id, propertyName: selectedPropertyName, type: "update", from: "homePage" },
				onCompleted: () => {
					setOpenEditProperty(false)
					refetch()
					refetchRCGeoJson()
				},
				onError: () => {
					null
				}
			})
		}
	}

	const handleSelectProperty = (property: Property) => {
		if (!property.mmlPropertyId) return
		setSelectedProperty(property)
		setSelectedPropertyMmlPropertyId(property.mmlPropertyId)
		setSelectedPropertyName(property.propertyName ?? "")
		setOpenEditProperty(true)
	}

	const handleSelectEst = (est: Establishment) => {
		setOpenDialog(!openDialog)
		setSelectedEst(est)
	}

	const handleDeleteProperty = () => {
		if (selectedProperty) {
			// console.log("DELETED XXXXXXXX: ", selectedProperty)
			deleteProperty({
				variables: {
					id: selectedProperty.id,
					mmlPropertyId: selectedProperty.mmlPropertyId,
					type: "delete",
					from: "homePage"
				},
				onCompleted: () => {
					setOpenEditProperty(false)
					refetch()
					refetchRCGeoJson()
				},
				onError: () => {
					null
				}
			})
			setOpenDialog(false)
		}
	}

	const handleFarmNameSort = () => {
		const sortedList: any = [...filteredPropertiesList]
		if (farmNameDirection === "asc") {
			setFarmNameDirection("desc")
			sortedList.sort((a: { propertyName: string }, b: { propertyName: string }) => {
				return a.propertyName?.toLowerCase().localeCompare(b.propertyName?.toLowerCase())
			})
		} else {
			setFarmNameDirection("asc")
			sortedList.sort((a: { propertyName: string }, b: { propertyName: string }) => {
				return b.propertyName?.toLowerCase().localeCompare(a.propertyName?.toLowerCase())
			})
		}
		setSelectedProperties(sortedList)
	}

	const handlePropertyAreaSort = () => {
		const sortedList: any = [...filteredPropertiesList]
		let aOverall: any
		let bOverall: any
		if (propertyAreaDirection === "asc") {
			setPropertyAreaDirection("desc")
			sortedList.sort((a: { establishmentsByPropertyId: { nodes: string | any[] } }, b: { establishmentsByPropertyId: { nodes: string | any[] } }) => {
				return a.establishmentsByPropertyId.nodes.length - b.establishmentsByPropertyId.nodes.length
			})
		} else {
			setPropertyAreaDirection("asc")
			sortedList.sort((a: { establishmentsByPropertyId: { nodes: string | any[] } }, b: { establishmentsByPropertyId: { nodes: string | any[] } }) => {
				return b.establishmentsByPropertyId.nodes.length - a.establishmentsByPropertyId.nodes.length
			})
		}
		setSelectedProperties(sortedList)
	}

	const handleEstSort = () => {
		const sortedList: any = [...filteredPropertiesList]
		let aOverall: any
		let bOverall: any
		if (estSortDirection === "asc") {
			setEstSortDirection("desc")

			sortedList.sort(
				(
					a: { establishmentsByPropertyId: { length: number; nodes: any[] } },
					b: { establishmentsByPropertyId: { length: number; nodes: any[] } }
				): any => {
					if (a.establishmentsByPropertyId.length > 1) {
						aOverall = a.establishmentsByPropertyId.nodes.reduce((p: any, n: any) => p + n.roadUnits, 0)
					} else {
						aOverall = a.establishmentsByPropertyId.nodes.length < 1 ? 0 : a.establishmentsByPropertyId.nodes[0].roadUnits
					}
					if (b.establishmentsByPropertyId.length > 1) {
						bOverall = b.establishmentsByPropertyId.nodes.reduce((p: any, n: any) => p + n.roadUnits, 0)
					} else {
						bOverall = b.establishmentsByPropertyId.nodes.length < 1 ? 0 : b.establishmentsByPropertyId.nodes[0].roadUnits
					}
					return aOverall - bOverall
				}
			)
		} else {
			setEstSortDirection("asc")
			sortedList.sort(
				(
					a: { establishmentsByPropertyId: { length: number; nodes: any[] } },
					b: { establishmentsByPropertyId: { length: number; nodes: any[] } }
				): any => {
					if (a.establishmentsByPropertyId.length > 1) {
						aOverall = a.establishmentsByPropertyId.nodes.reduce((p: any, n: any) => p + n.roadUnits, 0)
					} else {
						aOverall = a.establishmentsByPropertyId.nodes.length < 1 ? 0 : a.establishmentsByPropertyId.nodes[0].roadUnits
					}
					if (b.establishmentsByPropertyId.length > 1) {
						bOverall = b.establishmentsByPropertyId.nodes.reduce((p: any, n: any) => p + n.roadUnits, 0)
					} else {
						bOverall = b.establishmentsByPropertyId.nodes.length < 1 ? 0 : b.establishmentsByPropertyId.nodes[0].roadUnits
					}
					return bOverall - aOverall
				}
			)
		}
		setSelectedProperties(sortedList)
	}

	const handlePropertiesSort = () => {
		const sortedList: any = [...filteredPropertiesList]
		if (propertiesDirection === "asc") {
			setPropertiesDirection("desc")
			sortedList.sort((a: { mmlPropertyId: string }, b: { mmlPropertyId: string }) => {
				const aArr = a.mmlPropertyId.split("-")
				const bArr = b.mmlPropertyId.split("-")
				const length = aArr > bArr ? aArr.length : bArr.length
				for (let i = 0; i < length; i++) {
					if (Number(aArr[i]) > Number(bArr[i]) || bArr[i] === undefined) {
						return 1
					}
					if (Number(aArr[i]) < Number(bArr[i]) || aArr[i] === undefined) {
						return -1
					}
				}
				return 0
			})
		} else {
			setPropertiesDirection("asc")
			sortedList.sort((a: { mmlPropertyId: string }, b: { mmlPropertyId: string }) => {
				const aArr = a.mmlPropertyId!.split("-")
				const bArr = b.mmlPropertyId!.split("-")
				const length = aArr > bArr ? aArr.length : bArr.length
				for (let i = 0; i < length; i++) {
					if (Number(bArr[i]) > Number(aArr[i]) || aArr[i] === undefined) {
						return 1
					}
					if (Number(bArr[i]) < Number(aArr[i]) || bArr[i] === undefined) {
						return -1
					}
				}
				return 0
			})
		}
		setSelectedProperties(sortedList)
	}

	const handleFilterChange = (value: string) => {
		if (!value) setFilteredPropertiesList(selectedProperties)
		else {
			const temp: Property[] = []
			for (let i = 0, len = selectedProperties.length; i < len; i++) {
				if (
					selectedProperties[i].mmlPropertyId?.toUpperCase().includes(value.toUpperCase()) ||
					selectedProperties[i].propertyName?.toUpperCase().includes(value.toUpperCase())
				) {
					if (!temp.includes(selectedProperties[i])) {
						temp.push(selectedProperties[i])
					}
				}
			}
			setFilteredPropertiesList(temp)
		}
	}

	useEffect(() => {
		setFilteredPropertiesList(selectedProperties)
	}, [selectedProperties])

	///

	return (
		<>
			<Dialog open={openDialog}>
				<DialogCloseButton closeFunction={() => setOpenDialog(false)} />
				<CustomDialogTitle>Yksikkötiedon tarkastelu</CustomDialogTitle>
				<DialogContent>
					{selectedEst ? <EditEstablishmentDialog id={selectedEst.id} functionCallOnDataChange={refetch} setOpenEdit={setOpenDialog} /> : null}
				</DialogContent>
				<CustomDialogActions>
					<Button variant="outlined" color="primary" onClick={() => setOpenDialog(false)}>
						Sulje
					</Button>
				</CustomDialogActions>
			</Dialog>

			<NotificationDialog
				dialogTitle="Poista kiinteistö"
				setOpenState={setOpenDeleteNoti}
				openState={openDeleteNoti}
				executefunction={handleDeleteProperty}
				executeButtonText="Poista"
			>
				Haluatko varmasti poistaa kiinteistön? Poistat myös samalla kiinteistöön liitetyt yksiköt.
			</NotificationDialog>

			<Dialog open={openEditProperty}>
				<DeleteChip deleteFunction={setOpenDeleteNoti} functionParams={true} />
				<CustomDialogTitle>Kiinteistötietojen muokkaaminen</CustomDialogTitle>
				<DialogCloseButton closeFunction={() => setOpenEditProperty(false)} />
				<DialogContent>
					<Box sx={{ padding: "20px" }}>
						<Grid container rowSpacing={1} columnSpacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField disabled label="Kiinteistötunnus" fullWidth value={selectedPropertyMmlPropertyId}></TextField>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									inputProps={{
										maxLength: 100
									}}
									fullWidth
									label="Tilan nimi"
									onChange={(e: { target: { value: string } }) => setSelectedPropertyName(e.target.value)}
									value={selectedPropertyName}
								></TextField>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<CustomDialogActions>
					{/* <Stack direction='row' sx={{ justifyContent: 'space-between' }}  > */}
					<Button variant="outlined" color="primary" onClick={() => setOpenEditProperty(false)}>
						Sulje
					</Button>
					<Button variant="contained" color="primary" onClick={handleSavePropertyChanges}>
						Tallenna
					</Button>
					{/* </Stack> */}
				</CustomDialogActions>
			</Dialog>

			<TableContainer sx={{ maxHeight: "100%" }}>
				<Table stickyHeader size="medium" padding="none">
					<TableHead>
						<TableRow>
							<TableCell colSpan={7} align="center" sx={{ borderBottom: "1px solid white", paddingBottom: "10px" }}>
								<TextField
									size="small"
									fullWidth
									label="Hae"
									color="primary"
									sx={{ maxWidth: "300px", width: screen.width < 600 ? 250 : 460 }}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e.target.value)}
								/>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</TableContainer>

			<TableContainer sx={{ maxHeight: "calc(100% - 70px)" }}>
				<Table stickyHeader size="medium" padding="none">
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: "1px", margin: 0, padding: 0 }}></TableCell>
							<TableCell></TableCell>
							<TableCell sx={{ fontWeight: "700" }}>
								Tilan nimi
								<TableSortLabel active={true} onClick={handleFarmNameSort} direction={farmNameDirection} />
							</TableCell>
							<TableCell sx={{ fontWeight: "700" }}>
								Kiinteistötunnus
								<TableSortLabel active={true} onClick={handlePropertiesSort} direction={propertiesDirection} />
							</TableCell>
							<TableCell sx={{ fontWeight: "700" }}>
								Yksikköalat
								<TableSortLabel active={true} onClick={handlePropertyAreaSort} direction={propertyAreaDirection} />
							</TableCell>
							<TableCell sx={{ fontWeight: "700" }}>
								Tieyksiköt
								<TableSortLabel active={true} onClick={handleEstSort} direction={estSortDirection} />
							</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{noPropertyData ? (
							<TableRow>
								<TableCell colSpan={7}>
									<Box
										sx={{
											height: "100%",
											width: "100%",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
											paddingTop: "20px",
											paddingBottom: "20px"
										}}
									>
										<Typography sx={{ fontSize: "1rem", textAlign: "center", fontWeight: "400", padding: "10px" }}>
											Ei lisättyjä kiinteistöjä.
										</Typography>
										<MutationButton variant="contained" onClick={handlePopoverClick}>
											Lisää tie ja kiinteistöt
											<AddIcon sx={{ paddingLeft: "10px" }} />
										</MutationButton>
									</Box>
								</TableCell>
							</TableRow>
						) : selectedProperties.length === 0 ? (
							<TableRow>
								<TableCell colSpan={7}>
									<Box
										sx={{
											height: "100%",
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										<CircularProgress />
									</Box>
								</TableCell>
							</TableRow>
						) : (
							filteredPropertiesList.map((property: Property, index: number) => {
								return (
									<PropertiesTableRow
										activeCostCenterColor={activeCostCenterColor}
										handleSelectProperty={handleSelectProperty}
										property={property}
										key={"FilteredProperties-" + property.id}
										handleSelectEstablishment={handleSelectEst}
										unitsViewType={unitsViewType}
									/>
								)
							})
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

export default PropertiesTable
