import React, { useState } from "react"

import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import EditIcon from "@mui/icons-material/Edit"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { TableContainer } from "@mui/material"
import { Paper } from "@mui/material"
import { tableCellHeaderStyles, tableCellHeaderTypographyStyles, tableCellTypographyStyles } from "../customStyles"
import CopyableText from "../../../../reusables/CopyableText"
import { MutationIconButton } from "../../../../reusables/MutationButtonChipFab"

type PropertiesTableRowProps = {
	activeCostCenterColor: string | undefined
	property: Property
	handleSelectEstablishment: (establishment: Establishment) => void
	handleSelectProperty: (property: Property) => void
	unitsViewType: UnitsViewType
}

const PropertiesTableRow = ({ property, handleSelectEstablishment, handleSelectProperty, unitsViewType }: PropertiesTableRowProps) => {
	const [open, setOpen] = useState(false)

	const propertyName = property?.propertyName ? (property?.propertyName.length > 15 ? property.propertyName.slice(0, 15) + "..." : property.propertyName) : ""

	const propertyRoadUnits = property.establishmentsByPropertyId.nodes.reduce((acc: number, est: Establishment) => {
		return (acc += est.roadUnits)
	}, 0)

	const combinedText =
		property.propertyTag !== 1 && property.propertyTag !== undefined ? `${property.mmlPropertyId}/${property.propertyTag}` : property.mmlPropertyId

	return (
		<React.Fragment>
			<TableRow>
				<TableCell sx={{ paddingBottom: "25px", paddingTop: "25px" }}>
					<Box
						sx={{
							width: "12px",
							height: "12px",
							background: property.costCenterByCostCenterId.idColor,
							borderRadius: "8px",
							marginRight: "5px",
							marginLeft: "10px"
						}}
					></Box>
				</TableCell>
				<TableCell sx={{ width: "15px", fontWeight: "500" }}>
					{open ? (
						<IconButton
							// color='primary'
							// sx={{ width: "10px" }}
							size="medium"
							onClick={() => setOpen(!open)}
						>
							<ArrowForwardIosIcon fontSize="small" sx={{ transform: "rotate(90deg) " }} />
						</IconButton>
					) : (
						<IconButton
							// color='primary'
							// sx={{ width: "10px" }}
							size="medium"
							onClick={() => setOpen(!open)}
						>
							<ArrowForwardIosIcon fontSize="small" />
						</IconButton>
					)}
				</TableCell>
				<TableCell>
					<Typography sx={{ fontWeight: "500", fontSize: "0.875rem" }}> {propertyName}</Typography>
				</TableCell>
				<TableCell>
					<Typography sx={{ fontWeight: "500", fontSize: "0.875rem" }}>
						<CopyableText text={combinedText} />
					</Typography>
				</TableCell>
				<TableCell>
					<Typography sx={{ fontWeight: "500", fontSize: "0.875rem" }}> {property.establishmentsByPropertyId.nodes.length}</Typography>
				</TableCell>
				<TableCell>
					<Typography sx={{ fontWeight: "500", fontSize: "0.875rem" }}>{propertyRoadUnits}</Typography>
				</TableCell>
				<TableCell>
					<MutationIconButton
						// sx={{ color: property.costCenterByCostCenterId.idColor }}

						onClick={() => handleSelectProperty(property)}
					>
						<EditIcon sx={{ width: "20px", color: "rgba(0, 0, 0, 0.54)" }} />
					</MutationIconButton>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<TableContainer component={Paper}>
							<Typography sx={{ fontWeight: "500", fontStyle: "italic", fontSize: "0.8rem", marginLeft: "10px" }}>
								Kiinteistön {property.mmlPropertyId} yksikkölistaus
							</Typography>
							<Table size={"small"}>
								<TableHead>
									<TableCell sx={tableCellHeaderStyles}></TableCell>

									<TableCell sx={tableCellHeaderStyles}>Osakas</TableCell>
									{unitsViewType == "professional" && (
										<TableCell sx={tableCellHeaderStyles}>
											<Typography sx={tableCellHeaderTypographyStyles}>Liikennelaji</Typography>
										</TableCell>
									)}
									<TableCell sx={tableCellHeaderStyles}>
										<Typography sx={tableCellHeaderTypographyStyles}>Yksiköt</Typography>
									</TableCell>
									<TableCell sx={tableCellHeaderStyles}>
										<Typography sx={tableCellHeaderTypographyStyles}>Lisätieto</Typography>
									</TableCell>
									<TableCell sx={tableCellHeaderTypographyStyles}></TableCell>
								</TableHead>
								<TableBody>
									{property.establishmentsByPropertyId.nodes.length ? (
										property.establishmentsByPropertyId.nodes.map((est: Establishment) => {
											const additionalInformation = est?.additionalInformation
												? est.additionalInformation.length > 40
													? est.additionalInformation.slice(0, 40) + "..."
													: est.additionalInformation
												: ""
											return (
												<TableRow key={est.id + est.roadUnits}>
													<TableCell sx={{ fontSize: "1rem" }}></TableCell>
													<TableCell sx={{ paddingRight: "10px", fontWeight: "500" }}>
														{est.roadCooperativeMemberByRoadCooperativeMemberId.name}
													</TableCell>
													{unitsViewType == "professional" && (
														<TableCell sx={{ paddingRight: "10px", fontWeight: "500" }}>
															<Typography sx={tableCellTypographyStyles}>
																{est.roadUsageUnitByRoadUsageUnitId.roadUsageUnit}
															</Typography>
														</TableCell>
													)}
													<TableCell sx={{ fontWeight: "500" }}>
														<Typography sx={tableCellTypographyStyles}>{est.roadUnits}</Typography>{" "}
													</TableCell>
													<TableCell sx={{ fontWeight: "500" }}>
														<Typography sx={tableCellTypographyStyles}> {additionalInformation}</Typography>
													</TableCell>

													<TableCell sx={{ fontSize: "1rem" }}>
														<MutationIconButton onClick={() => handleSelectEstablishment(est)}>
															<EditIcon sx={{ width: "20px", color: "rgba(0, 0, 0, 0.54)" }} />
														</MutationIconButton>
													</TableCell>
												</TableRow>
											)
										})
									) : (
										<TableRow>
											<TableCell colSpan={5} sx={{ textAlign: "center" }}>
												Kiinteistöllä ei ole yksiköitä.
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	)
}

export default PropertiesTableRow
