import { Button, TextField, Stack, Container } from "@mui/material"
import React, { SetStateAction, useState } from "react"
import CustomDialogActions from "../reusables/CustomDialogActions"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import DialogCloseButton from "../reusables/DialogCloseButton"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Dayjs } from "dayjs"
import fiLocale from "date-fns/locale/fi"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Grid } from "@mui/material"

interface CreateTaskDialogProps {
	setCreateNewTask: React.Dispatch<SetStateAction<boolean>>
	addTask: (newTask: Task) => void
	allTaskRoadCooperativeNames: string[]
}
const emptyTaskDialog: Task = {
	title: "",
	content: "",
	type: "task",
	dateTime: "",
	roadCooperativeName: "",
	completed: false
}

const CreateTaskDialog = ({ setCreateNewTask, addTask, allTaskRoadCooperativeNames }: CreateTaskDialogProps) => {
	const [alert, setAlert] = useState("")
	const [task, setTask] = useState(emptyTaskDialog)

	const createNewTask = () => {
		//if (checkTaskParameters(task, setAlert)) {
		//	const taskobj: any = {}
		//}
		const newTaskObj: Task = {
			title: newTitle,
			content: newContent,
			type: "task",
			roadCooperativeName: newRoadCooperativeName,
			open: false,
			dateTime: newDateTime == null ? "" : newDateTime.toISOString(),
			completed: false
		}
		addTask(newTaskObj)
	}

	const [newTitle, setNewTitle] = useState<string>("")

	const [newRoadCooperativeName, setNewRoadCooperativeName] = useState<string>("")
	const [newContent, setNewContent] = useState<string>("")
	const [newDateTime, setNewDateTime] = React.useState<Dayjs | null>(null)
	const [tasks, setTasks] = useState<Task[]>([])

	const RCTstyle = {
		//todo stailaus loppuun
		//border: "solid 1px",
		//borderRadius: "5px",
		//borderColor: "grey",
		//height: "23px",
		//paddingTop: "15.5px",
		//paddingBottom: "15.5px",
		//paddingRight: "0.01px",
		//paddingLeft: "0.01px",
		width: "100%"
	}

	const box = document.getElementById("box")

	if (box! != null) {
		box.style.setProperty("border-color", "green")
	}

	return (
		<>
			<DialogCloseButton closeFunction={() => setCreateNewTask(false)} />
			<CustomDialogTitle>Luo uusi Tehtävä</CustomDialogTitle>
			<Container
				maxWidth="md"
				sx={{
					overflow: "auto",
					justifyContent: "center",
					height: "100%",
					maxWidth: "100%",
					textAlign: "center",
					display: "flex"
				}}
				elevation={10}
			>
				<Stack direction="column" justifyContent="center" alignItems="center" padding="5px" spacing={2} width="100%">
					<TextField
						sx={{ marginTop: "12px", marginBottom: "0px", width: "90%" }}
						label="Tiekunta"
						fullWidth
						type="text"
						InputProps={{
							inputProps: { list: "data" }
						}}
						onChange={(e: { target: { value: string } }) => {
							setNewRoadCooperativeName(e.target.value)
						}}
					/>
					<datalist id="data" className="">
						<option value={""}></option>
						{allTaskRoadCooperativeNames.map((item, key) => {
							return <option key={key} value={item}></option>
						})}
					</datalist>
					{/* <input
							id="box"
							type="text"
							list="data"
							style={RCTstyle}
							placeholder="Tiekunta"
							className="MuiTextField-root MuiInputBase-input MuiOutlinedInput-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input"
							onChange={(e: { target: { value: string } }) => {
								setNewRoadCooperativeName(e.target.value)
							}}
						/> */}

					<TextField
						style={{ width: "90%" }}
						required
						label="Otsikko"
						onChange={(e: { target: { value: string } }) => {
							setNewTitle(e.target.value)
						}}
					></TextField>
					<TextField
						overflow-y="scroll"
						rows={9}
						cols="20"
						style={{ width: "90%" }}
						label="Sisältö"
						multiline
						onChange={(e: { target: { value: string } }) => setNewContent(e.target.value)}
					></TextField>
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={fiLocale}>
						<DateTimePicker
							views={["year", "month", "day", "hours", "minutes"]}
							renderInput={(props) => <TextField {...props} style={{ marginBottom: "12px", maxWidth: "50%", minWidth: "210px" }} />}
							label="Päivämäärä/Kellonaika"
							value={newDateTime}
							inputFormat="dd.MM.yyyy HH:mm"
							onChange={(value: Dayjs | null) => setNewDateTime(value)}
							desktopModeMediaQuery=""
							PopperProps={{
								sx: {
									zIndex: "1493"
								}
							}}
						/>
					</LocalizationProvider>
				</Stack>
			</Container>
			<CustomDialogActions>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						setCreateNewTask(false)
					}}
				>
					Sulje
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						createNewTask()
					}}
				>
					{/* TODO: ota id pois kun bäkki on valmis */}
					Tallenna tehtävä
				</Button>
			</CustomDialogActions>
		</>
	)
}

export default CreateTaskDialog
