import { ThemeProvider } from "@emotion/react"
import { Stack } from "@mui/material"
import { Tooltip } from "@mui/material"

import { Button } from "@mui/material"
import { Typography } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import React from "react"

interface Props {
	municipalityName?: string
}

const theme = createTheme({
	palette: {
		action: {
			disabledBackground: "#FFF380"
		}
	}
})

const MunicipalityButtonWaiting: React.FC<Props> = ({ municipalityName }: any) => {
	const vaakunaSrc = `/municipalityLogos/${encodeURIComponent(municipalityName)}.png`

	return (
		<ThemeProvider theme={theme}>
			<Tooltip title="Odottaa kunnan käsittelyä.">
				<Button
					variant="contained"
					// disabled
					sx={{
						position: "fixed",
						cursor: "default",
						borderRadius: "5px",
						backgroundColor: "#FFF380",
						minWidth: "auto",
						height: "47px",
						marginTop: "10px",
						marginRight: "5px",
						right: "300px",
						paddingRight: "20px",
						paddingLeft: "50px",
						zIndex: "1000",
						"&:hover": {
							filter: "brightness(90%)",
							backgroundColor: "#c5c1c1"
						},
						"@media screen and (max-width: 900px)": {
							display: "none"
						},
						boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
					}}
				>
					<Stack direction="row" sx={{ width: "auto", height: "48px", alignItems: "center" }}>
						<img
							src={vaakunaSrc}
							style={{
								width: "60px",
								height: "60px",
								objectFit: "contain",
								position: "absolute",
								left: "-15px", // Säädä tätä arvoa tarvittaessa
								filter: "sepia(1) brightness(100%) saturate(150%)",
								cursor: "default"
							}}
							alt={municipalityName}
						/>
						<Typography
							sx={{
								color: "#505050",
								fontWeight: "700",
								fontSize: "0.8rem",
								display: "inline"
							}}
						>
							{municipalityName}
						</Typography>
					</Stack>
				</Button>
			</Tooltip>
		</ThemeProvider>
	)
}

export default MunicipalityButtonWaiting
