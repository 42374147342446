import vaylaDataService from "../../../network/dataServices/vaylaDataService"
import { convertToStandard } from "./coordinateConverter"
import { getKuntakoodiFromName } from "./kuntakoodit"

const searchPlaceByName = async (kuntanimi: string | undefined, katunimi: string | undefined) => {
	if (kuntanimi === undefined || kuntanimi === undefined) return
	const kuntakoodi = getKuntakoodiFromName(kuntanimi)
	const response = await vaylaDataService.selectRoadByKatunimiAndKuntakoodi(kuntakoodi, katunimi)
	const latLng = convertToStandard(response.features[0].properties.x, response.features[0].properties.y)
	return latLng
}

export default searchPlaceByName
