import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

import { BrowserRouter as Router } from "react-router-dom"
import "./utils/providers/languageProvider/i18n"
import ErrorNotifier from "./utils/ErrorNotifier"
import CustomApolloProvider from "./network/apolloClient/apolloClient"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import fiLocale from "date-fns/locale/fi"

ReactDOM.render(
	<ErrorNotifier>
		{(showError: JSX.Element) => (
			<CustomApolloProvider showError={showError}>
				<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={fiLocale}>
					<Router>
						<App />
					</Router>
				</LocalizationProvider>
			</CustomApolloProvider>
		)}
	</ErrorNotifier>,
	document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
