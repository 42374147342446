import { InMemoryCache } from "@apollo/client"
import { costCenterPropertyVar, testVar, costCenterNameVar, costCenterLinksVar, userProfileVar } from "./apolloClientMakeVars"

export const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				costCenterPropertyVar: {
					read() {
						return costCenterPropertyVar()
					}
				},
				costCenterNameVar: {
					read() {
						return costCenterNameVar()
					}
				},
				costCenterLinksVar: {
					read() {
						return costCenterLinksVar()
					}
				},
				testVar: {
					read() {
						return testVar()
					}
				},
				userProfileVar: {
					read() {
						return userProfileVar()
					}
				}
				// Add new makeVars here
			}
		}
	}
})
