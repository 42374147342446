import React, { useEffect, useState } from "react"
import "./Login.css"
// import { useQuery } from '@apollo/react-hooks'
// import {
//     ApolloClient, ApolloProvider, gql, HttpLink, InMemoryCache
//   } from '@apollo/client'
import axios from "axios"
import { toast } from "react-toastify"

import LoginForm from "./LoginForm"

import { gql } from "@apollo/client"
import config from "../../utils/config"
import { Typography, Dialog, DialogContent, TextField, Button, Stack, CircularProgress } from "@mui/material"
import DialogCloseButton from "../reusables/DialogCloseButton"
import { changePassword, loginUser, registerUser, verifyUserEmail } from "../../network/dataServices/userDataService"

//import RegisterForm from './RegisterForm';
import FullForm from "./FullForm"

interface GetUserParams {
	userByEmail: {
		name: string
		email: string
	}
}

const GET_USER = gql`
	query MyQuery($email: String!) {
		userByEmail(email: $email) {
			name
			email
		}
	}
`

const baseURL = config.BACKEND_ROOT_URL

const Login = () => {
	const [userRegistered, setUserRegistered] = useState(false)
	const [showEmailVerifierForm, setShowEmailVerifierForm] = useState(false)
	const [emailVerificationStatus, setEmailVerificationStatus] = useState<null | "verifying" | "success">(null)

	const [openForgetPasswordDialog, setOpenForgetPasswordDialog] = useState(false)

	const [passwordForgotEmail, setPasswordForgotEmail] = useState("")
	const [resetPasswordSended, setResetPasswordSended] = useState(false)
	const [changingPassword, setChangingPassword] = useState<boolean>(false)

	const [changedPassword, setChangedPassword] = useState<string>("")
	const [changedPasswordMatch, setChangedPasswordMatch] = useState<string>("")

	const [userData, setUserData] = useState({ email: "", password: "" })

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
	}

	const checkLoginEventValues = (e: React.ChangeEvent<HTMLFormElement>) => {
		const errors: string[] = []
		if (e.target.email.value) {
			if (!validateEmail(e.target.email.value)) {
				errors.push("Tarkista sähköpostin oikeinkirjoitus.")
			}
		} else {
			errors.push("Sähköposti puuttuu.")
		}
		if (!e.target.password.value) {
			errors.push("Salasana puuttuu.")
		}

		if (errors.length > 0) {
			const errorObj = (
				<div>
					{errors.map((e: string) => {
						return <li key={e}>{e}</li>
					})}
				</div>
			)
			toast.error(errorObj, { role: "global-top-center" })
		}

		return errors
	}

	const handleLogin = async (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault()

		loginUser(e.target.email.value, e.target.password.value)
	}

	const handleRegister = async (userObj: any) => {
		const requestBody = {
			fullName: userObj.fullName,
			email: userObj.email,
			password: userObj.password
		}

		const registerRes: any = await registerUser(requestBody)

		if (registerRes.status === 400) {
			if (registerRes.message === "Network Error") {
				toast.error("Yhteyden muodostaminen palvelimelle epäonnistui. Yritä myöhemmin uudelleen.")
			} else if (registerRes.error && registerRes.error.response.status == 403) {
				toast.error("Tievahdin käyttö on toistaseksi rajoitettu. Syöttämäsi sähköposti ei ole sallittujen listalla.")
			} else if (registerRes.error && registerRes.error.response.status == 409) {
				toast.error("Sähköpostiosoiteella on jo rekisteröity käyttäjä.")
			} else {
				toast.error("Käyttäjän luonti epäonnistui.")
			}
		} else {
			toast.success("Käyttäjä luotu", { role: "global" })
			setShowEmailVerifierForm(true)
			// setUserRegistered(true)
			window.history.pushState({}, document.title, "/")
		}
	}

	const handleVerification = async (verificationId: string) => {
		setEmailVerificationStatus("verifying")
		const res = await verifyUserEmail(verificationId)
		if (res.status < 300) setEmailVerificationStatus("success")
		else {
			setEmailVerificationStatus(null)
			toast.error("Sähköpostin vahvistaminen epäonnistui. Yritä myöhemmin uudelleen.")
		}
	}

	const handleChangePassword = async () => {
		const changePasswordId = new URLSearchParams(window.location.search).get("changePasswordId")?.toString()
		if (!changePasswordId) toast.error("Virheellinen linkki salasanan vaihdolle.")
		else if (changedPassword !== changedPasswordMatch) toast.error("Salasanat eivät täsmää")
		else {
			const res = await changePassword(changePasswordId, changedPassword)
			if (res && res.status < 300) {
				toast.success("Salasana vaihdettu onnistuneesti")
				setChangingPassword(false)
			}
		}
	}

	// Handle url param registration
	useEffect(() => {
		const username: string | undefined = new URLSearchParams(window.location.search).get("username")?.toString()
		const email: string | undefined = new URLSearchParams(window.location.search).get("email")?.toString()
		const password: string | undefined = new URLSearchParams(window.location.search).get("psw")?.toString()
		const reg: string | undefined = new URLSearchParams(window.location.search).get("reg")?.toString()

		if (username && email && password && reg == "true") {
			const requestBody = {
				fullName: username,
				email: email,
				password: password
			}

			handleRegister(requestBody)
			// window.history.pushState({}, document.title, "/")
		}
		// http://localhost:3000/?psw=123123123&username=ArviMusta&email=arvi.mustajarvi+%2B+12345@gmail.comreg=true
	}, [])

	// Handle email verification link
	useEffect(() => {
		const verificationId = new URLSearchParams(window.location.search).get("verificationId")?.toString()
		if (verificationId) handleVerification(verificationId)
	}, [])

	// Handle reset password link
	useEffect(() => {
		const changePasswordId = new URLSearchParams(window.location.search).get("changePasswordId")?.toString()
		if (changePasswordId) {
			setChangingPassword(true)
		}
	}, [])

	const handleSendResetEmail = () => {
		const requestBody = {
			loginId: passwordForgotEmail
		}
		setPasswordForgotEmail("")
		setOpenForgetPasswordDialog(false)
		const resetPasswordToast = toast.loading("Lähetetään linkkiä sähköpostiin.")
		axios
			.post(`${baseURL}/login/forgotPassword`, requestBody)
			.then((r) => {
				toast.update(resetPasswordToast, {
					render: "Linkki lähetetty",
					isLoading: false,
					type: toast.TYPE.SUCCESS
				})
			})
			.catch((r) => {
				toast.update(resetPasswordToast, {
					render: "Salasanan vaihto epäonnistui",
					isLoading: false,
					type: toast.TYPE.ERROR
				})
			})
	}

	// let pageContent: ReactElement

	// const queryString: string | undefined = new URLSearchParams(window.location.search).get("email")?.toString()
	// if (userRegistered) {
	// const pageContent = <LoginForm handleLogin={handleLogin} />
	// } else if (queryString) {
	// 	pageContent = <RegisterForm handleRegister={handleRegister} setUserRegistered={setUserRegistered} />
	// } else {
	const pageContent = (
		<FullForm
			handleLogin={handleLogin}
			setUserData={setUserData}
			userData={userData}
			handleRegister={handleRegister}
			setOpenForgetPasswordDialog={setOpenForgetPasswordDialog}
		/>
	)
	// }

	if (showEmailVerifierForm) {
		return (
			<div>
				<div className="login-container">
					<div className="wrapper fadeInDown">
						<div className="login-flex-row">
							<div id="formContent5">
								<div className="fadeIn first"></div>
								<div className="loginFormTitle" style={{ color: "black", paddingTop: "15px" }}>
									Käyttäjä rekisteröity onnistuneesti
								</div>
								<label className="registerFormText">
									Ennen kirjautumista, aktivoi tilisi sähköpostiisi tulleesta linkistä. Jos et löydä vahvistusviestiä saapuneista, tarkista
									myös roskapostikansio.
								</label>
								<button
									style={{ outline: "none" }}
									className="loginFormButton"
									onClick={() => {
										window.location.href = "/"
									}}
								>
									{" "}
									OK{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	if (emailVerificationStatus == "success")
		return (
			<div>
				<div className="login-container">
					<div className="wrapper fadeInDown">
						<div className="login-flex-row">
							<div id="formContent5">
								<div className="fadeIn first"></div>
								<div className="loginFormTitle" style={{ paddingTop: "15px", fontSize: "1.5em" }}>
									Vahvistus suoritettu!
								</div>
								<div className="registerFormText">Sähköpostiosoitteesi on nyt vahvistettu. Voit jatkaa kirjautumalla sisään.</div>
								<button
									style={{ outline: "none" }}
									className="loginFormButton"
									onClick={() => {
										window.location.href = "/"
									}}
								>
									Jatka
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)

	if (emailVerificationStatus == "verifying")
		return (
			<div>
				<div className="login-container">
					<div className="wrapper fadeInDown">
						<div className="login-flex-row">
							<div id="formContent5">
								<div className="fadeIn first"></div>
								<div className="loginFormTitle" style={{ paddingTop: "15px", fontSize: "1.5em" }}>
									<CircularProgress />
								</div>
								<div className="registerFormText">Vahvistetaan sähköpostia...</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)

	if (changingPassword)
		return (
			<div>
				<div className="login-container">
					<div className="wrapper fadeInDown">
						<div className="login-flex-row">
							<div id="formContent5">
								<div className="fadeIn first"></div>
								<div className="loginFormTitle" style={{ paddingTop: "15px", fontSize: "1.5em" }}>
									Syötä uusi salasana:
								</div>
								<input
									onChange={(e: { target: { value: string } }) => setChangedPassword(e.target.value)}
									type="password"
									id="change-password"
									className="loginFormText2 fadeIn second"
									name="change-password"
									placeholder="Uusi salasana"
								/>
								<input
									onChange={(e: { target: { value: string } }) => setChangedPasswordMatch(e.target.value)}
									type="password"
									id="changed-password-again"
									className="loginFormText2 fadeIn second"
									name="changed-password-again"
									placeholder="Salasana uudelleen"
								/>
								<button style={{ outline: "none" }} onClick={handleChangePassword} className="loginFormButton fadeIn third">
									Vaihda salasana
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)

	return (
		<div>
			<Dialog open={openForgetPasswordDialog} onBackdropClick={() => setOpenForgetPasswordDialog(false)}>
				<DialogCloseButton closeFunction={() => setOpenForgetPasswordDialog(false)} />
				<DialogContent>
					<>
						<Stack direction={"column"} sx={{}}>
							<Typography sx={{ padding: "20px", paddingTop: "0", marginTop: "20px" }}>Kirjoita käyttäjän sähköpostiosoite</Typography>
							<TextField
								fullWidth
								label={"Sähköposti"}
								value={passwordForgotEmail}
								onChange={(e: { target: { value: string } }) => setPasswordForgotEmail(e.target.value)}
							></TextField>
							<Button sx={{ marginTop: "10px" }} onClick={() => handleSendResetEmail()} fullWidth variant="contained">
								Lähetä
							</Button>
						</Stack>
					</>
				</DialogContent>
			</Dialog>

			{pageContent}
		</div>
	)
}

export default Login
