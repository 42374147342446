import React, { MutableRefObject, useEffect, useState } from "react"
import "./Map.css"
import { Typography } from "@mui/material"
import { Paper } from "@mui/material"
import { IconButton } from "@mui/material"
import { MapLayerType, MapProviderDispatch, mapProviderStateType, useMapState } from "../../utils/providers/mapProvider/mapProvider"
import { Box } from "@mui/material"
import { Slider } from "@mui/material"
import { Stack } from "@mui/material"
import { Fab } from "@mui/material"
import { Switch } from "@mui/material"
import { Collapse } from "@mui/material"
import { Tooltip } from "@mui/material"

import FormatShapesIcon from "@mui/icons-material/FormatShapes"
import PolylineIcon from "@mui/icons-material/Polyline"
import DeleteIcon from "@mui/icons-material/Delete"
import BrushIcon from "@mui/icons-material/Brush"
import FmdBadIcon from "@mui/icons-material/FmdBad"
import LayersIcon from "@mui/icons-material/Layers"
import CloseIcon from "@mui/icons-material/Close"

type MapToolAndLayersPopupType = {
	map: MapInstance
	drawFunc: MutableRefObject<MapboxDraw | null>
}

const MapToolAndLayersPopup = ({ map, drawFunc }: MapToolAndLayersPopupType) => {
	const { state, dispatch } = useMapState()

	const [openDrawTools, setOpenDrawTools] = useState(true)

	const togglePropertyClickFunction = () => {
		if (state.mapFeatures.showPropertyInfoByClick) {
			dispatch({ type: "setShowPropertyInfoByClick", payload: false })
		} else {
			dispatch({ type: "setShowPropertyInfoByClick", payload: true })
		}
	}

	const toggleDrawFunction = () => {
		if (state.mapFeatures.draw) {
			dispatch({ type: "setDraw", payload: false })
		} else {
			dispatch({ type: "setDraw", payload: true })
		}
	}

	const handleToggleLayersPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
		const layersBoxEl = document.getElementById("map_layers_options")
		if (layersBoxEl) {
			if (layersBoxEl.style.visibility == "visible") {
				layersBoxEl.style.opacity = "0"
				layersBoxEl.style.visibility = "hidden"
			} else {
				layersBoxEl.style.opacity = "1"
				layersBoxEl.style.visibility = "visible"
			}
		}
	}

	const [selectedButton, setSelectedButton] = useState("")

	const handleButtonClick = (buttonId: string) => {
		if (selectedButton === buttonId && selectedButton !== "") {
			return
		}
		{
			setSelectedButton(buttonId)
		}
	}

	const handleKeyPress = (event: any) => {
		if ((event.key === "Enter" || event.key === "Escape") && selectedButton === "draw_paint_mode") {
			return
		} else if (event.key === "Enter" || event.key === "Escape") {
			setSelectedButton("")
		}
	}

	// const handlePaintModeButtonClick = () => {
	// 	if (selectedButton === "draw_paint_mode") {
	// 		setSelectedButton("")
	// 	} else {
	// 		setSelectedButton("draw_paint_mode")
	// 	}
	// }

	const [markerColor, setMarkerColor] = useState("#505050")

	const handleMarkerClick = () => {
		setMarkerColor("primary")
	}

	const handleMouseDown = (event: any) => {
		setMarkerColor("#505050")
	}

	useEffect(() => {
		const handleDocumentMouseDown = (event: any) => {
			if (event.button === 0) {
				handleMouseDown(event)
			}
		}

		document.addEventListener("mousedown", handleDocumentMouseDown)

		return () => {
			document.removeEventListener("mousedown", handleDocumentMouseDown)
		}
	}, [])

	useEffect(() => {
		document.addEventListener("keydown", handleKeyPress)
		return () => {
			document.removeEventListener("keydown", handleKeyPress)
		}
	}, [])

	return (
		<>
			<Fab
				color={"primary"}
				onClick={handleToggleLayersPopup}
				sx={{ padding: "10px", maxWidth: "400px", position: "absolute", bottom: `5px`, left: "5px", zIndex: 200 }}
			>
				<LayersIcon />
			</Fab>

			<Box
				id={"map_layers_options"}
				sx={{
					padding: "3px",
					maxWidth: "400px",
					position: "absolute",
					bottom: `55px`,
					zIndex: 200,
					opacity: "0",
					transition: "visibility 0.5s, opacity 0.1s linear",
					visibility: "hidden",
					height: "calc(100% - 100px)"
				}}
			>
				<Paper
					sx={{
						padding: "10px",
						maxWidth: "400px",
						maxHeight: "100%",
						position: "absolute",
						bottom: "10px",
						overflowY: "scroll",
						overflowX: "hidden"
					}}
				>
					<Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
						<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							<Switch onChange={toggleDrawFunction} checked={state.mapFeatures.draw}></Switch>
							<Typography>Mittaus- ja piirtotyökalut</Typography>
						</Box>
					</Stack>
					<Collapse in={openDrawTools}>
						<Stack direction="row" sx={{ width: "100%", justifyContent: "space-around" }}>
							<Tooltip title="Mittaa alue">
								<span>
									<IconButton
										color={selectedButton === "draw_polygon" ? "primary" : ""}
										disabled={!state.mapFeatures.draw}
										onClick={() => {
											drawFunc.current?.changeMode("draw_polygon")
											handleButtonClick("draw_polygon")
										}}
									>
										<FormatShapesIcon />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title="Mittaa matka">
								<span>
									<IconButton
										color={selectedButton === "draw_line_string" ? "primary" : ""}
										disabled={!state.mapFeatures.draw}
										onClick={() => {
											drawFunc.current?.changeMode("draw_line_string")
											handleButtonClick("draw_line_string")
										}}
									>
										<PolylineIcon />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title="Merkki">
								<span>
									<IconButton
										color={markerColor}
										disabled={!state.mapFeatures.draw}
										onClick={() => {
											drawFunc.current?.changeMode("draw_point")
											handleMarkerClick()
										}}
										onMouseDown={handleMouseDown}
									>
										<FmdBadIcon />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title="Piirrä">
								<span>
									<IconButton
										id="draw_paint_mode_button"
										color={selectedButton === "draw_paint_mode" ? "primary" : ""}
										disabled={!state.mapFeatures.draw}
										onClick={() => {
											drawFunc.current?.changeMode("draw_paint_mode")
											handleButtonClick("draw_paint_mode")
										}}
									>
										<BrushIcon />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title="Poista valittu muoto tai piste">
								<span>
									<IconButton
										color={"#505050"}
										disabled={!state.mapFeatures.draw}
										onClick={() => {
											drawFunc.current?.trash()
										}}
									>
										<DeleteIcon />
									</IconButton>
								</span>
							</Tooltip>
						</Stack>
					</Collapse>
					<Stack direction="row" sx={{ alignItems: "center" }}>
						<Switch onChange={togglePropertyClickFunction} checked={state.mapFeatures.showPropertyInfoByClick}></Switch>
						<Typography>Kiinteistötieto ikkuna</Typography>
					</Stack>

					{state.mapLayers
						? state.mapLayers.map((layer, index: number) => {
								return (
									<React.Fragment key={layer.name + index}>
										<LayerOptionsRow layer={layer} map={map} />
									</React.Fragment>
								)
							})
						: null}
				</Paper>
			</Box>
		</>
	)
}
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"

const LayerOptionsRow = ({ layer, map }: { layer: MapLayerType; map: MapInstance }) => {
	const { state, dispatch } = useMapState()

	const [openAccordion, setOpenAccordion] = useState(false)

	const changeLayerOpacity = (layer: MapLayerType, opacity: number) => {
		map.current.setPaintProperty(layer.name, layer.type + "-opacity", opacity)
	}

	return !layer.featured ? null : (
		<>
			<Stack
				direction="row"
				sx={{
					justifyContent: "space-between",
					alignItems: "center",
					width: "100%"
				}}
			>
				<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
					<Switch onChange={() => toggleLayerVisibility(map, layer, state, dispatch)} checked={layer.visibility === "visible"}></Switch>

					<Typography>{layer?.displayName.length > 35 ? layer.displayName.slice(0, 35) + "..." : layer.displayName}</Typography>
				</Box>
				<IconButton size="medium" onClick={() => setOpenAccordion(!openAccordion)}>
					<ArrowForwardIosIcon fontSize="small" sx={{ transform: openAccordion ? "rotate(90deg) translateY(3px) " : null }} />
				</IconButton>
			</Stack>
			<Collapse in={openAccordion}>
				<Box
					sx={{
						margin: "5px",
						padding: "5px"
					}}
				>
					<Typography id="layer_opacity_slider">Läpinäkyvyys</Typography>
					<Slider
						aria-label="layer_opacity_slider"
						disabled={!layer.allowOpacityChange}
						defaultValue={1}
						min={0.01}
						max={1}
						step={0.01}
						default={1}
						onChange={(e: { target: { value: number } }) => changeLayerOpacity(layer, e.target.value)}
					/>
				</Box>
			</Collapse>
		</>
	)
}

const toggleLayerVisibility = (map: MapInstance, layer: MapLayerType, state: mapProviderStateType, dispatch: MapProviderDispatch) => {
	if (!map.current) return
	if (layer.visibility === "none") {
		map.current.setLayoutProperty(layer.name, "visibility", "visible")
		const tempMapLayers: MapLayerType[] = []
		for (let i = 0, len = state.mapLayers.length; len > i; i++) {
			if (state.mapLayers[i].name === layer.name) {
				tempMapLayers.push({
					...state.mapLayers[i],
					visibility: "visible"
				})
			} else {
				tempMapLayers.push(state.mapLayers[i])
			}
		}
		dispatch({ type: "setMapLayers", payload: tempMapLayers })
	} else {
		map.current.setLayoutProperty(layer.name, "visibility", "none")
		const tempMapLayers: MapLayerType[] = []
		for (let i = 0, len = state.mapLayers.length; len > i; i++) {
			if (state.mapLayers[i].name === layer.name) {
				tempMapLayers.push({
					...state.mapLayers[i],
					visibility: "none"
				})
			} else {
				tempMapLayers.push(state.mapLayers[i])
			}
		}
		dispatch({ type: "setMapLayers", payload: tempMapLayers })
	}
}

export { MapToolAndLayersPopup }
