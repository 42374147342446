import React, { useEffect, useState } from "react"
import { useQuery, gql, useMutation } from "@apollo/client"
import {
	TableContainer,
	TableCell,
	TableBody,
	Typography,
	Tooltip,
	Box,
	Stack,
	Dialog,
	IconButton,
	DialogContent,
	Collapse,
	Grid,
	Tabs,
	Tab
} from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import TableRow from "@mui/material/TableRow"
import DialogCloseButton from "../../../reusables/DialogCloseButton"
import CustomDialogTitle from "../../../reusables/CustomDialogTitle"
import CreateNewContact from "../CreateNewContact"
import ContactEditDialog from "../ContactEditDialog"
import ContactSearch from "../ContactSearch"
import NotificationDialogToDraggable from "../NotificationDialogToDraggable"
import { getAllCountries } from "../../../../network/dataServices/generalDataService"
import { MutationFab } from "../../../reusables/MutationButtonChipFab"
import userProfileStorage from "../../../../utils/providers/userProfileProvider/userProfileProvider"

const GET_ROADCOOPERATIVE_INVOICE_CONTACTS = gql`
	query getRoadCooperativeInvoiceContants {
		roadCooperativeWithJWT {
			invoiceContactsByRoadCooperativeId {
				nodes {
					billingAddress
					businessId
					city
					contactType
					countryCode
					eInvoiceAddress
					eInvoiceOperator
					email
					id
					name
					nodeId
					postalCode
					roadCooperativeId
					phoneNumber
					additionalInformation
				}
			}
		}
	}
`

const DELETE_INVOICE_CONTACT = gql`
	mutation deleteInvoiceContact($id: Int!) {
		deleteInvoiceContactById(input: { id: $id }) {
			deletedInvoiceContactId
		}
	}
`

enum ContactEnumType {
	USAGE_FEE_PAYER = "USAGE_FEE_PAYER",
	CONTRACTOR = "CONTRACTOR",
	OTHER = "OTHER"
}

const OtherContacts = () => {
	const [openDialog, setOpenDialog] = useState(false)

	const [contactPerson, setContactPerson] = useState<Contact>({
		name: "",
		contactType: ContactEnumType.OTHER,
		memberType: "yksityishenkilö",
		email: "",
		billingAddress: "",
		city: "",
		postalCode: "",
		countryCode: "FI",
		phoneNumber: "",
		eInvoiceAddress: "",
		eInvoiceOperator: "",
		businessId: "",
		additionalInformation: ""
	})

	const [openEditDialog, setOpenEditDialog] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)

	const { data: data, loading: contactLoading, error: contactError, refetch: refetchContacts } = useQuery(GET_ROADCOOPERATIVE_INVOICE_CONTACTS)

	const handleEditContact = (row: Contact, index: number) => {
		setOpenEditDialog(true)
		setSelectedContact({ ...row })
	}

	const [deleteContact] = useMutation(DELETE_INVOICE_CONTACT)
	const handleDeleteContact = () => {
		if (selectedContact && selectedContact.id) {
			deleteContact({
				variables: {
					id: selectedContact.id
				},
				onCompleted: () => {
					refetchContacts()
				},
				onError: (error) => {
					console.log(error)
				}
			})
		}
		setOpenEditDialog(false)
	}

	const [filteredContactList, setFilteredContactList] = useState<Contact[]>([])
	const [selectedContact, setSelectedContact] = useState<Contact | undefined>(undefined)
	const [contacts, setContacts] = useState<Contact[]>([])

	const [selectedView, setSelectedView] = useState(1)

	const handleChange = (event: React.ChangeEvent<HTMLFormElement>, newValue: number) => {
		setSelectedView(newValue)
	}

	useEffect(() => {
		if (data) {
			setContacts(data.roadCooperativeWithJWT.invoiceContactsByRoadCooperativeId.nodes)
			setFilteredContactList(data.roadCooperativeWithJWT.invoiceContactsByRoadCooperativeId.nodes)
		}
	})

	useEffect(() => {
		setFilteredContactList(contacts)
	}, [contacts])

	return (
		<>
			<Grid sx={{ minWidth: "370px" }}>
				<Stack sx={{ marginLeft: "10px", height: "45px" }}>
					<Tabs
						value={selectedView}
						onChange={handleChange}
						sx={{
							height: "30px",
							"& .MuiTabs-scroller": {
								height: "30px"
							},
							"& .MuiTab-root": {
								fontSize: "0.7rem",
								// color: "#9de563",
								paddingTop: 1,
								paddingBottom: 1,
								minHeight: "25px"
							}
						}}
						variant="scrollable"
					>
						<Tab
							label="Urakoitsijat"
							value={1}
							sx={{ fontWeight: "700", letterSpacing: "0", fontSize: "0.875rem", paddingLeft: "0px", paddingRight: "4px" }}
						/>
						<Tab label="Käyttömaksun maksajat" value={2} sx={{ fontWeight: "700", letterSpacing: "0", fontSize: "0.875rem", paddingX: "4px" }} />
						<Tab
							label="Muut"
							value={3}
							sx={{ fontWeight: "700", letterSpacing: "0", fontSize: "0.875rem", paddingRight: "0px", paddingLeft: "4px" }}
						/>
					</Tabs>
				</Stack>
				{
					{
						1: (
							<>
								<ContactSearch contacts={contacts} setFilteredContactList={setFilteredContactList} />
								<TableContainer
									sx={{
										justifyContent: "flex-start",
										height: "calc(100% - 140px)",
										alignItems: "flex-start",
										alignContent: "flex-start",
										overflow: "auto"
									}}
								>
									<TableBody>
										<TableCell align="center" sx={{ width: "10%", paddingTop: 1, paddingBottom: 1 }}></TableCell>
										<TableCell align="start" sx={{ width: "39%", paddingLeft: 0, paddingTop: 1, paddingBottom: 1 }}>
											<Typography sx={{ fontWeight: "700", letterSpacing: "0", fontSize: "0.875rem" }}>
												Nimi
												{/* <TableSortLabel active={true} onClick={handleMemberSort} direction={memberDirection} /> */}
											</Typography>
										</TableCell>
										<TableCell align="start" sx={{ width: "40%", paddingLeft: 1, paddingTop: 1, paddingBottom: 1 }}>
											<Typography sx={{ fontWeight: "700", letterSpacing: "0", fontSize: "0.875rem" }}>
												Työnkuva
												{/* <TableSortLabel active={true} onClick={handleUnitSort} direction={unitDirection} /> */}
											</Typography>
										</TableCell>
										<TableCell sx={{ width: "1%", paddingTop: 1, paddingBottom: 1 }}></TableCell>
										<TableCell sx={{ width: "10%", paddingTop: 1, paddingBottom: 1 }}></TableCell>

										{filteredContactList.map((contact: Contact, index: number) => {
											{
												if (contact.contactType === ContactEnumType.CONTRACTOR) {
													return (
														<ContactTypeRow
															contact={contact}
															key={`other-${contact.id}`}
															handleEditContact={handleEditContact}
															index={index}
														></ContactTypeRow>
													)
												}
											}
										})}
										{/* //////// */}
									</TableBody>
								</TableContainer>
							</>
						),
						2: (
							<>
								<ContactSearch contacts={contacts} setFilteredContactList={setFilteredContactList} />
								<TableContainer
									sx={{
										justifyContent: "flex-start",
										height: "calc(100% - 140px)",
										alignItems: "flex-start",
										alignContent: "flex-start",
										overflow: "auto"
									}}
								>
									<TableBody>
										<TableCell align="center" sx={{ width: "10%", paddingTop: 1, paddingBottom: 1 }}></TableCell>
										<TableCell align="start" sx={{ width: "39%", paddingLeft: 0, paddingTop: 1, paddingBottom: 1 }}>
											<Typography sx={{ fontSize: "0.875rem" }}>
												Nimi
												{/* <TableSortLabel active={true} onClick={handleMemberSort} direction={memberDirection} /> */}
											</Typography>
										</TableCell>
										<TableCell align="start" sx={{ width: "40%", paddingLeft: 1, paddingTop: 1, paddingBottom: 1 }}>
											<Typography sx={{ fontSize: "0.875rem" }}>
												Työnkuva
												{/* <TableSortLabel active={true} onClick={handleUnitSort} direction={unitDirection} /> */}
											</Typography>
										</TableCell>
										<TableCell sx={{ width: "1%", paddingTop: 1, paddingBottom: 1 }}></TableCell>
										<TableCell sx={{ width: "10%", paddingTop: 1, paddingBottom: 1 }}></TableCell>
										{filteredContactList.map((contact, index) => {
											{
												if (contact.contactType === ContactEnumType.USAGE_FEE_PAYER) {
													return (
														<ContactTypeRow
															contact={contact}
															key={`other-${contact.id}`}
															handleEditContact={handleEditContact}
															index={index}
														></ContactTypeRow>
													)
												}
											}
										})}
									</TableBody>
								</TableContainer>
							</>
						),
						3: (
							<>
								<ContactSearch contacts={contacts} setFilteredContactList={setFilteredContactList} />
								<TableContainer
									sx={{
										justifyContent: "flex-start",
										height: "calc(100% - 140px)",
										alignItems: "flex-start",
										alignContent: "flex-start",
										overflow: "auto"
									}}
								>
									<TableBody>
										<TableCell align="center" sx={{ width: "10%", paddingTop: 1, paddingBottom: 1 }}></TableCell>
										<TableCell align="start" sx={{ width: "39%", paddingLeft: 0, paddingTop: 1, paddingBottom: 1 }}>
											<Typography sx={{ fontSize: "0.875rem" }}>
												Nimi
												{/* <TableSortLabel active={true} onClick={handleMemberSort} direction={memberDirection} /> */}
											</Typography>
										</TableCell>
										<TableCell align="start" sx={{ width: "40%", paddingLeft: 1, paddingTop: 1, paddingBottom: 1 }}>
											<Typography sx={{ fontSize: "0.875rem" }}>
												Työnkuva
												{/* <TableSortLabel active={true} onClick={handleUnitSort} direction={unitDirection} /> */}
											</Typography>
										</TableCell>
										<TableCell sx={{ width: "1%", paddingTop: 1, paddingBottom: 1 }}></TableCell>
										<TableCell sx={{ width: "10%", paddingTop: 1, paddingBottom: 1 }}></TableCell>
										{filteredContactList.map((contact: Contact, index: number) => {
											{
												if (contact.contactType === ContactEnumType.OTHER) {
													return (
														<ContactTypeRow
															contact={contact}
															key={`other-${contact.id}`}
															handleEditContact={handleEditContact}
															index={index}
														></ContactTypeRow>
													)
												}
											}
										})}
									</TableBody>
								</TableContainer>
							</>
						)
					}[selectedView ?? 1]
				}

				<TableBody container fullwidth sx={{ height: "100%" }}></TableBody>
				<Box sx={{ display: "flex", justifyContent: "center" }}>
					<Tooltip title="Luo uusi kontakti">
						<MutationFab onClick={() => setOpenDialog(true)} color="primary" sx={{ width: "37px", height: "20px" }}>
							<AddIcon sx={{ fontSize: "22px" }} />
						</MutationFab>
					</Tooltip>
				</Box>
			</Grid>

			<Dialog
				sx={{
					zIndex: "1302"
				}}
				open={openDialog}
				maxWidth="md"
				fullWidth
			>
				<CreateNewContact
					setOpenDialog={setOpenDialog}
					createNewContact={openDialog}
					contacts={contacts}
					setContacts={setContacts}
					refetchContacts={refetchContacts}
					contactPerson={contactPerson}
					setContactPerson={setContactPerson}
				/>
			</Dialog>

			<Dialog sx={{ zIndex: "1302" }} maxWidth="md" fullWidth open={openEditDialog}>
				<CustomDialogTitle>Yhteystiedon muokkaaminen</CustomDialogTitle>
				<NotificationDialogToDraggable
					dialogTitle={"Poista kontakti"}
					executeButtonText="Poista"
					executefunction={handleDeleteContact}
					openState={openDelete}
					setOpenState={setOpenDelete}
				>
					Haluatko varmasti poistaa kontaktin? Kaikki kontaktiin liitetyt tiedot menetetään.{" "}
				</NotificationDialogToDraggable>
				<IconButton
					sx={{
						position: "absolute",
						top: "5px",
						left: "5px"
					}}
					onClick={() => setOpenDelete(true)}
				>
					<DeleteIcon />
				</IconButton>
				<DialogCloseButton closeFunction={() => setOpenEditDialog(false)} />

				<DialogContent>
					<ContactEditDialog
						refetchContacts={refetchContacts}
						setOpenEditDialog={setOpenEditDialog}
						setContactPerson={setContactPerson}
						selectedContact={selectedContact}
					/>
				</DialogContent>
			</Dialog>
		</>
	)
}

interface ContactTypeRowProps {
	contact: Contact
	handleEditContact: (contact: Contact, index: number) => void
	index: number
}

const ContactTypeRow = ({ contact, handleEditContact, index }: ContactTypeRowProps) => {
	const [open, setOpen] = useState(false)
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission

	const [allCountries, setAllCountries] = useState<Country[]>([])

	useEffect(() => {
		getAllCountries().then((data) => {
			if (data) setAllCountries(data)
		})
	}, [])

	const getCountryNameByCode = (countryCode: any) => {
		const country = allCountries.find((c) => c.countryCode === countryCode)
		return country ? country.country : ""
	}

	return (
		<>
			<React.Fragment key={"contacts-" + contact.id}>
				<TableRow>
					<TableCell sx={{ width: "10%", height: "50px", padding: "0px", borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0" }}>
						<IconButton size="small" onClick={() => setOpen(!open)}>
							{open ? (
								<ArrowForwardIosIcon fontSize="small" sx={{ transform: "rotate(90deg) translateY(3px) " }} />
							) : (
								<ArrowForwardIosIcon fontSize="small" />
							)}
						</IconButton>
					</TableCell>
					<TableCell sx={{ width: "40%", height: "50px", padding: "0px", borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0" }} align="start">
						{contact.name}
					</TableCell>
					<TableCell
						sx={{ width: "40%", height: "50px", padding: "0px", paddingLeft: 1, borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0" }}
						align="start"
					>
						{contact.additionalInformation}
					</TableCell>
					<TableCell sx={{ height: "50px", padding: "0px", borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0" }}></TableCell>
					<TableCell sx={{ width: "10%", height: "50px", padding: "0px", borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0" }}>
						{permission === "edit" && (
							<IconButton size="small" onClick={() => handleEditContact(contact, index)}>
								<EditIcon sx={{ width: "20px", color: "rgba(0, 0, 0, 0.54)" }} />
							</IconButton>
						)}
					</TableCell>
				</TableRow>

				<TableRow sx={{ borderBottom: "5px solid #e0e0e0" }}>
					<TableCell colSpan={5} sx={{ padding: 0, margin: 0, borderBottom: 0, borderTop: 0, borderTopColor: "#e0e0e0" }}>
						<Collapse in={open} unmountOnExit>
							{!contact ? null : (
								<>
									<Grid container direction="row" justifyContent="center" alignItems="flex-start" padding="10px">
										<Grid item xs sx={{ minWidth: "130px", maxWidth: "130px" }}>
											<Box component="div" sx={{}}>
												{contact.billingAddress && (
													<>
														<div style={{ marginBottom: "5px" }}>• Postiosoite: {contact.billingAddress}</div>
													</>
												)}
												{contact.postalCode && (
													<>
														<div style={{ marginTop: "5px", marginBottom: "5px" }}>• Postinumero: {contact.postalCode}</div>
													</>
												)}
												{contact.city && (
													<>
														<div style={{ marginTop: "5px", marginBottom: "5px" }}>• Postitoimipaikka: {contact.city}</div>
													</>
												)}
												{contact.email && (
													<>
														<div style={{ marginTop: "5px", marginBottom: "5px" }}>• Sähköposti: {contact.email}</div>
													</>
												)}
												{contact.phoneNumber && (
													<>
														<div style={{ marginTop: "5px", marginBottom: "5px" }}>• Puhelinnumero: {contact.phoneNumber}</div>
													</>
												)}
												{contact.countryCode && (
													<>
														<div style={{ marginTop: "5px", marginBottom: "5px" }}>
															• Maa: {getCountryNameByCode(contact.countryCode)} ({contact.countryCode})
														</div>
													</>
												)}
												{contact.businessId && (
													<>
														<div style={{ marginTop: "5px", marginBottom: "5px" }}>• Y-tunnus: {contact.businessId}</div>
													</>
												)}
												{contact.eInvoiceOperator && (
													<>
														<div style={{ marginTop: "5px", marginBottom: "5px" }}>
															• Operaatiotunnus: {contact.eInvoiceOperator}
														</div>
													</>
												)}
												{contact.eInvoiceAddress && (
													<>
														<div style={{ marginTop: "5px" }}>• Verkkolaskutusosoite: {contact.eInvoiceAddress}</div>
													</>
												)}
											</Box>
										</Grid>
									</Grid>
								</>
							)}
						</Collapse>
					</TableCell>
				</TableRow>
			</React.Fragment>
		</>
	)
}

export default OtherContacts
