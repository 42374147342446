import { TextField } from "@mui/material"
import { Stack } from "@mui/material"
import { Button } from "@mui/material"
import { Container } from "@mui/material"
import React, { SetStateAction, useState } from "react"
import CustomDialogActions from "../reusables/CustomDialogActions"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import DialogCloseButton from "../reusables/DialogCloseButton"
import "./CreateNoteDialog.css"
type NoteListItem = {
	roadCooperativeName: string
}

interface CreateNoteDialogProps {
	setCreateNewNote: React.Dispatch<SetStateAction<boolean>>
	addNote: (newNote: Note) => void
	allNoteRoadCooperativeNames: string[]
}

const emptyNoteDialog: Note = {
	title: "",
	content: "",
	type: "note",
	roadCooperativeName: ""
}

const CreateNoteDialog = ({ setCreateNewNote, addNote, allNoteRoadCooperativeNames }: CreateNoteDialogProps) => {
	const [alert, setAlert] = useState("")
	const [note, setNote] = useState(emptyNoteDialog)

	const createNewNote = () => {
		//if (checkTaskParameters(task, setAlert)) {
		//	const taskobj: any = {}
		//}
		const newNoteObj: any = {
			title: newTitle,
			content: newContent,
			type: "note",
			roadCooperativeName: newRoadCooperativeName,
			open: false
		}
		addNote(newNoteObj)
	}

	const [newTitle, setNewTitle] = useState<string>("")
	const [newRoadCooperativeName, setNewRoadCooperativeName] = useState<string>("")
	const [newContent, setNewContent] = useState<string>("")
	const [notes, setNotes] = useState<Note[]>([])

	const RCNstyle = {
		//todo stailaus loppuun
		border: "solid 1px",
		borderRadius: "5px",
		borderColor: "grey",
		height: "23px",
		paddingTop: "15.5px",
		paddingBottom: "15.5px",
		paddingRight: "0.01px",
		paddingLeft: "0.01px",
		width: "100%"
	}

	return (
		<>
			<DialogCloseButton closeFunction={() => setCreateNewNote(false)} />
			<CustomDialogTitle>Luo uusi muistio</CustomDialogTitle>
			<Container
				maxWidth="md"
				sx={{
					overflow: "auto",
					justifyContent: "center",
					height: "100%",
					maxWidth: "100%",
					textAlign: "center",
					display: "flex"
				}}
				elevation={10}
			>
				<Stack direction="column" justifyContent="center" alignItems="center" spacing={2} padding="5px" width="100%">
					<TextField
						label="Tiekunta"
						sx={{ width: "90%" }}
						fullWidth
						type="text"
						InputProps={{
							endAdornment: <></>,
							inputProps: { list: "data" }
						}}
						onChange={(e: { target: { value: string } }) => {
							setNewRoadCooperativeName(e.target.value)
						}}
					/>

					<datalist id="data">
						<option value={""}></option>
						{allNoteRoadCooperativeNames.map((item, key) => {
							return <option key={key} value={item}></option>
						})}
					</datalist>
					<TextField
						style={{ width: "90%" }}
						required
						label="Otsikko"
						onChange={(e: { target: { value: string } }) => {
							setNewTitle(e.target.value)
						}}
					></TextField>

					<TextField
						overflow-y="scroll"
						rows={9}
						cols="20"
						style={{ width: "90%" }}
						label="Sisältö"
						multiline
						onChange={(e: { target: { value: string } }) => setNewContent(e.target.value)}
					></TextField>
				</Stack>
			</Container>
			<CustomDialogActions>
				<Button variant="outlined" color="primary" onClick={() => setCreateNewNote(false)}>
					Sulje
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						createNewNote()
					}}
				>
					{/* TODO: ota id pois kun bäkki on valmis */}
					Tallenna muistio
				</Button>
			</CustomDialogActions>
		</>
	)
}

export default CreateNoteDialog
