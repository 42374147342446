import React, { ReactElement, SetStateAction } from "react"
import { gql, useMutation } from "@apollo/client"
import userProfileStorage from "../../../utils/providers/userProfileProvider/userProfileProvider"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import { Container, Stack, Grid, FormControl, InputLabel, Select, MenuItem, ToggleButtonGroup, ToggleButton, Button } from "@mui/material"
import ContactOrganisationUserInfo from "./ContactOrganisationUserInfo"
import ContactPersonUserInfo from "./ContactPersonUserInfo"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import PersonIcon from "@mui/icons-material/Person"
import BusinessIcon from "@mui/icons-material/Business"

const CREATE_ROADCOOPERATIVE_INVOICE_CONTACT = gql`
	mutation CreateInvoiceContact(
		$billingAddress: String = ""
		$businessId: String = ""
		$city: String = ""
		$contactType: InvoiceContactType = OTHER
		$countryCode: String = ""
		$eInvoiceAddress: String = ""
		$eInvoiceOperator: String = ""
		$email: String = ""
		$name: String = ""
		$postalCode: String = ""
		$additionalInformation: String = ""
		$roadCooperativeId: UUID!
		$phoneNumber: String = ""
	) {
		createInvoiceContact(
			input: {
				invoiceContact: {
					billingAddress: $billingAddress
					businessId: $businessId
					city: $city
					contactType: $contactType
					countryCode: $countryCode
					eInvoiceAddress: $eInvoiceAddress
					eInvoiceOperator: $eInvoiceOperator
					email: $email
					name: $name
					postalCode: $postalCode
					roadCooperativeId: $roadCooperativeId
					additionalInformation: $additionalInformation
					phoneNumber: $phoneNumber
				}
			}
		) {
			invoiceContact {
				id
				contactType
			}
		}
	}
`

enum ContactEnumType {
	USAGE_FEE_PAYER = "USAGE_FEE_PAYER",
	CONTRACTOR = "CONTRACTOR",
	OTHER = "OTHER"
}
interface CreateNewRCContact {
	createNewContact: boolean
	setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
	contacts: Contact[]
	setContacts: React.Dispatch<React.SetStateAction<Contact[]>>
	refetchContacts: () => void
	contactPerson: Contact
	setContactPerson: React.Dispatch<React.SetStateAction<Contact>>
}

const CreateNewContact = ({ createNewContact, setOpenDialog, contacts, setContacts, refetchContacts, contactPerson, setContactPerson }: CreateNewRCContact) => {
	const [createInvoiceContact] = useMutation(CREATE_ROADCOOPERATIVE_INVOICE_CONTACT)

	const handleChangeMemberType = (v: string | null) => {
		if (!v) {
			return
		} else {
			setContactPerson({ ...contactPerson, memberType: v })
		}
	}
	const emptyInformation: Contact = {
		billingAddress: "",
		businessId: "",
		city: "",
		contactType: ContactEnumType.OTHER,
		countryCode: "FI",
		eInvoiceAddress: "",
		eInvoiceOperator: "",
		email: "",
		name: "",
		postalCode: "",
		additionalInformation: "",
		phoneNumber: ""
	}

	const handleAfterSave = () => {
		setContactPerson(emptyInformation)
	}

	const handleCreateNewContact = async () => {
		const rcId = userProfileStorage({ type: "getActiveRoadCooperative" }).id
		try {
			const { data } = await createInvoiceContact({
				variables: {
					billingAddress: contactPerson.billingAddress || "",
					businessId: contactPerson.businessId || "",
					city: contactPerson.city || "",
					contactType: contactPerson.contactType || ContactEnumType.OTHER,
					countryCode: contactPerson.countryCode || "",
					eInvoiceAddress: contactPerson.eInvoiceAddress || "",
					eInvoiceOperator: contactPerson.eInvoiceOperator || "",
					email: contactPerson.email || "",
					name: contactPerson.name || "",
					postalCode: contactPerson.postalCode || "",
					roadCooperativeId: rcId,
					additionalInformation: contactPerson.additionalInformation || "",
					phoneNumber: contactPerson.phoneNumber || ""
				}
			})
			const newContact = data?.createInvoiceContact?.invoiceContact
			if (newContact) {
				switch (newContact.contactType) {
					case ContactEnumType.USAGE_FEE_PAYER:
						setContacts([...contacts, newContact])
						break
					case ContactEnumType.CONTRACTOR:
						setContacts([...contacts, newContact])
						break
					default:
						setContacts([...contacts, newContact])
						break
				}
			}
			setOpenDialog(false)
			refetchContacts()
			handleAfterSave()
		} catch (error) {
			console.error("Error creating new contact:", error)
		}
	}

	const getContactEnumType = (value: string): ContactEnumType => {
		switch (value) {
			case "Urakoitsija":
				return ContactEnumType.CONTRACTOR
			case "Käyttömaksun maksaja":
				return ContactEnumType.USAGE_FEE_PAYER
			case "Muu":
				return ContactEnumType.OTHER
			default:
				return ContactEnumType.OTHER
		}
	}

	return (
		<>
			<DialogCloseButton closeFunction={() => setOpenDialog(false)} />
			<CustomDialogTitle>Luo uusi Yhteystieto</CustomDialogTitle>
			<Container
				maxWidth="md"
				sx={{
					overflow: "auto",
					justifyContent: "center",
					marginTop: "0.5vh",
					height: "100%",
					padding: "10px",
					maxWidth: "md",
					textAlign: "center",
					display: "flex"
				}}
				elevation={10}
			>
				<Stack direction="column" width="60%">
					<Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
						<FormControl sx={{ marginBottom: "15px", width: "60%", zIndex: "1303" }}>
							<InputLabel sx={{}}>Rooli*</InputLabel>
							<Select
								inputProps={{ sx: { zIndex: "1303" } }}
								SelectDisplayProps={{ sx: { zIndex: "1303" } }}
								MenuProps={{ sx: { zIndex: "1303" } }}
								sx={{ overflow: "hidden", zIndex: "1303" }}
								label="Roolini tiekunnassa"
								onChange={(e: { target: { value: string } }) =>
									setContactPerson({
										...contactPerson,
										contactType: getContactEnumType(e.target.value)
									})
								}
							>
								<MenuItem value={"Urakoitsija"}>Urakoitsija</MenuItem>
								<MenuItem value={"Käyttömaksun maksaja"}>Käyttömaksun maksaja</MenuItem>
								<MenuItem value={"Muu"}>Muu</MenuItem>
							</Select>
						</FormControl>

						<ToggleButtonGroup
							value={contactPerson.memberType}
							color="primary"
							onChange={(e: ReactElement, v: string | null) => handleChangeMemberType(v)}
							exclusive
							size="large"
							aria-label="recipient type"
						>
							<ToggleButton value="yritys" fullWidth sx={{ alignItems: "space-between", maxWidth: 300 }}>
								Yritys
								<BusinessIcon sx={{ marginLeft: 2, fontSize: 32, width: 40 }} />
							</ToggleButton>
							<ToggleButton value="yksityishenkilö" fullWidth sx={{ alignItems: "space-between", maxWidth: 300 }}>
								<PersonIcon sx={{ marginRight: 2, fontSize: 32, width: 40 }} />
								Henkilö
							</ToggleButton>
						</ToggleButtonGroup>
						<Grid>
							{contactPerson.memberType === "yritys" ? (
								<ContactOrganisationUserInfo contactPerson={contactPerson} setContactPerson={setContactPerson} />
							) : (
								<ContactPersonUserInfo contactPerson={contactPerson} setContactPerson={setContactPerson} />
							)}
						</Grid>
					</Stack>
				</Stack>
			</Container>
			<CustomDialogActions>
				<Button variant="outlined" color="cancel" onClick={() => setOpenDialog(false)}>
					Sulje
				</Button>
				<Button variant="contained" onClick={handleCreateNewContact}>
					Tallenna
				</Button>
			</CustomDialogActions>
		</>
	)
}

export default CreateNewContact
