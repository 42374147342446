import React, { Dispatch } from "react"
import { Grid, List, ListItem, Paper, Box } from "@mui/material"
import Chat from "./Chat"

import { ChatProps } from "../Map/ChatPopUp"

// Määrittele tyyppi komponentin propseille
interface AllChatsProps {
	chatData: ChatProps[]
	// comments: CommentProps[]
	refetch: () => void
	profileInfo: any
	setChats: Dispatch<React.SetStateAction<ChatProps[]>>
}

const Chats: React.FC<AllChatsProps> = ({ chatData, refetch, profileInfo, setChats }) => {
	const height = "calc(100vh - 145px)"

	return (
		<Grid container spacing={0} sx={{ padding: "0px", height: "100%" }}>
			<Grid item xs={12} sx={{ height: "100%", padding: "0px" }}>
				<Box sx={{ height: "100%", boxSizing: "border-box" }}>
					<List>
						{chatData
							.slice()
							.reverse()
							.map((chat: any, index: number) => (
								<ListItem key={index}>
									<Paper style={{ padding: "10px", paddingTop: "0px", width: "100%", maxWidth: "95%" }}>
										<Chat chat={chat} profileInfo={profileInfo} refetch={refetch} />
									</Paper>
								</ListItem>
							))}
					</List>
				</Box>
			</Grid>
		</Grid>
	)
}

export default Chats
