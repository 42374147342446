import { CircularProgress } from "@mui/material"
import { Typography } from "@mui/material"
import { Box } from "@mui/material"
import { Stack } from "@mui/material"
import { Paper } from "@mui/material"
import React, { useEffect, useState } from "react"
import axiosClient from "../../network/axiosClient/axiosClient"
import config from "../../utils/config"
import axios from "axios"

const baseUrl = config.BACKEND_ROOT_URL

const JoinMeetingPage = () => {
	const [loading, setLoading] = useState(true)
	const [meetingTimeToStartDays, setMeetingTimeToStartDays] = useState("")
	const [meetingTimeToStart, setMeetingTimeToStart] = useState("")

	const [errorMessage, setErrorMessage] = useState("")

	const meetingId: string | undefined = new URLSearchParams(window.location.search).get("meetingId")?.toString()
	const email: string | undefined = new URLSearchParams(window.location.search).get("email")?.toString()

	const getMeeting = async () => {
		setLoading(true)
		try {
			const meetingRes = await axios({
				url: baseUrl + "/meetings/joinViaLink",
				params: {
					email: email,
					meetingId: meetingId
				},
				method: "GET"
			})
			console.log(meetingRes)
			if (meetingRes.data.meetingDateUTC) {
				console.log(meetingRes.data.meetingDateUTC)
				calculateTimeDifference(meetingRes.data.meetingDateUTC)
			} else {
				console.log(meetingRes.data.meetingUrl)

				window.location.replace("https://" + meetingRes.data.meetingUrl)
			}
		} catch (error) {
			console.log(error)
			setErrorMessage("Kokousta ei löytynyt")
		} finally {
			setLoading(false)
		}
	}

	const calculateTimeDifference = (time: any) => {
		const currentDate = Date.now()
		console.log(currentDate, " ----- ", time, new Date(time))
		const date1 = new Date(currentDate)
		const date2 = new Date(time)
		if (date2.getTime() - date1.getTime() > 900000) {
			const dateDiff = Math.floor(date2.getTime() - date1.getTime())
			const days = Math.floor(dateDiff / 86400000)
			const hourDiff = Math.floor((dateDiff - days * 86400000) / 3600000)
			const minDiff = Math.floor((dateDiff - days * 86400000 - hourDiff * 3600000) / 60000)

			setMeetingTimeToStartDays(`${days}`)
			setMeetingTimeToStart(hourDiff + (hourDiff == 1 ? " t " : " t ") + minDiff + " min")

			setTimeout(() => {
				calculateTimeDifference(time)
			}, 1000)
		} else {
			setMeetingTimeToStart(" Kokous on alkanut ")
			getMeeting()
		}
	}

	useEffect(() => {
		getMeeting()
	}, [])

	return (
		<Box
			sx={{
				Width: "100vw",
				height: "100vh",
				textAlign: "center",
				justifyContent: "center",
				display: "flex",
				alignItems: "center"
			}}
		>
			<Paper
				elevation={5}
				sx={{
					Width: "200px",
					height: "200px",
					textAlign: "center",
					justifyContent: "center",
					display: "flex",
					alignItems: "center",
					padding: "20px"
				}}
			>
				<Stack
					sx={{
						textAlign: "center",
						justifyContent: "center",
						alignItems: "center"
					}}
					spacing={5}
				>
					{errorMessage ? (
						<>
							<Typography>{errorMessage}</Typography>
						</>
					) : loading && !errorMessage ? (
						<>
							<CircularProgress />
							<Typography>Liitytään kokoukseen...</Typography>
						</>
					) : (
						<>
							<Typography>Kokouksen alkuun:</Typography>
							<Typography>
								{meetingTimeToStartDays ? (
									<>
										{meetingTimeToStartDays} pv {meetingTimeToStart}
									</>
								) : null}
							</Typography>
						</>
					)}
				</Stack>
			</Paper>
		</Box>
	)
}

export default JoinMeetingPage
