import { TextField, MenuItem, CircularProgress } from "@mui/material"
import React, { useEffect, useState } from "react"
import authToken from "../../utils/authToken"
import { getUserRoadCooperatives, selectRoadCooperative } from "../../network/dataServices/userDataService"

const getDecodedTokenRoadCooperativeId = () => {
	const token = authToken.getDecodedToken()
	return token?.roadCooperativeId
}

const RoadCooperativeSelectField = () => {
	const activeRoadcooperative: any = getDecodedTokenRoadCooperativeId()
	const [loading, setLoading] = useState(true)
	const [userAllRC, setUserAllRC] = useState([])
	const [roadCooperative, setRoadCooperative] = useState(activeRoadcooperative)

	useEffect(() => {
		getUserRoadCooperatives().then((data) => {
			if (data) {
				setUserAllRC(data.data)

				const storedRoadCooperative = localStorage.getItem("selectedRoadCooperative")
				if (storedRoadCooperative) {
					const parsedRoadCooperative = JSON.parse(storedRoadCooperative)

					const matchingRoadCooperative = data.data.find((rc: RoadCooperative) => rc.id === parsedRoadCooperative.id)
					if (matchingRoadCooperative) {
						setRoadCooperative(matchingRoadCooperative.id)
						handleSelectRoadCooperative(matchingRoadCooperative.name, matchingRoadCooperative.id)

						localStorage.removeItem("selectedRoadCooperative")
					}
				}
			}
			setLoading(false)
		})
	}, [])

	const handleSelectRoadCooperative = async (name?: string, id?: string) => {
		if (!id || !name) return
		selectRoadCooperative(name, id)
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await getUserRoadCooperatives()

				if (data) {
					setUserAllRC(data.data)

					const storedRoadCooperative = localStorage.getItem("selectedRoadCooperative")
					if (storedRoadCooperative) {
						const parsedRoadCooperative = JSON.parse(storedRoadCooperative)

						const matchingRoadCooperative = data.data.find((rc: RoadCooperative) => rc.id === parsedRoadCooperative.id)

						if (matchingRoadCooperative) {
							setRoadCooperative(matchingRoadCooperative.id)
							handleSelectRoadCooperative(matchingRoadCooperative.name, matchingRoadCooperative.id)

							localStorage.removeItem("selectedRoadCooperative")
						}
					}
				}
			} catch (error) {
				console.error("Error fetching road cooperatives:", error)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [])

	return (
		<>
			{loading ? (
				<>
					<CircularProgress />
				</>
			) : (
				<TextField
					select
					label="Tiekunta"
					sx={{
						minWidth: "250px"
					}}
					value={roadCooperative ?? ""}
					onChange={(e: { target: { value: string } }) => setRoadCooperative(e.target.value)}
				>
					{userAllRC.map((rc: RoadCooperative, i: number) => {
						return (
							<MenuItem value={rc.id} key={i} onClick={() => handleSelectRoadCooperative(rc.name, rc.id)}>
								{rc.name}
							</MenuItem>
						)
					})}
				</TextField>
			)}
		</>
	)
}

export default RoadCooperativeSelectField
