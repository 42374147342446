import { ApolloLink, from, HttpLink } from "@apollo/client"
import authToken from "../../../utils/authToken"

const authLink = new ApolloLink((operation, forward) => {
	authToken.getToken()
	operation.setContext(({ headers }: Record<string, any>) => ({
		headers: {
			authorization: `Bearer ${authToken.getToken()}`,
			...headers
		}
	}))
	return forward(operation)
})

export default authLink
