// When i start this project, only god and i knew how this works and now only god knows

const rad2deg = (rad: number) => {
	const pi = Math.PI
	return rad * (180 / pi)
}

const deg2rad = (deg: number) => {
	const pi = Math.PI
	return (deg * pi) / 180
}

export const convertToStandard = (E: number, N: number) => {
	// Vakiot
	const f = 1 / 298.257222101 // Ellipsoidin litistyssuhde
	const a = 6378137 // Isoakselin puolikas
	const lambda_nolla = 0.471238898 // Keskimeridiaani (rad), 27 astetta
	const k_nolla = 0.9996 // Mittakaavakerroin
	const E_nolla = 500000 // Itäkoordinaatti

	// Kaavat
	const n = f / (2 - f)
	const A1 = (a / (1 + n)) * (1 + Math.pow(n, 2) / 4 + Math.pow(n, 4) / 64)
	const e_toiseen = 2 * f - Math.pow(f, 2)
	const h1 = (1 / 2) * n - (2 / 3) * Math.pow(n, 2) + (37 / 96) * Math.pow(n, 3) - (1 / 360) * Math.pow(n, 4)
	const h2 = (1 / 48) * Math.pow(n, 2) + (1 / 15) * Math.pow(n, 3) - (437 / 1440) * Math.pow(n, 4)
	const h3 = (17 / 480) * Math.pow(n, 3) - (37 / 840) * Math.pow(n, 4)
	const h4 = (4397 / 161280) * Math.pow(n, 4)
	const zeeta = N / (A1 * k_nolla)
	const eeta = (E - E_nolla) / (A1 * k_nolla)
	const zeeta1_pilkku = h1 * Math.sin(2 * zeeta) * Math.cosh(2 * eeta)
	const zeeta2_pilkku = h2 * Math.sin(4 * zeeta) * Math.cosh(4 * eeta)
	const zeeta3_pilkku = h3 * Math.sin(6 * zeeta) * Math.cosh(6 * eeta)
	const zeeta4_pilkku = h4 * Math.sin(8 * zeeta) * Math.cosh(8 * eeta)
	const eeta1_pilkku = h1 * Math.cos(2 * zeeta) * Math.sinh(2 * eeta)
	const eeta2_pilkku = h2 * Math.cos(4 * zeeta) * Math.sinh(4 * eeta)
	const eeta3_pilkku = h3 * Math.cos(6 * zeeta) * Math.sinh(6 * eeta)
	const eeta4_pilkku = h4 * Math.cos(8 * zeeta) * Math.sinh(8 * eeta)
	const zeeta_pilkku = zeeta - (zeeta1_pilkku + zeeta2_pilkku + zeeta3_pilkku + zeeta4_pilkku)
	const eeta_pilkku = eeta - (eeta1_pilkku + eeta2_pilkku + eeta3_pilkku + eeta4_pilkku)
	const beeta = Math.asin((1 / Math.cosh(eeta_pilkku)) * Math.sin(zeeta_pilkku))
	const l = Math.asin(Math.tanh(eeta_pilkku) / Math.cos(beeta))
	const Q = Math.asinh(Math.tan(beeta))
	let Q_pilkku = Q + Math.sqrt(e_toiseen) * Math.atanh(Math.sqrt(e_toiseen) * Math.tanh(Q))

	for (let kierros = 1; kierros < 5; kierros++) {
		Q_pilkku = Q + Math.sqrt(e_toiseen) * Math.atanh(Math.sqrt(e_toiseen) * Math.tanh(Q_pilkku))
	}

	// Tulos radiaaneina
	let fii = Math.atan(Math.sinh(Q_pilkku))
	let lambda = lambda_nolla + l

	// Tulos asteina
	fii = rad2deg(fii)
	lambda = rad2deg(lambda)

	const output: [number, number] = [fii, lambda]
	return output
}

export const convertToETRSTM35FIN = (lev_aste: number, pit_aste: number) => {
	// Vakiot
	const f = 1 / 298.257222101 // Ellipsoidin litistyssuhde
	const a = 6378137 // Isoakselin puolikas
	const lambda_nolla = 0.471238898 // Keskimeridiaani (rad), 27 astetta
	const k_nolla = 0.9996 // Mittakaavakerroin
	const E_nolla = 500000 // Itäkoordinaatti

	// Kaavat

	// Muunnetaan astemuotoisesta radiaaneiksi
	const fii = deg2rad(lev_aste)
	const lambda = deg2rad(pit_aste)

	const n = f / (2 - f)
	const A1 = (a / (1 + n)) * (1 + Math.pow(n, 2) / 4 + Math.pow(n, 4) / 64)
	const e_toiseen = 2 * f - Math.pow(f, 2)
	// const e_pilkku_toiseen = e_toiseen / (1 - e_toiseen); // Kukaan ei tiedä miksi tämä on täällä
	const h1_pilkku = (1 / 2) * n - (2 / 3) * Math.pow(n, 2) + (5 / 16) * Math.pow(n, 3) + (41 / 180) * Math.pow(n, 4)
	const h2_pilkku = (13 / 48) * Math.pow(n, 2) - (3 / 5) * Math.pow(n, 3) + (557 / 1440) * Math.pow(n, 4)
	const h3_pilkku = (61 / 240) * Math.pow(n, 3) - (103 / 140) * Math.pow(n, 4)
	const h4_pilkku = (49561 / 161280) * Math.pow(n, 4)
	const Q_pilkku = Math.asinh(Math.tan(fii))
	const Q_2pilkku = Math.atanh(Math.sqrt(e_toiseen) * Math.sin(fii))
	const Q = Q_pilkku - Math.sqrt(e_toiseen) * Q_2pilkku
	const l = lambda - lambda_nolla
	const beeta = Math.atan(Math.sinh(Q))
	const eeta_pilkku = Math.atanh(Math.cos(beeta) * Math.sin(l))
	const zeeta_pilkku = Math.asin(Math.sin(beeta) / (1 / Math.cosh(eeta_pilkku)))
	const zeeta1 = h1_pilkku * Math.sin(2 * zeeta_pilkku) * Math.cosh(2 * eeta_pilkku)
	const zeeta2 = h2_pilkku * Math.sin(4 * zeeta_pilkku) * Math.cosh(4 * eeta_pilkku)
	const zeeta3 = h3_pilkku * Math.sin(6 * zeeta_pilkku) * Math.cosh(6 * eeta_pilkku)
	const zeeta4 = h4_pilkku * Math.sin(8 * zeeta_pilkku) * Math.cosh(8 * eeta_pilkku)
	const eeta1 = h1_pilkku * Math.cos(2 * zeeta_pilkku) * Math.sinh(2 * eeta_pilkku)
	const eeta2 = h2_pilkku * Math.cos(4 * zeeta_pilkku) * Math.sinh(4 * eeta_pilkku)
	const eeta3 = h3_pilkku * Math.cos(6 * zeeta_pilkku) * Math.sinh(6 * eeta_pilkku)
	const eeta4 = h4_pilkku * Math.cos(8 * zeeta_pilkku) * Math.sinh(8 * eeta_pilkku)
	const zeeta = zeeta_pilkku + zeeta1 + zeeta2 + zeeta3 + zeeta4
	const eeta = eeta_pilkku + eeta1 + eeta2 + eeta3 + eeta4

	// Tulos tasokoordinaatteina
	const N = A1 * zeeta * k_nolla
	const E = A1 * eeta * k_nolla + E_nolla

	const array: [number, number] = [N, E]
	return array
}
