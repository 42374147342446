import React, { useEffect, useState } from "react"
import { Table, TableRow, TableCell, IconButton, Collapse, Typography, Dialog, DialogContent, Box } from "@mui/material"
import { ApolloQueryResult, gql, OperationVariables, useQuery } from "@apollo/client"
import EditIcon from "@mui/icons-material/Edit"
import { TableBody } from "@mui/material"
import { TableContainer } from "@mui/material"
import { TableHead } from "@mui/material"
import { TableSortLabel } from "@mui/material"
import { CircularProgress } from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import DialogCloseButton from "../../../../reusables/DialogCloseButton"
import CustomDialogTitle from "../../../../reusables/CustomDialogTitle"
import CustomDialogActions from "../../../../reusables/CustomDialogActions"
import EditEstablishmentDialog from "../../EditEstablishmentDialog"
import EstablishmentsTableRow from "./EstablishmentTableRow"
import { establishmentSortingbyMmlPropertyId } from "../../../../../utils/propertiesSorting"
import { TextField } from "@mui/material"
import { Stack } from "@mui/material"
import { Button } from "@mui/material"

import AddIcon from "@mui/icons-material/Add"
import { Tooltip } from "@mui/material"
import { pulseAnimation } from "../../../../../utils/animations"
import userProfileStorage from "../../../../../utils/providers/userProfileProvider/userProfileProvider"
import { MutationButton } from "../../../../reusables/MutationButtonChipFab"
import LinkButton from "../../../../reusables/LinkButton"

const GET_ROADCOOPERATIVE_ALL_ESTABLISHMENTS = gql`
	query GetRoadCooperativeAllEstablishments {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					id
					establishmentsByCostCenterId {
						nodes {
							id
							additionalInformation
							amount
							costCenterId
							discretionalyCorrectionMultiplier
							distanceCorrectionMultiplier
							lateralDirection
							lateralDirectionCorrectionMultiplier
							operatingDistance
							propertyId
							roadUnits
							weightCorrectionMultiplier
							propertyByPropertyId {
								mmlPropertyId
								plotId
								propertyName
								propertyTag
								id
							}
							roadUsageUnitByRoadUsageUnitId {
								id
								roadUsageUnit
								roadUsageUnitType
								weight
							}
							roadUsageUnitId
							roadCooperativeMemberByRoadCooperativeMemberId {
								name
								userEmail
								id
							}
							costCenterByCostCenterId {
								idColor
							}
						}
					}
				}
			}
		}
	}
`

const GET_UPDATE_UNITS_VIEW_TYPE = gql`
	query GetUnitsViewType {
		roadCooperativeWithJWT {
			id
			roadCooperativeSettingsByRoadCooperativeId {
				nodes {
					id
					unitsPresentationType
				}
			}
		}
	}
`

type EstablishmentTableProps = {
	activeCostCenterColor: string | undefined
	activeCostCenterId: number | null
	wholeRoadCooperative: boolean
	refetchRCGeoJson: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
	handlePopoverClick: any
	dataChange: boolean
}

type TableFilterEnumType = "asc" | "desc"

const EstablishmentTable = ({ activeCostCenterColor, activeCostCenterId, wholeRoadCooperative, handlePopoverClick, dataChange }: EstablishmentTableProps) => {
	const [noEstData, setNoEstData] = useState(false)
	const [allEstablishments, setAllEstablishments] = useState<CostCenter[]>([])
	const [unitDirection, setUnitDirection] = useState<TableFilterEnumType>("asc")
	const [propertiesDirection, setPropertiesDirection] = useState<TableFilterEnumType>("asc")
	const [transportGenreDirection, setTransportGenreDirection] = useState<TableFilterEnumType>("asc")
	const [memberDirection, setMemberDirection] = useState<TableFilterEnumType>("asc")
	const [unitsViewType, setUnitsViewType] = useState<UnitsViewType>("professional")
	const [selectedEst, setSelectedEst] = useState<Establishment[]>([])
	const [filteredUnits, setFilteredUnits] = useState<Establishment[]>([])
	const [searchQuery, setSearchQuery] = useState<string>("")
	const rcStorage = userProfileStorage({ type: "getActiveRoadCooperative" })
	const [permission, setPermission] = useState("")

	const { data: unitsViewTypeData } = useQuery(GET_UPDATE_UNITS_VIEW_TYPE, {
		onCompleted(data) {
			setUnitsViewType(data.roadCooperativeWithJWT.roadCooperativeSettingsByRoadCooperativeId.nodes[0].unitsPresentationType)
		}
	})

	const { data, loading, refetch } = useQuery(GET_ROADCOOPERATIVE_ALL_ESTABLISHMENTS)

	useEffect(() => {
		setPermission(rcStorage.permission)
	}, [])
	useEffect(() => {
		refetch()
	}, [dataChange])

	useEffect(() => {
		if (!data || loading) return
		if (data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes.length != 0) {
			setAllEstablishments(data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes)
		} else {
			setNoEstData(true)
		}
	}, [data])

	useEffect(() => {
		if (allEstablishments.length === 0) {
			//refetch()
			return
		}
		if (wholeRoadCooperative) {
			const tempEstablishments = []
			for (let i = 0, len = allEstablishments.length; i < len; i++) {
				tempEstablishments.push(...allEstablishments[i].establishmentsByCostCenterId.nodes)
			}
			establishmentSortingbyMmlPropertyId(tempEstablishments, setSelectedEst)
			if (!tempEstablishments.length) setNoEstData(true)
			else setNoEstData(false)
		} else {
			const ccEstablishments = []
			for (let i = 0, len = allEstablishments.length; i < len; i++) {
				if (allEstablishments[i].id === activeCostCenterId) ccEstablishments.push(...allEstablishments[i].establishmentsByCostCenterId.nodes)
			}
			establishmentSortingbyMmlPropertyId(ccEstablishments, setSelectedEst)
			if (!ccEstablishments.length) setNoEstData(true)
			else setNoEstData(false)
		}
	}, [allEstablishments, activeCostCenterId])

	const handleTransportGenreSort = () => {
		const sortedList = [...filteredUnits]

		if (transportGenreDirection === "asc") {
			setTransportGenreDirection("desc")
			sortedList.sort((a, b) => {
				return a.roadUsageUnitByRoadUsageUnitId.roadUsageUnit.toLowerCase().localeCompare(b.roadUsageUnitByRoadUsageUnitId.roadUsageUnit.toLowerCase())
			})
		} else {
			setTransportGenreDirection("asc")
			sortedList.sort((a, b) => {
				return b.roadUsageUnitByRoadUsageUnitId.roadUsageUnit.toLowerCase().localeCompare(a.roadUsageUnitByRoadUsageUnitId.roadUsageUnit.toLowerCase())
			})
		}
		setFilteredUnits(sortedList)
	}

	const handleUnitSort = () => {
		const sortedList = [...filteredUnits]
		if (unitDirection === "asc") {
			setUnitDirection("desc")
			sortedList.sort((a, b) => {
				return a.roadUnits - b.roadUnits
			})
		} else {
			setUnitDirection("asc")
			sortedList.sort((a, b) => {
				return b.roadUnits - a.roadUnits
			})
		}
		setFilteredUnits(sortedList)
	}

	const handleMemberSort = () => {
		const sortedList = [...filteredUnits]
		if (memberDirection === "asc") {
			setMemberDirection("desc")
			sortedList.sort((a: any, b: any) => {
				return a.roadCooperativeMemberByRoadCooperativeMemberId.name
					.toLowerCase()
					.localeCompare(b.roadCooperativeMemberByRoadCooperativeMemberId.name.toLowerCase())
			})
		} else {
			setMemberDirection("asc")
			sortedList.sort((a: any, b: any) => {
				return b.roadCooperativeMemberByRoadCooperativeMemberId.name
					.toLowerCase()
					.localeCompare(a.roadCooperativeMemberByRoadCooperativeMemberId.name.toLowerCase())
			})
		}
		setFilteredUnits(sortedList)
	}

	const handlePropertiesSort = () => {
		const sortedList = [...filteredUnits]
		if (propertiesDirection === "asc") {
			setPropertiesDirection("desc")
			sortedList.sort((a, b): any => {
				const aArr = a.propertyByPropertyId?.mmlPropertyId!.split("-") ?? ""
				const bArr = b.propertyByPropertyId?.mmlPropertyId!.split("-") ?? ""
				const length = aArr > bArr ? aArr.length : bArr.length
				for (let i = 0; i < length; i++) {
					if (Number(aArr[i]) > Number(bArr[i]) || bArr[i] === undefined) {
						return 1
					}
					if (Number(aArr[i]) < Number(bArr[i]) || aArr[i] === undefined) {
						return -1
					}
				}
				return 0
			})
		} else {
			setPropertiesDirection("asc")
			sortedList.sort((a, b) => {
				const aArr = a.propertyByPropertyId?.mmlPropertyId!.split("-") ?? ""
				const bArr = b.propertyByPropertyId?.mmlPropertyId!.split("-") ?? ""
				const length = aArr > bArr ? aArr.length : bArr.length
				for (let i = 0; i < length; i++) {
					if (Number(bArr[i]) > Number(aArr[i]) || aArr[i] === undefined) {
						return 1
					}
					if (Number(bArr[i]) < Number(aArr[i]) || bArr[i] === undefined) {
						return -1
					}
				}
				return 0
			})
		}
		setFilteredUnits(sortedList)
	}

	useEffect(() => {
		if (!searchQuery) {
			setFilteredUnits(selectedEst)
		} else {
			const filteredEsts = selectedEst.filter((est: Establishment) => {
				const currentEstRoadUsageUnit = est.roadUsageUnitByRoadUsageUnitId?.roadUsageUnit?.toUpperCase() ?? ""
				const currentEstMmlPropertyId = est.propertyByPropertyId?.mmlPropertyId?.toUpperCase() ?? ""
				const estMember = (est.roadCooperativeMemberByRoadCooperativeMemberId?.name ?? "").toUpperCase()
				const upperCaseValue = searchQuery.toUpperCase()

				return (
					currentEstRoadUsageUnit.includes(upperCaseValue) || currentEstMmlPropertyId.includes(upperCaseValue) || estMember.includes(upperCaseValue)
				)
			})
			setFilteredUnits(filteredEsts)
		}
	}, [searchQuery, selectedEst])

	const handleFilterChange = (value: string) => {
		setSearchQuery(value)
	}

	return (
		<>
			<TableContainer sx={{ maxHeight: "100%" }}>
				<Table stickyHeader size="medium" padding="none" sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}>
					<TableHead>
						<TableRow align="center">
							{permission === "edit" ? (
								<LinkButton title="Pyydä tarjous tieyksiköinnistä" url="https://www.tievahti.fi/palvelut/tieyksikointi/"></LinkButton>
							) : null}
						</TableRow>
						<TableRow>
							<TableCell colSpan={6} align="center" sx={{ borderBottom: "1px solid white", paddingBottom: "10px" }}>
								<TextField
									size="small"
									fullWidth
									label="Hae"
									color="primary"
									sx={{ maxWidth: "300px", width: screen.width < 600 ? 250 : 460 }}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e.target.value)}
								/>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</TableContainer>
			<TableContainer sx={{ maxHeight: "calc(100% - 70px)" }}>
				<Table stickyHeader size="medium" padding="none">
					<TableHead>
						<TableRow>
							<TableCell align="center"></TableCell>
							<TableCell align="center"></TableCell>
							{unitsViewType == "professional" ? (
								<TableCell align="center" sx={{ fontWeight: "700" }}>
									Liikennelaji
									<TableSortLabel active={true} onClick={handleTransportGenreSort} direction={transportGenreDirection} />
								</TableCell>
							) : (
								<TableCell align="center" sx={{ fontWeight: "700" }}>
									Osakas
									<TableSortLabel active={true} onClick={handleMemberSort} direction={memberDirection} />
								</TableCell>
							)}
							<TableCell align="center" sx={{ fontWeight: "700" }}>
								Kiinteistötunnus
								<TableSortLabel active={true} onClick={handlePropertiesSort} direction={propertiesDirection} />
							</TableCell>
							<TableCell align="center" sx={{ fontWeight: "700" }}>
								Lisätieto
							</TableCell>
							<TableCell align="center" sx={{ fontWeight: "700" }}>
								Tieyksiköt
								<TableSortLabel active={true} onClick={handleUnitSort} direction={unitDirection} />
							</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{noEstData ? (
							<TableRow rowSpan={3}>
								<TableCell colSpan={7}>
									<Box
										sx={{
											height: "100%",
											width: "100%",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
											paddingTop: "20px",
											paddingBottom: "20px"
										}}
									>
										<Typography sx={{ fontSize: "1rem", textAlign: "center", fontWeight: "400", padding: "10px" }}>
											Ei lisättyjä yksiköitä.
										</Typography>
										<MutationButton variant="contained" onClick={handlePopoverClick}>
											Lisää yksiköitä
											<AddIcon sx={{ paddingLeft: "10px" }} />
										</MutationButton>
									</Box>
								</TableCell>
							</TableRow>
						) : selectedEst.length === 0 ? (
							//
							<TableRow rowSpan={3}>
								<TableCell colSpan={7}>
									<Box
										sx={{
											height: "100%",
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										<CircularProgress />
									</Box>
								</TableCell>
							</TableRow>
						) : (
							filteredUnits.map((unit: Establishment, index: number) => {
								return (
									<EstablishmentsTableRow
										key={"filteredEstablishments-" + unit.id}
										unit={unit}
										refetch={refetch}
										unitsViewType={unitsViewType}
									/>
								)
							})
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

export default EstablishmentTable
