import axiosClient from "../axiosClient/axiosClient"

const createRoadCooperative = async (requestBody: any) => {
	try {
		const res = await axiosClient({
			method: "post",
			url: "/api/roadCooperative/create",
			data: requestBody
		})
		return res
	} catch (error: any) {
		if (error.response) {
			return error.response
		}
		console.error(error)
		return null
	}
}

export { createRoadCooperative }
