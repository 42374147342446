import waitUntilMapLoaded from "./waitUntilMapLoaded"

type returnType = Array<any>

const selectPropertiesFromMap = (map: any, propertyList: any, setPropertyList: any, ref?: any, setOff?: boolean) => {
	if (!map) return

	const loadingInitialProertiesData = () => {
		if (!propertyList.current) return
		if (map.current._loaded) {
			propertyList.current.map((p: any) => {
				map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: p.plotId }, { hover: true })
			})
		}
	}

	waitUntilMapLoaded(map, loadingInitialProertiesData)

	const selectPropertiesOnClick = (e: any) => {
		if (propertyList.current === undefined) return
		let tempPropertyList

		if (e.features.length > 0) {
			const index = propertyList.current.findIndex((p: any) => p.plotId === e.features[0].id)
			if (index !== -1) {
				tempPropertyList = propertyList.current.filter((p: any) => p.plotId !== e.features[0].id)
				map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: e.features[0].id }, { hover: false })
			} else {
				tempPropertyList = propertyList.current.concat({
					mmlPropertyId: e.features[0].properties.kiinteistotunnuksenEsitysmuoto,
					plotId: e.features[0].id
				})
				map.current.setFeatureState({ source: "kipa", sourceLayer: "PalstanSijaintitiedot", id: e.features[0].id }, { hover: true })
			}
		}

		setPropertyList(tempPropertyList)
	}

	if (setOff) {
		setPropertyList([])

		map.current.off("click", "PalstanSijaintitiedot", ref.current)
	} else {
		ref.current = selectPropertiesOnClick
		map.current.on("click", "PalstanSijaintitiedot", ref.current)
	}
}

export default selectPropertiesFromMap
