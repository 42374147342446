import { makeVar } from "@apollo/client"
import { LngLatLike } from "maplibre-gl"

// This file contains all Apollo Clients makeVars that are inMemoryCache.
// You can configure new makeVares here, as shown below.

//Make sure you import and add makeVars to inMemoryCache !!

// === How to use examples === //

// This is excample how you can query/call these makeVars after you have added it inMemorycache

// const testVar = gql`
//   query gettestVar {
//     testVar @client
//   }
// `

// This is example how to change data in makeVar variable

//import { testVar } from './apolloClientMakeVars'
// testVar(Actual data you want to store e.g: {data: ["test 2", 2, false]})

// Typescript interface that spesific makeVar will take as a type parameter.
// you need to define a separate interface for each makeVar.
interface testVarType {
	data: any
}

export const testVar = makeVar<testVarType>(
	//initial data that you can remove any time and anywere in this application.
	{
		data: ["testi", 1, true]
	}
)

export interface CostCenterVarType {
	roadLinks?: {
		coordinates: [LngLatLike]
		linkId?: string
		linkLength?: number
	}[]
}

export const costCenterLinksVar = makeVar<CostCenterVarType>({
	roadLinks: []
})

export interface costCenterPropertyVarType {
	properties: Property[]
}

export const costCenterPropertyVar = makeVar<costCenterPropertyVarType>({
	properties: []
})

export interface costCenterNameVarType {
	name: string
}

export const costCenterNameVar = makeVar<costCenterNameVarType>({
	name: "no name"
})

export interface userProfileVarType {
	userProfile: {
		name: string
		email: string
	}
	activeRoadCooperative: {
		roadCooperative: string | null
		role: string | null
	}
}

export const userProfileVar = makeVar<userProfileVarType>({
	userProfile: {
		name: "Vieras",
		email: "ei määritetty"
	},
	activeRoadCooperative: {
		roadCooperative: "Ei aktiivista tiekuntaa",
		role: ""
	}
})
