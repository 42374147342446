import React, { useMemo } from "react"
import { ApolloClient, HttpLink, from, ApolloProvider, ApolloLink } from "@apollo/client"

import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import { toast } from "react-toastify"
import authToken from "../../../utils/authToken"

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (networkError) {
		console.error(`[Network error]: ${networkError}`)
		toast.update("apolloQueryToast", {
			isLoading: false,
			render: "Tietojen käsittelyssä tapahtui virhe",
			autoClose: 5000,
			type: toast.TYPE.ERROR,
			closeOnClick: true
		})
	}
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) => {
			console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
		})
	}
})

const checkAuthToken = setContext(async () => {
	await authToken.checkToken()
})

export default errorLink
