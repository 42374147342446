import { Divider } from "@mui/material"
import { Stack } from "@mui/material"
import { DialogActions } from "@mui/material"
import React from "react"

const CustomDialogActions = ({ children }: { children?: React.ReactNode }) => {
	return (
		<>
			<Divider></Divider>
			<DialogActions sx={{ paddingX: "27px", paddingY: "25px", alignContent: "center", height: "45px" }}>
				<Stack
					direction="row"
					sx={{
						justifyContent: "space-between",
						width: "100%"
					}}
				>
					{children}
				</Stack>
			</DialogActions>
		</>
	)
}

export default CustomDialogActions
