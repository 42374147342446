import React from "react"
import { IconButton } from "@mui/material"
import { ReactComponent as InfoIcon } from "../../resources/icons/info.svg"

import { Tooltip } from "@mui/material"

type LinkButtonProps = {
	url: string
	title: React.ReactNode
}
const LinkButton = ({ url, title }: LinkButtonProps) => {
	function redirectToOfferPage() {
		window.open(url, "_blank")
	}
	return (
		<Tooltip title={title}>
			<IconButton
				variant="contained"
				onClick={redirectToOfferPage}
				sx={{
					fontSize: 15,
					color: "#34aadc"
				}}
			>
				<InfoIcon />
			</IconButton>
		</Tooltip>
	)
}
export default LinkButton
