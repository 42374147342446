import { openDB } from "idb"

type MeetingDocument = string[]

type MeetingAgenda = string[]

type MeetingParticipants = any

type MeetingInfo = {
	roadCooperative: string
	roadCooerativeIdentifier: string
	locationOfMeet: string
	dateOfMeet: string
	timeOfMeet: string
}

type MeetingAttachments = string[]

type Action =
	| { type: "setMeetingDocumentData"; payload: MeetingDocument | null }
	| { type: "setMeetingParticipants"; payload: MeetingParticipants | null }
	| { type: "setMeetingAgenda"; payload: MeetingAgenda }
	| { type: "setMeetingOfficers"; payload: MeetingOfficer[] }
	| { type: "setMeetingInfo"; payload: MeetingInfo }
	| { type: "setMeetingAttachments"; payload: MeetingAttachments }
	| { type: "setAgendasWithVote"; payload: any }
	| { type: "getAgendasWithVote" }
	| { type: "getMeetingAttachments" }
	| { type: "getMeetingInfo" }
	| { type: "getMeetingOfficers" }
	| { type: "getMeetingAgenda" }
	| { type: "getMeetingDocumentData" }
	| { type: "getMeetingParticipants" }
	| { type: "endMeeting" }
	| { type: "setMeetingIsStarted"; payload: boolean }
	| { type: "getMeetingStarted" }

const DB_NAME = localStorage.getItem("currentMeetingDbName")
const DB_VERSION = 1

const initDB = async () => {
	const dbName = DB_NAME
	if (!dbName) {
		throw new Error("No meeting database name found in local storage")
	}
	return openDB(dbName, DB_VERSION, {
		upgrade(db) {
			if (!db.objectStoreNames.contains("meetings")) {
				db.createObjectStore("meetings", { keyPath: "id" })
			}
		}
	})
}
const setItem = async (key: string, value: any) => {
	const db = await initDB()
	await db.put("meetings", { id: key, data: value })
}

const getItem = async (key: string) => {
	const db = await initDB()
	const item = await db.get("meetings", key)
	return item ? item.data : null
}

const deleteItem = async (key: string) => {
	const db = await initDB()
	await db.delete("meetings", key)
}

const clearDB = async () => {
	const db = await initDB()
	await db.clear("meetings")
}

const parseData = (data: string | null) => {
	if (data) {
		return JSON.parse(data)
	}
	return data
}

const endMeeting = async () => {
	await clearDB()
}

const currentMeetingStorage = async (action: Action) => {
	switch (action.type) {
		case "getAgendasWithVote":
			return parseData(await getItem("currentAgendasWithVote"))
		case "setAgendasWithVote":
			await setItem("currentAgendasWithVote", JSON.stringify(action.payload))
			return
		case "setMeetingAttachments":
			await setItem("currentMeetingAttachments", JSON.stringify(action.payload))
			return
		case "getMeetingAttachments":
			return parseData(await getItem("currentMeetingAttachments"))
		case "setMeetingInfo":
			await setItem("currentMeetingInfo", JSON.stringify(action.payload))
			return
		case "getMeetingInfo":
			return parseData(await getItem("currentMeetingInfo"))
		case "setMeetingAgenda":
			await setItem("currentMeetingAgenda", JSON.stringify(action.payload))
			return
		case "getMeetingAgenda":
			return parseData(await getItem("currentMeetingAgenda"))
		case "setMeetingOfficers":
			await setItem("currentMeetingOfficers", JSON.stringify(action.payload))
			return
		case "getMeetingOfficers":
			return parseData(await getItem("currentMeetingOfficers"))
		case "setMeetingDocumentData":
			await setItem("currentMeetingDocumentData", JSON.stringify(action.payload))
			return
		case "getMeetingDocumentData":
			return parseData(await getItem("currentMeetingDocumentData"))
		case "setMeetingParticipants":
			await setItem("currentMeetingParticipants", JSON.stringify(action.payload))
			return
		case "getMeetingParticipants":
			return parseData(await getItem("currentMeetingParticipants"))
		case "endMeeting":
			console.log(" ------ END MEETING ------- ")
			return endMeeting()
		case "setMeetingIsStarted":
			return await setItem("currentMeetingIsStarted", JSON.stringify(true))
		case "getMeetingStarted":
			return parseData(await getItem("currentMeetingIsStarted"))
	}
}

export default currentMeetingStorage
