import React, { useState } from "react"
import { Checkbox, Typography } from "@mui/material"
import checkRegisterEventValues from "./checkRegisterEventValues"
import { Grid } from "@mui/material"
import { Link } from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { FormControl } from "@mui/material"
import { FormGroup } from "@mui/material"
import { FormControlLabel } from "@mui/material"
import { IconButton } from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { TextField } from "@mui/material"
import PasswordField from "./PasswordField"

interface FullFormProps {
	handleLogin: (e: React.ChangeEvent<HTMLFormElement>) => Promise<void>
	handleRegister: (userObj: any) => void
	setOpenForgetPasswordDialog: React.Dispatch<React.SetStateAction<boolean>>
	setUserData: React.Dispatch<React.SetStateAction<{ email: string; password: string }>>
	userData: { email: string; password: string }
}

const FullForm = (props: FullFormProps) => {
	const [agreedTermsOk, setAgreedtermsOk] = useState(false)
	const [userObj, setUserObj] = useState({
		fullName: "",
		email: "",
		password: "",
		passwordAgain: ""
	})
	const [passwordShown, setPasswordShown] = useState(false)

	const togglePasswordVisibility = () => {
		setPasswordShown(!passwordShown)
	}

	const handleCheckBox = () => {
		setAgreedtermsOk(!agreedTermsOk)
	}

	const handleRegisterSubmit = () => {
		if (checkRegisterEventValues(userObj, agreedTermsOk)) {
			props.handleRegister(userObj)
		}
	}

	return (
		<div className="login-container">
			<div className="wrapper fadeInDown">
				<div className="login-title"></div>
				<div className="login-flex-row">
					<div id="formContent">
						<div className="fadeIn first"></div>
						<div className="loginFormTitle" style={{ paddingTop: "15px" }}>
							Kirjaudu
						</div>
						<form onSubmit={props.handleLogin}>
							<input type="text" id="login" className="loginFormText fadeIn second" name="email" placeholder="Sähköposti" />
							{/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
								<input
									type={passwordShown ? "text" : "password"}
									id="loginPassword"
									className="passwordField fadeIn third"
									name="password"
									placeholder="Salasana"
								/>
								<IconButton className={"fadeIn third"} color="primary" onClick={togglePasswordVisibility} type="button">
									{passwordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
								</IconButton>
							</div> */}
							<PasswordField id="password" placeholder="Salasana" className="passwordField fadeIn third" />
							<button style={{ outline: "none" }} type="submit" className="loginFormButton fadeIn fourth">
								Kirjaudu
							</button>
						</form>
						<div id="formFooter">
							<div className="forgotPasswordText">
								<a className="underlineHover" onClick={() => props.setOpenForgetPasswordDialog(true)}>
									Unohditko salasanasi?
								</a>
							</div>
						</div>
					</div>
					<div id="formContent2">
						<div className="fadeIn first"></div>
						<div className="loginFormTitle" style={{ color: "white", paddingTop: "15px" }}>
							Rekisteröidy
						</div>
						<div>
							<input
								onChange={(e: { target: { value: string } }) => setUserObj({ ...userObj, fullName: e.target.value })}
								type="text"
								id="fullName"
								className="loginFormText2 fadeIn second"
								name="fullName"
								placeholder="Etu- ja Sukunimi"
							/>
							<input
								onChange={(e: { target: { value: string } }) => setUserObj({ ...userObj, email: e.target.value })}
								type="text"
								id="email"
								className="loginFormText2 fadeIn second"
								name="email"
								placeholder="Sähköpostiosoite"
							/>
							<PasswordField
								setState={setUserObj}
								state={userObj}
								field="password"
								id="reg-password"
								placeholder="Salasana"
								className="loginFormText2 fadeIn third"
							/>
							<PasswordField
								setState={setUserObj}
								state={userObj}
								field="passwordAgain"
								id="reg-password-again"
								placeholder="Salasana uudelleen"
								className="loginFormText2 fadeIn third"
							/>

							<button style={{ outline: "none" }} onClick={handleRegisterSubmit} className="loginFormButton fadeIn fourth">
								Rekisteröidy
							</button>
							<br />
							<FormControl>
								<FormControlLabel
									control={
										<Checkbox id="accept-terms-checkbox" onChange={handleCheckBox} color="primary" size="medium" sx={{ color: "white" }} />
									}
									label={
										<Typography color="primary.contrastText" variant="body2">
											Hyväksyn{" "}
											<Link href="https://tietosuoja.tievahti.fi/" target="_blank" rel="noreferrer">
												käyttöehdot ja tietosuojaselosteen
												<OpenInNewIcon fontSize="small" sx={{ fontSize: "14px" }} />
											</Link>{" "}
											mukaisen tietojen käytön.
										</Typography>
									}
									sx={{ width: "90%", margin: "10px", marginTop: 0, alignSelf: "center" }}
								/>
							</FormControl>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FullForm
