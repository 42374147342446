import axiosVaylaClient from "../axiosClient/axiosVaylaClient"
import React from "react"
import { toast } from "react-toastify"
import { checkVaylaResponseDataType } from "../../components/Map/mapUtils/checkVaylaResponseDataType"
import { convertToETRSTM35FIN } from "../../components/Map/mapUtils/coordinateConverter"

const selectRoadByClick = async (mouseLat: number, mouseLng: number) => {
	const coordinateList = convertToETRSTM35FIN(mouseLat, mouseLng)

	let response = null

	try {
		const roadPointCoordinates: any = await axiosVaylaClient.get("/muunna", {
			params: { x: coordinateList[1], y: coordinateList[0], palautusarvot: "1,2,3,4,5,6" }
		})

		if (roadPointCoordinates.data.features[0].properties.virheet) {
			throw "No roads found at given points"
		}

		const roadLineCoordinates = await axiosVaylaClient.get("/muunna", {
			params: {
				kuntakoodi: roadPointCoordinates.data.features[0].properties.kuntakoodi,
				katunimi: roadPointCoordinates.data.features[0].properties.katunimi,
				palautusarvot: "1,2,3,4,5,6",
				valihaku: true
			}
		})

		if (roadLineCoordinates.data.features[0].properties.tie) {
			const roadLineCoordinates = await axiosVaylaClient.get("/muunna", {
				params: {
					tie: roadPointCoordinates.data.features[0].properties.tie,
					katunimi: roadPointCoordinates.data.features[0].properties.katunimi,
					palautusarvot: "1,2,3,4,5,6",
					valihaku: true
				}
			})
			response = roadLineCoordinates.data
		}

		if (!roadLineCoordinates.data.features[0].properties.virheet) {
			response = roadLineCoordinates.data
		} else {
			const roadLinkIdLine = await axiosVaylaClient.get("/muunna", {
				params: {
					link_id: roadPointCoordinates.data.features[0].properties.link_id,
					palautusarvot: "1,2,3,4,5,6",
					valihaku: true
				}
			})
			response = roadLinkIdLine.data
		}

		const line = checkVaylaResponseDataType(response)
		response.features[0].geometry.coordinates = line
		response.features[0].geometry.type = "LineString"

		const tienNimi = response.features[0].properties.katunimi != undefined ? response.features[0].properties.katunimi : "Tiellä ei ole nimeä"
		const notificationObj = (
			<div>
				<h3>Tien tiedot</h3> <li>Kunta: {response.features[0].properties.kuntanimi}</li> <li>Tien nimi: {tienNimi}</li>
				{response.features[0].properties.viivan_pituus ? <li>Tien pituus: {response.features[0].properties.viivan_pituus.toFixed(0)} m</li> : null}{" "}
			</div>
		)

		toast.info(notificationObj, { role: "global", toastId: "vaylaToast" })

		return response
	} catch (err) {
		if (err == "No roads found at given points") {
			console.info(err)
			toast.info("Et voi valita kyseistä tietä")
			return "virhe"
		}
		return "virhe"
	}
}

const selectRoadByKatunimiAndKuntakoodi = async (kuntakoodi: number | undefined, katunimi: string | undefined) => {
	try {
		const resultKatuPoint = await axiosVaylaClient.get("/muunna", {
			params: {
				kuntakoodi: kuntakoodi,
				katunimi: katunimi,
				palautusarvot: "1,2,3,4,5,6",
				valihaku: false
			}
		})

		if (!resultKatuPoint.data.features[0].properties.virheet) {
			return resultKatuPoint.data
		} else {
			const resultTie = await axiosVaylaClient.get("/muunna", {
				params: {
					kuntakoodi: kuntakoodi,
					katunimi: katunimi,
					palautusarvot: "1,2,3,4,5,6",
					valihaku: true
				}
			})

			return resultTie.data
		}
	} catch (err) {
		return 403
	}
}

const getRoadlinkByLatLng = async (mouseLat: number, mouseLng: number) => {
	const coordinateList = await convertToETRSTM35FIN(mouseLat, mouseLng)

	try {
		const roadPointCoordinates: any = await axiosVaylaClient.get("/muunna", {
			params: {
				x: coordinateList[1],
				y: coordinateList[0],
				palautusarvot: "1,2,3,4,5,6"
			}
		})

		if (roadPointCoordinates.data.features[0].properties.virheet) {
			throw 404
		} else {
			const roadLinkIdLine = await axiosVaylaClient.get("/muunna", {
				params: {
					link_id: roadPointCoordinates.data.features[0].properties.link_id,
					palautusarvot: "1,2,3,4,5,6",
					valihaku: true
				}
			})

			const response: any = roadLinkIdLine.data

			const line = checkVaylaResponseDataType(response)
			response.features[0].geometry.coordinates = line
			response.features[0].geometry.type = "LineString"

			return response
		}
	} catch (err) {
		if (err == 404) {
			// console.info(err);
			toast.info("Et voi valita kyseistä tietä", { toastId: "InvalidRoadParamsToast" })
			return "virhe"
		}

		return "virhe"
	}
}

export default {
	selectRoadByClick,
	selectRoadByKatunimiAndKuntakoodi,
	getRoadlinkByLatLng
}
