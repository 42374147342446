import axios from "axios"
import jwtDecode from "jwt-decode"
import { logoutUser } from "../network/dataServices/userDataService"
import config from "./config"

const getToken = () => {
	const authToken = localStorage.getItem("authToken")
	return authToken
}

const getRefreshToken = () => {
	const refreshToken = localStorage.getItem("authTokenRefresh")
	return refreshToken
}

const setRefreshToken = (token: string) => {
	localStorage.setItem("authTokenRefresh", token)
}

const deleteRefreshToken = () => {
	localStorage.removeItem("authTokenRefresh")
}

const setToken = (token: string) => {
	localStorage.setItem("authToken", token)
}

const deleteToken = () => {
	localStorage.removeItem("authToken")
}

const refetchAuthToken = async () => {
	try {
		const refreshToken = getRefreshToken()
		const requestBody = {
			refreshToken: refreshToken
		}
		const newToken = await axios({
			url: config.BACKEND_ROOT_URL + "/refreshToken",
			method: "POST",
			headers: { authorization: `Bearer ${getToken()}` },
			data: requestBody
		})

		setToken(newToken.data.token)
	} catch (error) {
		logoutUser()
	}
}

const checkToken = async () => {
	const currentToken = getDecodedToken()
	if (currentToken) {
		// console.log(currentToken.exp * 1000, " - ", Date.now(), " = ", currentToken.exp * 1000 - Date.now())
		if (currentToken.exp * 1000 - Date.now() <= 60000) {
			await refetchAuthToken()
		} else {
			return
		}
	} else {
		logoutUser()
	}
}

const getDecodedToken = (): DecodedToken | undefined => {
	try {
		const token: string | null = getToken()
		if (token == null) return undefined
		const decodedToken: DecodedToken = jwtDecode(token)
		return decodedToken
	} catch (error) {
		console.error(error)
		return undefined
	}
}

export default {
	getToken,
	setToken,
	deleteToken,
	getDecodedToken,
	checkToken,
	getRefreshToken,
	setRefreshToken,
	deleteRefreshToken
}
