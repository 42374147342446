import vaylaDataService from "../../../network/dataServices/vaylaDataService"
import { FeatureCollection, Position } from "geojson"

import { MapMouseEvent } from "maplibre-gl"
import waitUntilMapLoaded from "./waitUntilMapLoaded"

const getRoadLinkByClick = (map: MapInstance, state: any, setState: any, setOff?: any, ref?: any, color: any = "#505050") => {
	// console.log(color)

	// if(initial) {
	//   // console.log(initial);
	//   if(state.length < 1) {
	//     setState(initial)
	//   }
	// }

	if (!map.current) return
	// LISÄÄ GEOJSON TYYPIT !!

	let latLngG: Array<number> | null = null
	let geoJsonData: FeatureCollection | null = null
	let geoJsonCoordinates: Position[] | null = null
	let linkIdToAdd: any = null

	// Intial loading of roadLink data  in memoryCache
	map.current.on("load", () => {
		if (map.current._checkLoaded) return
		geoJsonData = {
			type: "FeatureCollection",
			features: [
				{
					properties: {},
					type: "Feature",
					geometry: {
						type: "MultiLineString",
						coordinates: state?.map((l: any) => {
							return l.coordinates
						})
					}
				}
			]
		}

		if (map.current.getLayer("clickRoadLink")) {
			map.current.removeLayer("clickRoadLink")
		}

		if (map.current.getSource("clickRoadLink")) {
			map.current.removeSource("clickRoadLink")
		}

		map.current.addSource("clickRoadLink", {
			type: "geojson",
			data: geoJsonData
		})

		map.current.addLayer({
			id: "clickRoadLink",
			type: "line",
			source: "clickRoadLink",
			layout: {
				"line-join": "round",
				"line-cap": "round"
			},
			paint: {
				"line-color": color,
				"line-width": 5
			}
		})
	})

	const onRoadLinkClickFunc = (e: MapMouseEvent) => {
		// // console.log(state)
		func(e)
	}

	const func = (e: MapMouseEvent) => {
		latLngG = [e.lngLat.lat, e.lngLat.lng]
		const roadLink = vaylaDataService.getRoadlinkByLatLng(latLngG[0], latLngG[1])
		roadLink
			.then((response) => {
				if (response == "virhe") return
				// console.log(response)
				geoJsonCoordinates = response.features[0].geometry.coordinates
				linkIdToAdd = response.features[0].properties.link_id

				if (state.length === 0) {
					state = state.concat({ coordinates: geoJsonCoordinates, linkId: linkIdToAdd, linkLength: response.features[0].properties.viivan_pituus }) //{coordinates: geoJsonCoordinates, linkId: linkIdToAdd}
				} else {
					const index = state.findIndex((l: any) => l.linkId === linkIdToAdd)
					if (index != -1) {
						state = state.filter((l: any) => l.linkId != linkIdToAdd)
					} else {
						state = state.concat({
							coordinates: geoJsonCoordinates,
							linkId: linkIdToAdd,
							linkLength: response.features[0].properties.viivan_pituus
						})
					}
				}

				setState(state)

				geoJsonData = {
					type: "FeatureCollection",
					features: [
						{
							properties: {},
							type: "Feature",
							geometry: {
								type: "MultiLineString",
								coordinates: state.map((l: any) => {
									return l.coordinates
								})
							}
						}
					]
				}
			})
			.then((r) => {
				if (map.current._loaded) {
					if (map.current.getLayer("clickRoadLink")) {
						map.current.removeLayer("clickRoadLink")
					}

					if (map.current.getSource("clickRoadLink")) {
						map.current.removeSource("clickRoadLink")
					}

					map.current.addSource("clickRoadLink", {
						type: "geojson",
						data: geoJsonData
					})

					map.current.addLayer({
						id: "clickRoadLink",
						type: "line",
						source: "clickRoadLink",
						layout: {
							"line-join": "round",
							"line-cap": "round"
						},
						paint: {
							"line-color": color,
							"line-width": 5
						}
					})
				}
			})
	}

	// console.log(setOff, " ----------------------- SETOFF --------------------------")

	if (!map) return
	if (setOff) {
		// setState([])
		// console.log(setOff, " ----------------------- SETOFF 2 --------------------------")
		map.current.off("click", ref.current)
		// if(map.current.getLayer('clickRoadLink')){

		//   // map.current.setPaintProperty('clickRoadLink', 'line-width', 0)
		//   // console.log(" --------------- CLICKROADLINK LAYER ON OLEMASSA ------------");
		//   map.current.removeLayer('clickRoadLink')
		// }

		// if(map.current.getSource('clickRoadLink')){
		//   map.current.removeSource('clickRoadLink')
		// }
		// console.log(map.current)
	} else {
		ref.current = onRoadLinkClickFunc
		// console.log(setOff, " ----------------------- SETON --------------------------")
		map.current.on("click", ref.current)
		// console.log(map.current, " ----------------- AFTER SET ON ------------------")
	}
}

export default getRoadLinkByClick
