import maplibregl from "maplibre-gl"
import React, { createContext, useContext, useMemo, useReducer } from "react"

type MapCenterPositionSettingType = {
	zoom: number
	centerPoint: { lat: number; lng: number }
}

export type MapLayerType = {
	allowOpacityChange?: boolean
	userCanToggle: boolean
	displayName: string
	type: string
	name: string
	visibility: "visible" | "none"
	opacity: number
	featured: boolean
}

type MapSource = {
	source: string
	sourceLayer: string
}

type EventListenerType = {
	type: string
	layer: string
	listener?: any
}

export type MapFeatureType = {
	showPropertyInfoByClick: boolean
	showProperties: boolean
	showRoadLinks: boolean
	editCostCenterData: boolean
	createCostCenter: boolean
	draw: boolean
}

type Action =
	| { type: "INITIAL"; payload: mapProviderStateType }
	| { type: "setMapCenterPositionSetting"; payload: MapCenterPositionSettingType }
	| { type: "setCostCenterData"; payload: CostCenter }
	| { type: "setMapLayers"; payload: MapLayerType[] | [] }
	| { type: "setMapSources"; payload: MapSource[] | [] }
	| { type: "setEventListeners"; payload: EventListenerType[] | [] }
	| { type: "setShowProperties"; payload: boolean }
	| { type: "setShowRoadLinks"; payload: boolean }
	| { type: "setShowPropertyInfoByClick"; payload: boolean }
	| { type: "setEditCostCenterData"; payload: boolean }
	| { type: "setCreateCostCenter"; payload: boolean }
	| { type: "setDraw"; payload: boolean }
	| { type: "setMapFeatures"; payload: MapFeatureType }
	| { type: "setAllProperties"; payload: Property[] }
	| { type: "setPropertyDialogData"; payload: ProperyDialogDataType }
	| { type: "setSelectedCostCenterId"; payload: number | undefined }
	| { type: "setActivePropertyPlotId"; payload: string | undefined }
	| { type: "setCurrentPopup"; payload: maplibregl.Popup | undefined }

export type MapProviderDispatch = (action: Action) => void

export type mapProviderStateType = {
	mapCenterPositionSetting: MapCenterPositionSettingType
	costCenterData?: CostCenter
	allProperties: Property[]
	mapLayers: MapLayerType[] | []
	mapSources: MapSource[] | []
	eventListeners: EventListenerType[] | []
	selectedCostCenterId?: number | undefined
	mapFeatures: MapFeatureType
	propertyDialogData?: ProperyDialogDataType
	activePropertyPlotId?: string
	currentPopup?: maplibregl.Popup
}

const DEFAULT_STATE: mapProviderStateType = {
	mapCenterPositionSetting: { centerPoint: { lat: 26.645, lng: 64.101 }, zoom: 8 },
	costCenterData: undefined,
	mapLayers: [],
	allProperties: [],
	mapSources: [],
	eventListeners: [],
	selectedCostCenterId: undefined,
	mapFeatures: {
		showPropertyInfoByClick: false,
		showProperties: false,
		showRoadLinks: false,
		editCostCenterData: false,
		createCostCenter: false,
		draw: false
	},
	activePropertyPlotId: undefined,
	currentPopup: undefined
}

const mapReducer = (state: mapProviderStateType, action: Action) => {
	switch (action.type) {
		case "INITIAL":
			return { ...action.payload }
		case "setMapCenterPositionSetting":
			return { ...state, mapCenterPositionSetting: action.payload }
		case "setCostCenterData":
			return { ...state, costCenterData: action.payload }
		case "setMapLayers":
			return { ...state, mapLayers: action.payload }
		case "setEventListeners":
			return { ...state, eventListeners: action.payload }
		case "setMapSources":
			return { ...state, mapFeatureStates: action.payload }
		case "setShowPropertyInfoByClick":
			return { ...state, mapFeatures: { ...state.mapFeatures, showPropertyInfoByClick: action.payload } }
		case "setShowProperties":
			return { ...state, mapFeatures: { ...state.mapFeatures, showProperties: action.payload } }
		case "setShowRoadLinks":
			return { ...state, mapFeatures: { ...state.mapFeatures, showRoadLinks: action.payload } }
		case "setDraw":
			return { ...state, mapFeatures: { ...state.mapFeatures, draw: action.payload } }
		case "setEditCostCenterData":
			return { ...state, mapFeatures: { ...state.mapFeatures, editCostCenterData: action.payload } }
		case "setCreateCostCenter":
			return { ...state, mapFeatures: { ...state.mapFeatures, createCostCenter: action.payload } }
		case "setPropertyDialogData":
			return { ...state, propertyDialogData: action.payload }
		case "setMapFeatures":
			return { ...state, mapFeatures: action.payload }
		case "setAllProperties":
			return { ...state, allProperties: action.payload }
		case "setSelectedCostCenterId":
			return { ...state, selectedCostCenterId: action.payload }
		case "setActivePropertyPlotId":
			return { ...state, activePropertyPlotId: action.payload }
		case "setCurrentPopup":
			return { ...state, currentPopup: action.payload }
	}
}

type MapProviderProps = { initialState?: mapProviderStateType; children: React.ReactNode }

const MapStateContext = createContext<{ state: mapProviderStateType; dispatch: MapProviderDispatch } | undefined>(undefined)

const MapStateProvider = ({ initialState, children }: MapProviderProps) => {
	const [state, dispatch] = useReducer(mapReducer, initialState ?? DEFAULT_STATE)
	const memoChildren = useMemo(() => children, [state, children])
	const value = { state, dispatch }
	return <MapStateContext.Provider value={value}>{memoChildren}</MapStateContext.Provider>
}

const useMapState = () => {
	const context = useContext(MapStateContext)
	if (context === undefined) {
		throw new Error("useMapState hook must be used within a MapStateProvider")
	}
	return context
}

export { MapStateProvider, useMapState }
