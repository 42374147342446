import React, { createContext, useContext, useMemo, useReducer } from "react"

interface costCenter {
	id: number
	name: string
	units: number
	weight: number
}

enum ExpenseTypeEnum {
	INVOICE = "INVOICE",
	RECEIPT = "RECEIPT"
}

type Action =
	| { type: "setSupplierName"; payload: string }
	| { type: "setSupplierBusinessId"; payload: string }
	| { type: "setSupplierEmail"; payload: string }
	| { type: "setSupplierPhoneNumber"; payload: string }
	| { type: "setSupplierBankAccount"; payload: string }
	| { type: "setInvoiceNumber"; payload: string }
	| { type: "setInvoiceDate"; payload: string }
	| { type: "setDueDate"; payload: string }
	| { type: "setDeliveryDate"; payload: string }
	| { type: "setReferenceNumber"; payload: string }
	| { type: "setDescription"; payload: string }
	| { type: "setInvoiceRows"; payload: PurchaseInvoiceRow[] | [] }
	| { type: "setCostCenters"; payload: costCenter[] | [] }
	| { type: "setInvoiceRowAmountsByCostCenters"; payload: [number[]] | [[]] }
	| { type: "setActiveRow"; payload: PurchaseInvoiceRow }
	| { type: "setActiveRowIndex"; payload: number }
	| { type: "setTotal"; payload: number }
	| { type: "setExpenseType"; payload: ExpenseTypeEnum }
	| { type: "setExpenseTypeDetailsForReceipt"; payload: null }
	| { type: "setDefaults"; payload: null }

type Dispatch = (action: Action) => void

type State = {
	supplierName: string
	supplierBusinessId: string
	supplierEmail: string
	supplierPhoneNumber: string
	supplierBankAccount: string
	invoiceNumber: string
	invoiceDate: string
	dueDate: string
	deliveryDate: string
	referenceNumber: string
	description: string
	invoiceRows: PurchaseInvoiceRow[] | []
	costCenters: costCenter[] | []
	invoiceRowAmountsByCostCenters: [number[]] | [number[]]
	activeRow: PurchaseInvoiceRow
	activeRowIndex: number
	total: number
	expenseType: ExpenseTypeEnum
}

const DEFAULT_STATE: State = {
	supplierName: "",
	supplierBusinessId: "",
	supplierEmail: "",
	supplierPhoneNumber: "",
	supplierBankAccount: "",
	invoiceNumber: "",
	invoiceDate: "",
	dueDate: "",
	deliveryDate: "",
	referenceNumber: "",
	description: "",
	invoiceRows: [],
	costCenters: [],
	invoiceRowAmountsByCostCenters: [[]],
	activeRow: {
		description: "",
		quantity: 0,
		unitPrice: 0,
		total: 0,
		costType: "",
		costTypeAccount: null,
		costTypeId: null,
		isDistributed: false,
		costCenterDistribution: null
	},
	activeRowIndex: -1,
	total: 0,
	expenseType: ExpenseTypeEnum.INVOICE
}

const createExpenseReducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "setSupplierName":
			return { ...state, supplierName: action.payload }
		case "setSupplierBusinessId":
			return { ...state, supplierBusinessId: action.payload }
		case "setSupplierEmail":
			return { ...state, supplierEmail: action.payload }
		case "setSupplierPhoneNumber":
			return { ...state, supplierPhoneNumber: action.payload }
		case "setSupplierBankAccount":
			return { ...state, supplierBankAccount: action.payload }
		case "setInvoiceNumber":
			return { ...state, invoiceNumber: action.payload }
		case "setInvoiceDate":
			return { ...state, invoiceDate: action.payload }
		case "setDueDate":
			return { ...state, dueDate: action.payload }
		case "setDeliveryDate":
			return { ...state, deliveryDate: action.payload }
		case "setReferenceNumber":
			return { ...state, referenceNumber: action.payload }
		case "setDescription":
			return { ...state, description: action.payload }
		case "setInvoiceRows":
			return { ...state, invoiceRows: action.payload }
		case "setCostCenters":
			return { ...state, costCenters: action.payload }
		case "setInvoiceRowAmountsByCostCenters":
			return { ...state, invoiceRowAmountsByCostCenters: action.payload }
		case "setActiveRow":
			return { ...state, activeRow: action.payload }
		case "setActiveRowIndex":
			return { ...state, activeRowIndex: action.payload }
		case "setTotal":
			return { ...state, total: action.payload }
		case "setExpenseType":
			return { ...state, expenseType: action.payload }
		case "setExpenseTypeDetailsForReceipt":
			return { ...state, dueDate: "", referenceNumber: "", supplierBankAccount: "", invoiceNumber: "" }
		case "setDefaults":
			return { ...DEFAULT_STATE, invoiceRows: [] }
	}
}

type CreateExpenseProviderProps = { children: React.ReactNode }

const CreateExpenseStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

const CreateExpenseStateProvider = ({ children }: CreateExpenseProviderProps) => {
	const memoChildren = useMemo(() => children, [])
	const [state, dispatch] = useReducer(createExpenseReducer, DEFAULT_STATE)
	const value = { state, dispatch }
	return <CreateExpenseStateContext.Provider value={value}>{memoChildren}</CreateExpenseStateContext.Provider>
}

const useCreateExpenseState = () => {
	const context = useContext(CreateExpenseStateContext)
	if (context === undefined) {
		throw new Error("useCreateExpenseState hook must be used within a CreateExpenseStateProvider")
	}
	return context
}

export { CreateExpenseStateProvider, useCreateExpenseState }
export type { State }
