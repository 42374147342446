import { TextField } from "@mui/material"
import { TextFieldProps, BaseTextFieldProps } from "@mui/material/TextField"
import React, { useEffect, useState } from "react"

interface MonetaryInputProps {
	baseProps?: BaseTextFieldProps
	variant?: TextFieldProps["variant"]
	maximumDecimals?: number
	value: number
	valueSetter: (v: number) => void
	label?: string
	allowOutsideChange?: boolean
	allowNegative?: boolean
	endAdornment?: string
	onBlur?: () => void
	forceNegative?: boolean
	disabled?: boolean
	fullWidth?: boolean
	sx?: any
	size?: "small" | "medium"
}

const MonetaryInput = ({
	baseProps,
	variant,
	value,
	valueSetter,
	label,
	maximumDecimals = 2,
	allowOutsideChange = false,
	allowNegative = true,
	endAdornment,
	onBlur = undefined,
	forceNegative = false,
	disabled = false,
	fullWidth = false,
	sx,
	size = "medium"
}: MonetaryInputProps) => {
	const [displayValue, setDisplayValue] = useState<string>(value?.toString() ?? "")

	useEffect(() => {
		if (!forceNegative || value >= 0) return
		setDisplayValue((value * -1).toString())
	}, [])

	const regex = allowNegative
		? new RegExp(`^[-+]?[0-9]{0,10}([,.][0-9]{0,${maximumDecimals}})?$`)
		: new RegExp(`^[0-9]{0,10}([,.][0-9]{0,${maximumDecimals}})?$`)

	const onValueChange = (value: string) => {
		//if(!value.match(/^[-+]?[0-9]{0,10}([,.][0-9]{0,2})?$/)) return

		if (!regex.test(value)) return
		if (value === "-0") {
			setDisplayValue(value)
			return
		}
		if (value == "") {
			valueSetter(0)
			setDisplayValue(value)
			return
		}
		value = value.replace(",", ".")
		let displayValue = value
		if (value.length === 2 && (value.charAt(0) == "-" || value.charAt(0) == "+") && value.charAt(1) == ".") {
			displayValue = value.charAt(0) + "0."
		}
		if (value.length === 1 && value.charAt(0) == ".") {
			displayValue = "0."
		}
		setDisplayValue(displayValue)

		if (!(value == "-" || value == "+" || value == "-." || value == "+." || value == ".")) {
			const float = parseFloat(value)
			valueSetter(forceNegative ? float * -1 : float)
		}
	}

	// // Handler in cases where the value is updated outside of the component
	useEffect(() => {
		if (!allowOutsideChange) return
		if (forceNegative && (value * -1).toString() !== displayValue) {
			onValueChange((value * -1).toString())
		} else if (value.toString() !== displayValue) onValueChange(value.toString())
	}, [value])

	return (
		<TextField
			variant={variant}
			inputProps={{ inputMode: "numeric" }}
			{...baseProps}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => onValueChange(e.target.value)}
			value={displayValue}
			label={label}
			disabled={disabled}
			onBlur={onBlur ? () => onBlur() : null}
			onFocus={displayValue == "0" ? (e: React.FocusEvent<HTMLInputElement>) => e.target.select() : null}
			InputProps={{ endAdornment: endAdornment, startAdornment: forceNegative ? "-" : undefined }}
			fullWidth={fullWidth}
			// sx={sx}
			size={size}
		/>
	)
}

export default MonetaryInput
