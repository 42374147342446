import React, { createContext, useContext, useMemo, useReducer } from "react"

type RoadLinks = any

type Properties = any

type Action = { type: "setRoadLinks"; payload: RoadLinks | null } | { type: "setProperties"; payload: Properties | null }

type Dispatch = (action: Action) => void

type State = {
	roadLinks: RoadLinks | null
	properties: Properties | null
}

const DEFAULT_STATE: State = {
	roadLinks: null,
	properties: null
}

const createUserReducer = (state: State, action: Action) => {
	switch (action.type) {
		case "setRoadLinks":
			return { ...state, roadLinks: action.payload }
		case "setProperties":
			return { ...state, properties: action.payload }
	}
}

type CreateCostCenterProviderProps = { children: React.ReactNode }

const CreateCostCenterStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

const CreateCostCenterStateProvider = ({ children }: CreateCostCenterProviderProps) => {
	const memoChildren = useMemo(() => children, [])
	const [state, dispatch] = useReducer(createUserReducer, DEFAULT_STATE)
	const value = { state, dispatch }
	return <CreateCostCenterStateContext.Provider value={value}>{memoChildren}</CreateCostCenterStateContext.Provider>
}

const useCreateUserState = () => {
	const context = useContext(CreateCostCenterStateContext)
	if (context === undefined) {
		throw new Error("useCreateCostCenterState hook must be used within a CreateCostCenterStateProvider")
	}
	return context
}

export { CreateCostCenterStateProvider, useCreateUserState }
