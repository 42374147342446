interface Kunta {
	kuntakoodi: number
	kunnannimi: string
}

export const getKuntakoodiFromName = (name: string) => {
	const koodi = kuntakoodit.filter((k: Kunta) => k.kunnannimi.toLowerCase() === name.toLowerCase())
	return koodi[0]?.kuntakoodi ?? null
}

const kuntakoodit: Kunta[] = [
	{ kuntakoodi: 20, kunnannimi: "Akaa" },
	{ kuntakoodi: 5, kunnannimi: "Alajärvi" },
	{ kuntakoodi: 9, kunnannimi: "Alavieska" },
	{ kuntakoodi: 10, kunnannimi: "Alavus" },
	{ kuntakoodi: 16, kunnannimi: "Asikkala" },
	{ kuntakoodi: 18, kunnannimi: "Askola" },
	{ kuntakoodi: 19, kunnannimi: "Aura" },
	{ kuntakoodi: 35, kunnannimi: "Brändö" },
	{ kuntakoodi: 43, kunnannimi: "Eckerö" },
	{ kuntakoodi: 46, kunnannimi: "Enonkoski" },
	{ kuntakoodi: 47, kunnannimi: "Enontekiö" },
	{ kuntakoodi: 49, kunnannimi: "Espoo" },
	{ kuntakoodi: 50, kunnannimi: "Eura" },
	{ kuntakoodi: 51, kunnannimi: "Eurajoki" },
	{ kuntakoodi: 52, kunnannimi: "Evijärvi" },
	{ kuntakoodi: 60, kunnannimi: "Finström" },
	{ kuntakoodi: 61, kunnannimi: "Forssa" },
	{ kuntakoodi: 62, kunnannimi: "Föglö" },
	{ kuntakoodi: 65, kunnannimi: "Geta" },
	{ kuntakoodi: 69, kunnannimi: "Haapajärvi" },
	{ kuntakoodi: 71, kunnannimi: "Haapavesi" },
	{ kuntakoodi: 72, kunnannimi: "Hailuoto" },
	{ kuntakoodi: 74, kunnannimi: "Halsua" },
	{ kuntakoodi: 75, kunnannimi: "Hamina" },
	{ kuntakoodi: 76, kunnannimi: "Hammarland" },
	{ kuntakoodi: 77, kunnannimi: "Hankasalmi" },
	{ kuntakoodi: 78, kunnannimi: "Hanko" },
	{ kuntakoodi: 79, kunnannimi: "Harjavalta" },
	{ kuntakoodi: 81, kunnannimi: "Hartola" },
	{ kuntakoodi: 82, kunnannimi: "Hattula" },
	{ kuntakoodi: 86, kunnannimi: "Hausjärvi" },
	{ kuntakoodi: 111, kunnannimi: "Heinola" },
	{ kuntakoodi: 90, kunnannimi: "Heinävesi" },
	{ kuntakoodi: 91, kunnannimi: "Helsinki" },
	{ kuntakoodi: 97, kunnannimi: "Hirvensalmi" },
	{ kuntakoodi: 98, kunnannimi: "Hollola" },
	{ kuntakoodi: 102, kunnannimi: "Huittinen" },
	{ kuntakoodi: 103, kunnannimi: "Humppila" },
	{ kuntakoodi: 105, kunnannimi: "Hyrynsalmi" },
	{ kuntakoodi: 106, kunnannimi: "Hyvinkää" },
	{ kuntakoodi: 108, kunnannimi: "Hämeenkyrö" },
	{ kuntakoodi: 109, kunnannimi: "Hämeenlinna" },
	{ kuntakoodi: 139, kunnannimi: "Ii" },
	{ kuntakoodi: 140, kunnannimi: "Iisalmi" },
	{ kuntakoodi: 142, kunnannimi: "Iitti" },
	{ kuntakoodi: 143, kunnannimi: "Ikaalinen" },
	{ kuntakoodi: 145, kunnannimi: "Ilmajoki" },
	{ kuntakoodi: 146, kunnannimi: "Ilomantsi" },
	{ kuntakoodi: 153, kunnannimi: "Imatra" },
	{ kuntakoodi: 148, kunnannimi: "Inari" },
	{ kuntakoodi: 149, kunnannimi: "Inkoo" },
	{ kuntakoodi: 151, kunnannimi: "Isojoki" },
	{ kuntakoodi: 152, kunnannimi: "Isokyrö" },
	{ kuntakoodi: 165, kunnannimi: "Janakkala" },
	{ kuntakoodi: 167, kunnannimi: "Joensuu" },
	{ kuntakoodi: 169, kunnannimi: "Jokioinen" },
	{ kuntakoodi: 170, kunnannimi: "Jomala" },
	{ kuntakoodi: 171, kunnannimi: "Joroinen" },
	{ kuntakoodi: 172, kunnannimi: "Joutsa" },
	{ kuntakoodi: 176, kunnannimi: "Juuka" },
	{ kuntakoodi: 177, kunnannimi: "Juupajoki" },
	{ kuntakoodi: 178, kunnannimi: "Juva" },
	{ kuntakoodi: 179, kunnannimi: "Jyväskylä" },
	{ kuntakoodi: 181, kunnannimi: "Jämijärvi" },
	{ kuntakoodi: 182, kunnannimi: "Jämsä" },
	{ kuntakoodi: 186, kunnannimi: "Järvenpää" },
	{ kuntakoodi: 202, kunnannimi: "Kaarina" },
	{ kuntakoodi: 204, kunnannimi: "Kaavi" },
	{ kuntakoodi: 205, kunnannimi: "Kajaani" },
	{ kuntakoodi: 208, kunnannimi: "Kalajoki" },
	{ kuntakoodi: 211, kunnannimi: "Kangasala" },
	{ kuntakoodi: 213, kunnannimi: "Kangasniemi" },
	{ kuntakoodi: 214, kunnannimi: "Kankaanpää" },
	{ kuntakoodi: 216, kunnannimi: "Kannonkoski" },
	{ kuntakoodi: 217, kunnannimi: "Kannus" },
	{ kuntakoodi: 218, kunnannimi: "Karijoki" },
	{ kuntakoodi: 224, kunnannimi: "Karkkila" },
	{ kuntakoodi: 226, kunnannimi: "Karstula" },
	{ kuntakoodi: 230, kunnannimi: "Karvia" },
	{ kuntakoodi: 231, kunnannimi: "Kaskinen" },
	{ kuntakoodi: 232, kunnannimi: "Kauhajoki" },
	{ kuntakoodi: 233, kunnannimi: "Kauhava" },
	{ kuntakoodi: 235, kunnannimi: "Kauniainen" },
	{ kuntakoodi: 236, kunnannimi: "Kaustinen" },
	{ kuntakoodi: 239, kunnannimi: "Keitele" },
	{ kuntakoodi: 240, kunnannimi: "Kemi" },
	{ kuntakoodi: 320, kunnannimi: "Kemijärvi" },
	{ kuntakoodi: 241, kunnannimi: "Keminmaa" },
	{ kuntakoodi: 322, kunnannimi: "Kemiönsaari" },
	{ kuntakoodi: 244, kunnannimi: "Kempele" },
	{ kuntakoodi: 245, kunnannimi: "Kerava" },
	{ kuntakoodi: 249, kunnannimi: "Keuruu" },
	{ kuntakoodi: 250, kunnannimi: "Kihniö" },
	{ kuntakoodi: 256, kunnannimi: "Kinnula" },
	{ kuntakoodi: 257, kunnannimi: "Kirkkonummi" },
	{ kuntakoodi: 260, kunnannimi: "Kitee" },
	{ kuntakoodi: 261, kunnannimi: "Kittilä" },
	{ kuntakoodi: 263, kunnannimi: "Kiuruvesi" },
	{ kuntakoodi: 265, kunnannimi: "Kivijärvi" },
	{ kuntakoodi: 271, kunnannimi: "Kokemäki" },
	{ kuntakoodi: 272, kunnannimi: "Kokkola" },
	{ kuntakoodi: 273, kunnannimi: "Kolari" },
	{ kuntakoodi: 275, kunnannimi: "Konnevesi" },
	{ kuntakoodi: 276, kunnannimi: "Kontiolahti" },
	{ kuntakoodi: 280, kunnannimi: "Korsnäs" },
	{ kuntakoodi: 284, kunnannimi: "Koski Tl" },
	{ kuntakoodi: 285, kunnannimi: "Kotka" },
	{ kuntakoodi: 286, kunnannimi: "Kouvola" },
	{ kuntakoodi: 287, kunnannimi: "Kristiinankaupunki" },
	{ kuntakoodi: 288, kunnannimi: "Kruunupyy" },
	{ kuntakoodi: 290, kunnannimi: "Kuhmo" },
	{ kuntakoodi: 291, kunnannimi: "Kuhmoinen" },
	{ kuntakoodi: 295, kunnannimi: "Kumlinge" },
	{ kuntakoodi: 297, kunnannimi: "Kuopio" },
	{ kuntakoodi: 300, kunnannimi: "Kuortane" },
	{ kuntakoodi: 301, kunnannimi: "Kurikka" },
	{ kuntakoodi: 304, kunnannimi: "Kustavi" },
	{ kuntakoodi: 305, kunnannimi: "Kuusamo" },
	{ kuntakoodi: 312, kunnannimi: "Kyyjärvi" },
	{ kuntakoodi: 316, kunnannimi: "Kärkölä" },
	{ kuntakoodi: 317, kunnannimi: "Kärsämäki" },
	{ kuntakoodi: 318, kunnannimi: "Kökar" },
	{ kuntakoodi: 398, kunnannimi: "Lahti" },
	{ kuntakoodi: 399, kunnannimi: "Laihia" },
	{ kuntakoodi: 400, kunnannimi: "Laitila" },
	{ kuntakoodi: 407, kunnannimi: "Lapinjärvi" },
	{ kuntakoodi: 402, kunnannimi: "Lapinlahti" },
	{ kuntakoodi: 403, kunnannimi: "Lappajärvi" },
	{ kuntakoodi: 405, kunnannimi: "Lappeenranta" },
	{ kuntakoodi: 408, kunnannimi: "Lapua" },
	{ kuntakoodi: 410, kunnannimi: "Laukaa" },
	{ kuntakoodi: 416, kunnannimi: "Lemi" },
	{ kuntakoodi: 417, kunnannimi: "Lemland" },
	{ kuntakoodi: 418, kunnannimi: "Lempäälä" },
	{ kuntakoodi: 420, kunnannimi: "Leppävirta" },
	{ kuntakoodi: 421, kunnannimi: "Lestijärvi" },
	{ kuntakoodi: 422, kunnannimi: "Lieksa" },
	{ kuntakoodi: 423, kunnannimi: "Lieto" },
	{ kuntakoodi: 425, kunnannimi: "Liminka" },
	{ kuntakoodi: 426, kunnannimi: "Liperi" },
	{ kuntakoodi: 444, kunnannimi: "Lohja" },
	{ kuntakoodi: 430, kunnannimi: "Loimaa" },
	{ kuntakoodi: 433, kunnannimi: "Loppi" },
	{ kuntakoodi: 434, kunnannimi: "Loviisa" },
	{ kuntakoodi: 435, kunnannimi: "Luhanka" },
	{ kuntakoodi: 436, kunnannimi: "Lumijoki" },
	{ kuntakoodi: 438, kunnannimi: "Lumparland" },
	{ kuntakoodi: 440, kunnannimi: "Luoto" },
	{ kuntakoodi: 441, kunnannimi: "Luumäki" },
	{ kuntakoodi: 475, kunnannimi: "Maalahti" },
	{ kuntakoodi: 478, kunnannimi: "Maarianhamina - Mariehamn" },
	{ kuntakoodi: 480, kunnannimi: "Marttila" },
	{ kuntakoodi: 481, kunnannimi: "Masku" },
	{ kuntakoodi: 483, kunnannimi: "Merijärvi" },
	{ kuntakoodi: 484, kunnannimi: "Merikarvia" },
	{ kuntakoodi: 489, kunnannimi: "Miehikkälä" },
	{ kuntakoodi: 491, kunnannimi: "Mikkeli" },
	{ kuntakoodi: 494, kunnannimi: "Muhos" },
	{ kuntakoodi: 495, kunnannimi: "Multia" },
	{ kuntakoodi: 498, kunnannimi: "Muonio" },
	{ kuntakoodi: 499, kunnannimi: "Mustasaari" },
	{ kuntakoodi: 500, kunnannimi: "Muurame" },
	{ kuntakoodi: 503, kunnannimi: "Mynämäki" },
	{ kuntakoodi: 504, kunnannimi: "Myrskylä" },
	{ kuntakoodi: 505, kunnannimi: "Mäntsälä" },
	{ kuntakoodi: 508, kunnannimi: "Mänttä-Vilppula" },
	{ kuntakoodi: 507, kunnannimi: "Mäntyharju" },
	{ kuntakoodi: 529, kunnannimi: "Naantali" },
	{ kuntakoodi: 531, kunnannimi: "Nakkila" },
	{ kuntakoodi: 535, kunnannimi: "Nivala" },
	{ kuntakoodi: 536, kunnannimi: "Nokia" },
	{ kuntakoodi: 538, kunnannimi: "Nousiainen" },
	{ kuntakoodi: 541, kunnannimi: "Nurmes" },
	{ kuntakoodi: 543, kunnannimi: "Nurmijärvi" },
	{ kuntakoodi: 545, kunnannimi: "Närpiö" },
	{ kuntakoodi: 560, kunnannimi: "Orimattila" },
	{ kuntakoodi: 561, kunnannimi: "Oripää" },
	{ kuntakoodi: 562, kunnannimi: "Orivesi" },
	{ kuntakoodi: 563, kunnannimi: "Oulainen" },
	{ kuntakoodi: 564, kunnannimi: "Oulu" },
	{ kuntakoodi: 309, kunnannimi: "Outokumpu" },
	{ kuntakoodi: 576, kunnannimi: "Padasjoki" },
	{ kuntakoodi: 577, kunnannimi: "Paimio" },
	{ kuntakoodi: 578, kunnannimi: "Paltamo" },
	{ kuntakoodi: 445, kunnannimi: "Parainen" },
	{ kuntakoodi: 580, kunnannimi: "Parikkala" },
	{ kuntakoodi: 581, kunnannimi: "Parkano" },
	{ kuntakoodi: 599, kunnannimi: "Pedersören kunta" },
	{ kuntakoodi: 583, kunnannimi: "Pelkosenniemi" },
	{ kuntakoodi: 854, kunnannimi: "Pello" },
	{ kuntakoodi: 584, kunnannimi: "Perho" },
	{ kuntakoodi: 588, kunnannimi: "Pertunmaa" },
	{ kuntakoodi: 592, kunnannimi: "Petäjävesi" },
	{ kuntakoodi: 593, kunnannimi: "Pieksämäki" },
	{ kuntakoodi: 595, kunnannimi: "Pielavesi" },
	{ kuntakoodi: 598, kunnannimi: "Pietarsaari" },
	{ kuntakoodi: 601, kunnannimi: "Pihtipudas" },
	{ kuntakoodi: 604, kunnannimi: "Pirkkala" },
	{ kuntakoodi: 607, kunnannimi: "Polvijärvi" },
	{ kuntakoodi: 608, kunnannimi: "Pomarkku" },
	{ kuntakoodi: 609, kunnannimi: "Pori" },
	{ kuntakoodi: 611, kunnannimi: "Pornainen" },
	{ kuntakoodi: 638, kunnannimi: "Porvoo" },
	{ kuntakoodi: 614, kunnannimi: "Posio" },
	{ kuntakoodi: 615, kunnannimi: "Pudasjärvi" },
	{ kuntakoodi: 616, kunnannimi: "Pukkila" },
	{ kuntakoodi: 619, kunnannimi: "Punkalaidun" },
	{ kuntakoodi: 620, kunnannimi: "Puolanka" },
	{ kuntakoodi: 623, kunnannimi: "Puumala" },
	{ kuntakoodi: 624, kunnannimi: "Pyhtää" },
	{ kuntakoodi: 625, kunnannimi: "Pyhäjoki" },
	{ kuntakoodi: 626, kunnannimi: "Pyhäjärvi" },
	{ kuntakoodi: 630, kunnannimi: "Pyhäntä" },
	{ kuntakoodi: 631, kunnannimi: "Pyhäranta" },
	{ kuntakoodi: 635, kunnannimi: "Pälkäne" },
	{ kuntakoodi: 636, kunnannimi: "Pöytyä" },
	{ kuntakoodi: 678, kunnannimi: "Raahe" },
	{ kuntakoodi: 710, kunnannimi: "Raasepori" },
	{ kuntakoodi: 680, kunnannimi: "Raisio" },
	{ kuntakoodi: 681, kunnannimi: "Rantasalmi" },
	{ kuntakoodi: 683, kunnannimi: "Ranua" },
	{ kuntakoodi: 684, kunnannimi: "Rauma" },
	{ kuntakoodi: 686, kunnannimi: "Rautalampi" },
	{ kuntakoodi: 687, kunnannimi: "Rautavaara" },
	{ kuntakoodi: 689, kunnannimi: "Rautjärvi" },
	{ kuntakoodi: 691, kunnannimi: "Reisjärvi" },
	{ kuntakoodi: 694, kunnannimi: "Riihimäki" },
	{ kuntakoodi: 697, kunnannimi: "Ristijärvi" },
	{ kuntakoodi: 698, kunnannimi: "Rovaniemi" },
	{ kuntakoodi: 700, kunnannimi: "Ruokolahti" },
	{ kuntakoodi: 702, kunnannimi: "Ruovesi" },
	{ kuntakoodi: 704, kunnannimi: "Rusko" },
	{ kuntakoodi: 707, kunnannimi: "Rääkkylä" },
	{ kuntakoodi: 729, kunnannimi: "Saarijärvi" },
	{ kuntakoodi: 732, kunnannimi: "Salla" },
	{ kuntakoodi: 734, kunnannimi: "Salo" },
	{ kuntakoodi: 736, kunnannimi: "Saltvik" },
	{ kuntakoodi: 790, kunnannimi: "Sastamala" },
	{ kuntakoodi: 738, kunnannimi: "Sauvo" },
	{ kuntakoodi: 739, kunnannimi: "Savitaipale" },
	{ kuntakoodi: 740, kunnannimi: "Savonlinna" },
	{ kuntakoodi: 742, kunnannimi: "Savukoski" },
	{ kuntakoodi: 743, kunnannimi: "Seinäjoki" },
	{ kuntakoodi: 746, kunnannimi: "Sievi" },
	{ kuntakoodi: 747, kunnannimi: "Siikainen" },
	{ kuntakoodi: 748, kunnannimi: "Siikajoki" },
	{ kuntakoodi: 791, kunnannimi: "Siikalatva" },
	{ kuntakoodi: 749, kunnannimi: "Siilinjärvi" },
	{ kuntakoodi: 751, kunnannimi: "Simo" },
	{ kuntakoodi: 753, kunnannimi: "Sipoo" },
	{ kuntakoodi: 755, kunnannimi: "Siuntio" },
	{ kuntakoodi: 758, kunnannimi: "Sodankylä" },
	{ kuntakoodi: 759, kunnannimi: "Soini" },
	{ kuntakoodi: 761, kunnannimi: "Somero" },
	{ kuntakoodi: 762, kunnannimi: "Sonkajärvi" },
	{ kuntakoodi: 765, kunnannimi: "Sotkamo" },
	{ kuntakoodi: 766, kunnannimi: "Sottunga" },
	{ kuntakoodi: 768, kunnannimi: "Sulkava" },
	{ kuntakoodi: 771, kunnannimi: "Sund" },
	{ kuntakoodi: 777, kunnannimi: "Suomussalmi" },
	{ kuntakoodi: 778, kunnannimi: "Suonenjoki" },
	{ kuntakoodi: 781, kunnannimi: "Sysmä" },
	{ kuntakoodi: 783, kunnannimi: "Säkylä" },
	{ kuntakoodi: 831, kunnannimi: "Taipalsaari" },
	{ kuntakoodi: 832, kunnannimi: "Taivalkoski" },
	{ kuntakoodi: 833, kunnannimi: "Taivassalo" },
	{ kuntakoodi: 834, kunnannimi: "Tammela" },
	{ kuntakoodi: 837, kunnannimi: "Tampere" },
	{ kuntakoodi: 844, kunnannimi: "Tervo" },
	{ kuntakoodi: 845, kunnannimi: "Tervola" },
	{ kuntakoodi: 846, kunnannimi: "Teuva" },
	{ kuntakoodi: 848, kunnannimi: "Tohmajärvi" },
	{ kuntakoodi: 849, kunnannimi: "Toholampi" },
	{ kuntakoodi: 850, kunnannimi: "Toivakka" },
	{ kuntakoodi: 851, kunnannimi: "Tornio" },
	{ kuntakoodi: 853, kunnannimi: "Turku" },
	{ kuntakoodi: 857, kunnannimi: "Tuusniemi" },
	{ kuntakoodi: 858, kunnannimi: "Tuusula" },
	{ kuntakoodi: 859, kunnannimi: "Tyrnävä" },
	{ kuntakoodi: 886, kunnannimi: "Ulvila" },
	{ kuntakoodi: 887, kunnannimi: "Urjala" },
	{ kuntakoodi: 889, kunnannimi: "Utajärvi" },
	{ kuntakoodi: 890, kunnannimi: "Utsjoki" },
	{ kuntakoodi: 892, kunnannimi: "Uurainen" },
	{ kuntakoodi: 893, kunnannimi: "Uusikaarlepyy" },
	{ kuntakoodi: 895, kunnannimi: "Uusikaupunki" },
	{ kuntakoodi: 785, kunnannimi: "Vaala" },
	{ kuntakoodi: 905, kunnannimi: "Vaasa" },
	{ kuntakoodi: 908, kunnannimi: "Valkeakoski" },
	{ kuntakoodi: 92, kunnannimi: "Vantaa" },
	{ kuntakoodi: 915, kunnannimi: "Varkaus" },
	{ kuntakoodi: 918, kunnannimi: "Vehmaa" },
	{ kuntakoodi: 921, kunnannimi: "Vesanto" },
	{ kuntakoodi: 922, kunnannimi: "Vesilahti" },
	{ kuntakoodi: 924, kunnannimi: "Veteli" },
	{ kuntakoodi: 925, kunnannimi: "Vieremä" },
	{ kuntakoodi: 927, kunnannimi: "Vihti" },
	{ kuntakoodi: 931, kunnannimi: "Viitasaari" },
	{ kuntakoodi: 934, kunnannimi: "Vimpeli" },
	{ kuntakoodi: 935, kunnannimi: "Virolahti" },
	{ kuntakoodi: 936, kunnannimi: "Virrat" },
	{ kuntakoodi: 941, kunnannimi: "Vårdö" },
	{ kuntakoodi: 946, kunnannimi: "Vöyri" },
	{ kuntakoodi: 976, kunnannimi: "Ylitornio" },
	{ kuntakoodi: 977, kunnannimi: "Ylivieska" },
	{ kuntakoodi: 980, kunnannimi: "Ylöjärvi" },
	{ kuntakoodi: 981, kunnannimi: "Ypäjä" },
	{ kuntakoodi: 989, kunnannimi: "Ähtäri" },
	{ kuntakoodi: 992, kunnannimi: "Äänekoski" }
]
