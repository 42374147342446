import { ApolloQueryResult, gql, OperationVariables, useMutation, useQuery } from "@apollo/client"
import { TextField, MenuItem, SwipeableDrawer, Grid, Stack, Box, Tooltip, ToggleButtonGroup, ToggleButton } from "@mui/material"
import React, { useState, useEffect } from "react"
import PropertiesTable from "./DrawerTableViews/PropertiesTable/PropertiesTable"

import HomeIcon from "@mui/icons-material/Home"
import AddIcon from "@mui/icons-material/Add"
import GroupsIcon from "@mui/icons-material/Groups"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion"
import AddRoadIcon from "@mui/icons-material/AddRoad"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import GroupAddIcon from "@mui/icons-material/GroupAdd"

import { costCenterLinksVar } from "../../../network/apolloClient/apolloClientMakeVars"
import EstablishmentTable from "./DrawerTableViews/EstblishmentTable/EstablishmentTable"
import PartnersTable from "./DrawerTableViews/PartnerTable/PartnersTable"
import { Dialog, DialogContent, Button } from "@mui/material"
import { CreateCostCenterStateProvider } from "../../../utils/providers/createCostCenterProvider/CreateCostCenterProvider"
import { CreateUserStateProvider } from "../../../utils/providers/CreateUserProvider"
import CreateEstablishmentDialog from "./CreateEstablishmentDialog"
import CreateNewCostCenterModal from "./CreateNewCostCenterModal"
import CreateNewUserDialog from "./CreateNewUserDialog"
import { Popover } from "@mui/material"

import BulkMemberImportTool from "../BulkImportTools/BulkMemberImportTool"
import BulkUnitImportTool from "../BulkImportTools/BulkUnitImportTool"
import DialogCloseButton from "../../reusables/DialogCloseButton"
import CustomDialogActions from "../../reusables/CustomDialogActions"
import CustomDialogTitle from "../../reusables/CustomDialogTitle"
import { Typography } from "@mui/material"
import { costCenterPropertyVar } from "../../../network/apolloClient/apolloClientMakeVars"
import SimpleUnitsImport from "../SimpleUnitsImport/SimpleUnitsImport"
import { DialogTitle } from "@mui/material"
import { Divider } from "@mui/material"
import { RadioGroup } from "@mui/material"
import { Paper } from "@mui/material"
import { Radio } from "@mui/material"
import CustomNotification from "../../reusables/CustomNotification"
import { MutationFab } from "../../reusables/MutationButtonChipFab"

const GET_COST_CENTER_NAME = gql`
	query GetCostCenterName {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					idColor
					name
					id
				}
			}
			roadCooperativeSettingsByRoadCooperativeId {
				nodes {
					id
					unitsPresentationType
				}
			}
		}
	}
`

const UPDATE_UNITS_VIEW_TYPE = gql`
	mutation UpdateUnitsViewType($id: Int = 10, $unitsPresentationType: String = "") {
		updateRoadCooperativeSettingById(input: { roadCooperativeSettingPatch: { unitsPresentationType: $unitsPresentationType }, id: $id }) {
			roadCooperativeSetting {
				unitsPresentationType
			}
		}
	}
`

declare global {
	type UnitsViewType = "easy" | "professional"
}

interface HomePageDrawerProps {
	showDrawer: boolean
	setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>
	refetchRCGeoJson: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
	activeCostCenter: ActiveCostCenterInterface
	setActiveCostCenter: React.Dispatch<React.SetStateAction<ActiveCostCenterInterface>>
	setRoadCooperativeStatusValues: React.Dispatch<
		React.SetStateAction<{
			createRoad: boolean
			createMembers: boolean
			createEstablishments: boolean
		}>
	>
	roadCooperativeStatusValues: {
		createRoad: boolean
		createMembers: boolean
		createEstablishments: boolean
	}
}

const HomePageDrawer = ({
	setRoadCooperativeStatusValues,
	roadCooperativeStatusValues,
	showDrawer,
	setShowDrawer,
	refetchRCGeoJson,
	activeCostCenter,
	setActiveCostCenter
}: HomePageDrawerProps) => {
	const drawerWidth = "700px"

	const [allEstablismhentsView, setAllEstablismhentsView] = useState<JSX.Element | null>(null)
	const [allPartnersView, setAllPartnersView] = useState<JSX.Element | null>(null)
	const [allPropertiesView, setAllPropertiesView] = useState<JSX.Element | null>(null)
	const [selectedView, setSelectedView] = useState("partners")
	const [showCreateUserForm, setShowCreateUserForm] = useState(false)
	const [showCreateEstablishmentForm, setShowCreateEstablishmentForm] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | EventTarget>(null)
	const [openBulkMemberDataImport, setOpenBulkMemberDataImport] = useState(false)
	const [openBulkUnitDataImport, setOpenBulkUnitDataImport] = useState(false)
	const [openCreateCostCenter, setOpenCreateCostCenter] = useState(false)
	const [openNotiDialog, setOpenNotiDialog] = useState(false)
	const [costCenterData, setCostCenterData] = useState<CostCenter[] | undefined>(undefined)

	const [dataChangeToggle, setDataChangeToggle] = useState(false)

	const [openSelectUnitsViewType, setOpenSelectUnitsViewType] = useState(false)
	const [unitsViewType, setUnitsViewType] = useState<UnitsViewType>()
	const [roadCooperativeSettingsId, setRoadCoopeartiveSettingsId] = useState<number | null>()
	const [selectedUnitsViewType, setSelectedUnitsViewType] = useState<UnitsViewType | undefined>("professional")

	const {
		data,
		loading,
		error,
		refetch: refetchCCNames
	} = useQuery(GET_COST_CENTER_NAME, {
		onCompleted(data) {
			if (!data.roadCooperativeWithJWT?.costCentersByRoadCooperativeId) return
			setCostCenterData(data.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes)
			setRoadCoopeartiveSettingsId(data.roadCooperativeWithJWT.roadCooperativeSettingsByRoadCooperativeId.nodes[0].id)
			setUnitsViewType(data.roadCooperativeWithJWT.roadCooperativeSettingsByRoadCooperativeId.nodes[0].unitsPresentationType)
		}
	})

	const [updateUnitsViewType] = useMutation(UPDATE_UNITS_VIEW_TYPE)

	const pulseFunctionWhenDataCreated = () => {
		setDataChangeToggle(!dataChangeToggle)
	}

	useEffect(() => {
		if (roadCooperativeStatusValues.createEstablishments) {
			// Tästä avautuu popuppi josta määritetään että millä tavalla luodaan yksiköt
			// if (unitsViewType) {
			// 	setOpenBulkUnitDataImport(true)
			// } else {
			setOpenSelectUnitsViewType(true)
			// }
		}
		if (roadCooperativeStatusValues.createMembers) {
			setOpenBulkMemberDataImport(true)
		}
		if (roadCooperativeStatusValues.createRoad) {
			setOpenCreateCostCenter(true)
		}
	}, [roadCooperativeStatusValues])

	const handleCloseCreateCostCenter = () => {
		costCenterPropertyVar({ properties: [] })
		costCenterLinksVar({ roadLinks: [] })
		setOpenCreateCostCenter(false)
		setOpenNotiDialog(false)
		refetchCCNames()
		refetchRCGeoJson()
		setRoadCooperativeStatusValues((prevValue) => {
			return { ...prevValue, createRoad: false }
		})
	}

	const handleUnitsViewTypeSelectSave = async () => {
		await updateUnitsViewType({
			variables: { id: roadCooperativeSettingsId, unitsPresentationType: selectedUnitsViewType },
			onCompleted(data, clientOptions) {
				setUnitsViewType(data.updateRoadCooperativeSettingById.roadCooperativeSetting.unitsPresentationType)
			}
		})
		setOpenSelectUnitsViewType(false)
		setOpenBulkUnitDataImport(true)
	}

	const handleSelectViewType = (value: UnitsViewType) => {
		setSelectedUnitsViewType(value)
	}

	const handleCloseMemberBulkImportTool = () => {
		setRoadCooperativeStatusValues((prevValue) => {
			return { ...prevValue, createMembers: false }
		})
		setOpenBulkMemberDataImport(false)
	}

	const handleCloseUnitBulkImportTool = () => {
		setRoadCooperativeStatusValues((prevValue) => {
			return { ...prevValue, createEstablishments: false }
		})
		setOpenBulkUnitDataImport(false)
	}

	const handleChangeCostCenter = (name: string, id: number | undefined, color: string) => {
		// Vaihdetaan kustannuspaikkaa
		setActiveCostCenter({ id: id, name: name, color: color })
	}

	useEffect(() => {
		if (activeCostCenter.id == undefined) {
			setAllPropertiesView(
				<PropertiesTable
					refetchRCGeoJson={refetchRCGeoJson}
					activeCostCenterId={null}
					activeCostCenterColor={activeCostCenter.color}
					wholeRoadCooperative={true}
					handlePopoverClick={handlePopoverClick}
				/>
			)
			setAllEstablismhentsView(
				<EstablishmentTable
					dataChange={dataChangeToggle}
					refetchRCGeoJson={refetchRCGeoJson}
					activeCostCenterId={null}
					activeCostCenterColor={activeCostCenter.color}
					wholeRoadCooperative={true}
					handlePopoverClick={handlePopoverClick}
				/>
			)
			setAllPartnersView(
				<PartnersTable
					dataChange={dataChangeToggle}
					activeCostCenterId={null}
					activeCostCenterColor={activeCostCenter.color}
					wholeRoadCooperative={true}
					handlePopoverClick={handlePopoverClick}
				/>
			)
		} else {
			setAllPropertiesView(
				<PropertiesTable
					refetchRCGeoJson={refetchRCGeoJson}
					activeCostCenterId={activeCostCenter.id}
					activeCostCenterColor={activeCostCenter.color}
					wholeRoadCooperative={false}
					handlePopoverClick={handlePopoverClick}
				/>
			)
			setAllEstablismhentsView(
				<EstablishmentTable
					dataChange={dataChangeToggle}
					refetchRCGeoJson={refetchRCGeoJson}
					activeCostCenterId={activeCostCenter.id}
					activeCostCenterColor={activeCostCenter.color}
					wholeRoadCooperative={false}
					handlePopoverClick={handlePopoverClick}
				/>
			)
			setAllPartnersView(
				<PartnersTable
					dataChange={dataChangeToggle}
					activeCostCenterId={activeCostCenter.id}
					activeCostCenterColor={activeCostCenter.color}
					wholeRoadCooperative={true}
					handlePopoverClick={handlePopoverClick}
				/>
			)
		}
	}, [activeCostCenter.id, activeCostCenter, dataChangeToggle])

	useEffect(() => {
		if (loading || error) return
		if (!data?.roadCooperativeById?.costCentersByRoadCooperativeId) return
		if (data.roadCooperativeById.costCentersByRoadCooperativeId.nodes.length == 1) {
			const cc = data.roadCooperativeById.costCentersByRoadCooperativeId.nodes[0]
			handleChangeCostCenter(cc.name, cc.id, cc.idColor)
		}
	}, [data])

	const handleSelectedViewType = (e: React.MouseEvent<HTMLElement, MouseEvent>, value: "establishments" | "partners" | "properties" | null) => {
		if (value === null) return
		setSelectedView(value)
	}

	const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const hideToggleButtonText = window.visualViewport && (window.visualViewport.width < 500 || window.visualViewport.height < 638)

	return (
		<>
			{/* // TODO: screen.width pois ja joku parempi ratkaisu tilalle. */}
			<SwipeableDrawer
				sx={{
					width: screen.width > 1120 ? drawerWidth : "100vw",
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: screen.width > 1120 ? drawerWidth : "100vw",
						boxSizing: "border-box",
						borderRadius: "10px",
						borderTopRightRadius: "0px"
					}
				}}
				variant="persistent"
				ModalProps={{
					keepMounted: true
				}}
				anchor={"right"}
				open={showDrawer}
				onOpen={() => console.log("auki")} // Tähän voi lisätä toiminnallisuuden että drawerin data latautuu vasta kun se aukaistaan
				onClose={() => setShowDrawer(false)}
			>
				<Grid container spacing={0} direction="column" sx={{ overflow: "hidden", height: "100%" }}>
					<Grid item container xs={3} sx={{ justifyContent: "center" }}>
						<Grid item xs={12}>
							<Stack
								direction="row"
								spacing={3}
								sx={{
									margin: 0,
									paddingBottom: "20px",
									paddingTop: "15px",
									height: "60px",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								{costCenterData == undefined ? null : costCenterData?.length == 0 ? null : costCenterData.length == 1 ? (
									<>
										<TextField
											sx={{
												minWidth: "300px"
											}}
											disabled
											readOnly
											type="text"
											label="Tie"
											variant="outlined"
											value={costCenterData[0].name}
										/>
									</>
								) : (
									<>
										<TextField select label="Tie" sx={{ minWidth: "300px" }} value={activeCostCenter?.name ?? "tiekunta"}>
											{costCenterData != undefined
												? costCenterData.length == 0
													? null
													: costCenterData.map((cc: CostCenter, index: number) => {
															return (
																<MenuItem
																	key={cc.id}
																	value={cc.name}
																	onClick={() => handleChangeCostCenter(cc.name, cc?.id ? cc?.id : 0, cc.idColor)}
																	sx={{ minWith: "300px" }}
																>
																	<Stack direction="row">
																		<Box
																			key={index}
																			sx={{
																				width: "12px",
																				height: "12px",
																				background: cc.idColor,
																				borderRadius: "8px",
																				marginRight: "15px",
																				marginLeft: "10px",
																				alignSelf: "center"
																			}}
																		/>
																		{cc.name}
																	</Stack>
																</MenuItem>
															)
														})
												: null}
											<MenuItem
												value={"tiekunta"}
												onClick={() => handleChangeCostCenter("tiekunta", undefined, "#7FC646")}
												// sx={{ padding: '20px' }}
											>
												Koko tiekunta
											</MenuItem>
										</TextField>
									</>
								)}
							</Stack>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								textAlign: "center"
							}}
						>
							<ToggleButtonGroup
								color="primary"
								onChange={handleSelectedViewType}
								value={selectedView}
								exclusive
								size="large"
								variant="contained"
								aria-label="outlined primary button group"
							>
								<ToggleButton value="establishments" fullWidth sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
									<Tooltip title="Tieyksiköt">
										<Stack direction="row" alignItems="center">
											<span style={{ paddingRight: "10px", display: hideToggleButtonText ? "none" : "inherit" }}>Tieyksiköt</span>
											<AutoAwesomeMotionIcon />
										</Stack>
									</Tooltip>
								</ToggleButton>
								<ToggleButton value="partners" fullWidth sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
									<Tooltip title="Osakkaat">
										<Stack direction="row" alignItems="center">
											<span style={{ paddingRight: "10px", display: hideToggleButtonText ? "none" : "inherit" }}>Osakkaat</span>
											<GroupsIcon />
										</Stack>
									</Tooltip>
								</ToggleButton>
								<ToggleButton value="properties" fullWidth sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
									<Tooltip title="Kiinteistöt">
										<Stack direction="row" alignItems="center">
											<span style={{ paddingRight: "10px", display: hideToggleButtonText ? "none" : "inherit" }}>Kiinteistöt</span>
											<HomeIcon />
										</Stack>
									</Tooltip>
								</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
					</Grid>
					<Grid item xs={9} sx={{ overflow: "hidden", justifyContent: "space-between", height: "100%" }}>
						<Box sx={{ height: "calc(100% - 80px)" }}>
							{
								{
									partners: allPartnersView,
									establishments: allEstablismhentsView,
									properties: allPropertiesView
								}[selectedView]
							}
						</Box>
						<Box
							sx={{
								textAlign: "center",
								height: "80px"
							}}
						>
							<Tooltip title="Lisää yksiköitä ja osakkaita">
								<div>
									<MutationFab
										variant="extended"
										color={"primary"}
										// size='medium'
										onClick={handlePopoverClick}
										sx={{ minWidth: "48px", width: "48px", marginBottom: "10px" }}
									>
										<AddIcon fontSize="large" />
									</MutationFab>
								</div>
							</Tooltip>
						</Box>
					</Grid>
				</Grid>
			</SwipeableDrawer>

			<Popover
				open={anchorEl ? true : false}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: "visible",
						bgcolor: "#e8e8e8",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: -1.5
					}
				}}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
			>
				<Box
					sx={{
						padding: "20px",
						maxWidth: "350px"
					}}
				>
					<Typography fullWith sx={{ marginTop: "-10px" }} textAlign="center" color="#D2D2D2">
						PERUSTYÖKALUT
					</Typography>
					<Button fullWidth sx={{ marginTop: "2px" }} variant="contained" startIcon={<PersonAddIcon />} onClick={() => setShowCreateUserForm(true)}>
						{" "}
						Lisää osakas
					</Button>
					<Button
						fullWidth
						sx={{ marginTop: "2px" }}
						variant="contained"
						startIcon={<AutoAwesomeMotionIcon />}
						onClick={() => setShowCreateEstablishmentForm(true)}
					>
						Lisää yksiköitä
					</Button>
					<Button
						fullWidth
						sx={{ marginTop: "2px" }}
						variant="contained"
						startIcon={<AddRoadIcon />}
						onClick={() => {
							setOpenCreateCostCenter(true)
						}}
					>
						Lisää tie
					</Button>
					<Typography fullWith sx={{ marginTop: "10px" }} textAlign="center" color="#D2D2D2">
						MASSATYÖKALUT
					</Typography>
					<Button
						fullWidth
						sx={{ marginTop: "2px" }}
						variant="contained"
						startIcon={<GroupAddIcon />}
						onClick={() => {
							setOpenBulkMemberDataImport(true)
						}}
					>
						Osakkaiden tuonti
					</Button>
					<Button
						fullWidth
						sx={{ marginTop: "2px" }}
						variant="contained"
						startIcon={<AutoAwesomeMotionIcon />}
						onClick={() => {
							setOpenBulkUnitDataImport(true)
						}}
					>
						Yksiköiden tuonti
					</Button>
				</Box>
			</Popover>

			<Dialog open={showCreateUserForm} fullWidth maxWidth="md">
				<DialogCloseButton closeFunction={() => setShowCreateUserForm(false)} />
				<CustomDialogTitle>Lisää osakas</CustomDialogTitle>

				<DialogContent>
					<CreateUserStateProvider>
						<CreateNewUserDialog
							refetchRCGeoJson={refetchRCGeoJson}
							pulseFunctionWhenDataCreated={pulseFunctionWhenDataCreated}
							setShowCreateUserForm={setShowCreateUserForm}
						/>
					</CreateUserStateProvider>
				</DialogContent>
				<CustomDialogActions>
					<Button
						color="cancel"
						onClick={() => setShowCreateUserForm(false)}
						variant="outlined"
						// sx={{ position: 'absolute', top: '10px', right: '10px' }}
					>
						Sulje
					</Button>
				</CustomDialogActions>
			</Dialog>

			<Dialog open={showCreateEstablishmentForm} fullWidth maxWidth="md">
				<DialogCloseButton closeFunction={() => setShowCreateEstablishmentForm(false)} />
				<CustomDialogTitle>Lisää yksiköitä</CustomDialogTitle>

				<DialogContent>
					<CreateUserStateProvider>
						<CreateEstablishmentDialog
							refetchRCGeoJson={refetchRCGeoJson}
							setShowCreateEstablishmentForm={setShowCreateEstablishmentForm}
							pulseFunctionWhenDataCreated={pulseFunctionWhenDataCreated}
						/>
					</CreateUserStateProvider>
				</DialogContent>
				<CustomDialogActions>
					<Button
						color="cancel"
						onClick={() => setShowCreateEstablishmentForm(false)}
						variant="outlined"
						// sx={{ position: 'absolute', top: '10px', right: '10px' }}
					>
						Sulje
					</Button>
				</CustomDialogActions>
			</Dialog>

			<Dialog open={openNotiDialog}>
				<DialogCloseButton closeFunction={handleCloseCreateCostCenter} />
				<DialogContent>Oletko varma että haluat poistua? Jos poistut, menetät juuri syöttämäsi tiedot.</DialogContent>
				<CustomDialogActions>
					<Button variant="outlined" color="cancel" onClick={() => setOpenNotiDialog(false)}>
						Peruuta
					</Button>
					<Button variant="contained" color="close" onClick={handleCloseCreateCostCenter}>
						Jatka
					</Button>
				</CustomDialogActions>
			</Dialog>

			<Dialog open={openCreateCostCenter} fullScreen sx={{ heigth: "auto", maxWidth: "none" }}>
				<DialogCloseButton closeFunction={handleCloseCreateCostCenter} />
				<CustomDialogTitle>Lisää tie</CustomDialogTitle>

				<DialogContent sx={{ heigth: "auto", maxWidth: "none" }}>
					<CreateCostCenterStateProvider>
						<CreateNewCostCenterModal refetchRCGeoJson={refetchRCGeoJson} handleCloseCreateCostCenter={handleCloseCreateCostCenter} />
					</CreateCostCenterStateProvider>
				</DialogContent>
			</Dialog>

			<Dialog open={openBulkMemberDataImport} fullScreen>
				<CustomDialogTitle>Osakkaiden massatuonti</CustomDialogTitle>
				<DialogCloseButton closeFunction={handleCloseMemberBulkImportTool} />
				<DialogContent
				// sx={{ overflow: 'hidden' }}
				>
					<BulkMemberImportTool
						setOpenBulkMemberDataImport={setOpenBulkMemberDataImport}
						setRoadCooperativeStatusValues={setRoadCooperativeStatusValues}
					/>
				</DialogContent>
				<CustomDialogActions>
					<Button color="cancel" variant="outlined" onClick={() => setOpenBulkMemberDataImport(false)}>
						Sulje
					</Button>
				</CustomDialogActions>
			</Dialog>

			<Dialog open={openBulkUnitDataImport} fullScreen>
				<DialogTitle sx={{ padding: "10px" }}>
					<Typography sx={{ fontWeight: "900" }}>Yksiköinti</Typography>
				</DialogTitle>
				<Divider></Divider>
				<DialogCloseButton closeFunction={handleCloseUnitBulkImportTool} />
				<DialogContent sx={{ padding: "10px" }}>
					{
						{
							professional: (
								<>
									<BulkUnitImportTool
										setOpenBulkUnitDataImport={setOpenBulkUnitDataImport}
										setRoadCooperativeStatusValues={setRoadCooperativeStatusValues}
									/>
								</>
							),
							easy: (
								<>
									<SimpleUnitsImport
										activeCostCenter={activeCostCenter}
										setOpenBulkUnitDataImport={setOpenBulkUnitDataImport}
										setRoadCooperativeStatusValues={setRoadCooperativeStatusValues}
									/>
								</>
							)
						}[unitsViewType ?? "professional"]
					}
				</DialogContent>
				<CustomDialogActions>
					{
						{
							professional: (
								<>
									<Button color="cancel" variant="outlined" onClick={() => setOpenBulkUnitDataImport(false)}>
										Sulje
									</Button>
								</>
							),
							easy: null
						}[unitsViewType ?? "professional"]
					}
				</CustomDialogActions>
			</Dialog>

			<Dialog open={openSelectUnitsViewType}>
				<CustomDialogTitle>Yksiköiden tyyppi valinta</CustomDialogTitle>
				<DialogCloseButton closeFunction={setOpenSelectUnitsViewType} functionParams={false} />
				<DialogContent>
					<CustomNotification severity="info">
						Voit vaihtaa yksiköintitapaa myöhemmin tiekunnan asetuksista. Jos valitset helpon tavan, ei ole suositeltavaa vaihtaa sitä myöhemmin
						ammattimaiseen tapaan.
					</CustomNotification>
					<Typography sx={{ fontWeight: "400" }}>
						{
							{
								professional: (
									<>
										Ammattimainen yksiköintitapa antaa sinulle mahdollisuuden luoda yksiköintiä tarkasti, käyttäen liikennelajeja,
										painolukuja, käyttömatkoja ja korjauskertoimia. Ammattimaisessa tavassa voit tuoda osakaslistauksen ja yksiköt helposti
										excel-tuotipohjaa käyttäen. Ammattimainen tapa on hyvä suuremmille tiekunnille tai tiekunnille jotka haluavat
										Maanmittauslaitosken suositusten mukaisen yksikkölaskelman ja ymmärtää liikennelajeja ja painolukuja.
									</>
								),
								easy: (
									<>
										Helpossa yksiköintitavassa voit vaivattomasti luoda yksiköinnin ilman suurenpaa ymmärrystä yksiköintiperusteista. Helppo
										tapa ei välttämättä tarjoa mahdollisuutta luoda Maanmittauslaitoksen suosituksien mukaista yksiköintilaskelmaa, mutta
										pienelle tiekunnalle se ei ole ongelma. Jos siis haluat luoda yksiköinnin ilman ymmärrystä liikennelajeista ja
										painoluvuista tai ei ole tarvetta määritellä tarkasti yksiköintiperusteita, valiste helppo.
									</>
								),
								undefined: <></>
							}[selectedUnitsViewType ?? "undefined"]
						}
					</Typography>

					<RadioGroup
						overlay
						sx={{
							flexDirection: "row",
							justifyContent: "space-evenly"
						}}
					>
						<Paper
							variant="outlined"
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: 1.5,
								p: 2,
								minWidth: 120
							}}
						>
							<Radio
								id={"Helppo"}
								value={"Helppo"}
								checked={selectedUnitsViewType == "easy"}
								onClick={() => handleSelectViewType("easy")}
								checkedIcon={<CheckCircleRoundedIcon />}
							/>
							<Typography>{"Helppo"}</Typography>
						</Paper>
						<Paper
							variant="outlined"
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: 1.5,
								p: 2,
								minWidth: 120
							}}
						>
							<Radio
								checked={selectedUnitsViewType == "professional"}
								id={"Ammattimainen"}
								value={"Ammattimainen"}
								onClick={() => handleSelectViewType("professional")}
								checkedIcon={<CheckCircleRoundedIcon />}
							/>
							<Typography>{"Ammattimainen"}</Typography>
						</Paper>
					</RadioGroup>
				</DialogContent>
				<CustomDialogActions>
					<Button color="cancel" variant="outlined" onClick={() => setOpenSelectUnitsViewType(false)}>
						Sulje
					</Button>
					<Button variant="contained" onClick={() => handleUnitsViewTypeSelectSave()}>
						Tallenna
					</Button>
				</CustomDialogActions>
			</Dialog>
		</>
	)
}

export default HomePageDrawer
