import { Divider } from "@mui/material"
import { DialogTitle } from "@mui/material"
import React from "react"

const CustomDialogTitle = ({ children }: { children?: React.ReactChild | any }) => {
	return (
		<>
			<DialogTitle sx={{ padding: "15px", paddingRight: "45px", paddingLeft: "45px", fontWeight: "900", paddingTop: "0px", marginTop: "32px" }}>
				{children}
			</DialogTitle>
			<Divider></Divider>
		</>
	)
}

export default CustomDialogTitle
