import { keyframes } from "@emotion/react"

export const pulseAnimation = keyframes`
	0% {
		transform: scale(1);
		}
		25% {
			transform: scale(1.08);
		}
		50% {
			transform: scale(1.08);
		}
		75% {
			transform: scale(1.08);
		}
		100% {
			transform: scale(1);
		}
		`

export const pulseAnimationSmall = keyframes`
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(1.04);
	}
	50% {
		transform: scale(1.04);
	}
	75% {
		transform: scale(1.04);
	}
	100% {
		transform: scale(1);
	}
	`
