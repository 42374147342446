import { convertToStandard } from "./coordinateConverter"

export const checkVaylaResponseDataType = (response: any) => {
	let line: Array<Array<number>> = []

	try {
		if (response.features[0].geometry.type == "LineString") {
			response.features[0].geometry.coordinates.map((c: Array<number>) => {
				const value = convertToStandard(c[0], c[1])
				line = line.concat([[value[1], value[0]]])
			})
		}
		if (response.features[0].geometry.type == "MultiLineString") {
			const coordinates = response.features[0].geometry.coordinates
			coordinates.map((c1: Array<Array<number>>, i: number) => {
				if (i != 0) {
					if (i == 1) {
						switch (findMinDistance(coordinates[0], c1, true)) {
							case 0:
								break
							case 1:
								c1 = c1.reverse()
								break
							case 2:
								line = line.reverse()
								break
							case 3:
								c1 = c1.reverse()
								line = line.reverse()
								break
							default:
								console.log("NYT MENI JOKU PIELEEN :D")
								break
						}
					} else {
						if (findMinDistance(coordinates[i - 1], c1) == 1) {
							c1 = c1.reverse()
						}
					}
				}

				c1.map((c2: Array<number>) => {
					const value = convertToStandard(c2[0], c2[1])
					line = line.concat([[value[1], value[0]]])
				})
			})
		}
	} catch (error) {
		console.error(error)
		return "virhe"
	}

	return line
}

const findMinDistance = (previous: Array<Array<number>>, current: Array<Array<number>>, isFirst = false) => {
	const distanceCalc = (a: Array<number>, b: Array<number>) => {
		return Math.sqrt((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2)
	}

	const distancesArray = []
	distancesArray.push(distanceCalc(previous[previous.length - 1], current[0])) // End of previous, start of current
	distancesArray.push(distanceCalc(previous[previous.length - 1], current[current.length - 1])) // End of previous, end of current

	if (isFirst) {
		distancesArray.push(distanceCalc(previous[0], current[0])) // Start of previous, start of current
		distancesArray.push(distanceCalc(previous[0], current[current.length - 1])) // Start of previous, end of current
	}

	let min = distancesArray[0]
	let minIndex = 0
	for (let i = 0; i < distancesArray.length; i++) {
		if (distancesArray[i] < min) {
			min = distancesArray[i]
			minIndex = i
		}
	}

	return minIndex
}
