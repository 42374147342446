import { FeatureCollection, Position } from "geojson"
import waitUntilMapLoaded from "./waitUntilMapLoaded"

const selectedRoadLinksValues = (map: any, selectedLinks: any) => {
	if (selectedLinks === undefined) return

	const func = (map: any) => {
		if (!map) return

		const geoJsonData: FeatureCollection = {
			type: "FeatureCollection",
			features: [
				{
					properties: {},
					type: "Feature",
					geometry: {
						type: "MultiLineString",
						coordinates: selectedLinks.map((l: { coordinates: Position }) => {
							return l.coordinates
						})
					}
				}
			]
		}

		if (map.current.loaded()) {
			if (map.current.getLayer("clickRoadLink") ? true : false) {
				map.current.removeLayer("clickRoadLink")
			}

			if (map.current.getSource("clickRoadLink")) {
				map.current.removeSource("clickRoadLink")
			}

			map.current.addSource("clickRoadLink", {
				type: "geojson",
				data: geoJsonData
			})

			map.current.addLayer({
				id: "clickRoadLink",
				type: "line",
				source: "clickRoadLink",
				layout: {
					"line-join": "round",
					"line-cap": "round"
				},
				paint: {
					"line-color": "#505050",
					"line-width": 5
				}
			})
		}
	}

	waitUntilMapLoaded(map, func, map)
}

export default selectedRoadLinksValues
