import { Grid } from "@mui/material"
import React, { Dispatch } from "react"
import Chats from "./Chats"
import NewChat from "./NewChat"

import { ChatProps, RoadCooperativeInfo } from "../Map/ChatPopUp"

interface ComponentProps {
	profileInfo: any
	rcInfo: RoadCooperativeInfo
	refetchChatData: () => void
	chats: ChatProps[]
	setChats: Dispatch<React.SetStateAction<ChatProps[]>>
	setLockChat: Dispatch<React.SetStateAction<boolean>>
	lockChat: boolean
	toggleChatPopUp: () => void
}

const ChatPage: React.FC<ComponentProps> = ({ profileInfo, rcInfo, setChats, chats, refetchChatData, setLockChat, lockChat, toggleChatPopUp }) => {
	return (
		<Grid container spacing={2} sx={{ height: "100%", maxHeight: "100%" }}>
			<Grid id="newChatGrid" item xs={12} sx={{}}>
				<NewChat
					profileInfo={profileInfo}
					roadCooperativeInfo={rcInfo}
					setChats={setChats}
					chats={chats}
					refetch={refetchChatData}
					setLockChat={setLockChat}
					lockChat={lockChat}
					toggleChatPopUp={toggleChatPopUp}
				></NewChat>
			</Grid>
			<Grid item xs={12} sx={{ height: "calc(100% - 240px)", overflowY: "auto", margin: "10px" }}>
				<Chats chatData={chats} refetch={refetchChatData} profileInfo={profileInfo} setChats={setChats} />
			</Grid>
		</Grid>
	)
}

export default ChatPage
