import React, { ReactElement, useEffect, useState } from "react"
import {
	Paper,
	MenuItem,
	FormControl,
	Radio,
	IconButton,
	Box,
	Button,
	Stack,
	ToggleButtonGroup,
	Alert,
	TextField,
	Typography,
	ToggleButton,
	Grid,
	FormLabel,
	FormControlLabel,
	RadioGroup,
	useMediaQuery,
	Tooltip,
	Fab
} from "@mui/material"
import BusinessIcon from "@mui/icons-material/Business"
import PersonIcon from "@mui/icons-material/Person"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import CheckIcon from "@mui/icons-material/Check"
import { ReactComponent as InfoIcon } from "../../resources/icons/info.svg"
import LogoutIcon from "@mui/icons-material/Logout"

import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"
import { getAllCountries } from "../../network/dataServices/generalDataService"
import { forceLogoutUser, logoutUser, selectRoadCooperative } from "../../network/dataServices/userDataService"
import { createRoadCooperative } from "../../network/dataServices/roadCooperativeDataService"
import customTheme from "../../utils/customTheme/muiTheme"
import MunicipalitySelect from "../reusables/MunicipalityList"
import { CircularProgress } from "@mui/material"
import { Backdrop } from "@mui/material"
import LinkButton from "../reusables/LinkButton"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { toast } from "react-toastify"
import IconLinkButton from "../reusables/IconLinkButton"

// TEE TSEKKERI MIKÄ TARKISTAA ETTÄ EI OLE KÄYTTÖOIKEUSYKSIKKÖTUNNUS KÄYTÖSSÄ JA JOS ON NIIN LÄHETTÄÄ SPOSTIN JA HERJAA JA JOS EI OLE NIIN "TULOSSA"
const CreateRoadCooperative = ({
	creatingNew = false,
	setCreatingNew
}: {
	creatingNew?: boolean
	setCreatingNew?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
	const [roadCooperative, setRoadCooperative] = useState<any>({
		RCName: "",
		RCBusinessId: "",
		RCPermissionUnit: "",
		RCRole: "",
		memberType: "yksityishenkilö",
		invoiceSendingType: "",
		meetingInviteType: "",
		name: "",
		email: "",
		companyName: "",
		address: "",
		postalDistrict: "",
		postalCode: "",
		countryCode: "FI",
		languageCode: "fi",
		telNumber: "",
		eInvoiceAddress: "",
		eInvoiceOperator: "",
		businessId: "",
		municipality: ""
	})
	const [allCountries, setAllCountries] = useState<Country[]>([])
	const [alert, setAlert] = useState("")
	const [RCalert, setRCAlert] = useState("")
	const [userProfile, setUserProfile] = useState<any>({ name: "", email: "" })
	const [step, setStep] = useState<"rcInfo" | "personInfo">("rcInfo")
	const [loading, setLoading] = useState(false)
	const user = userProfileStorage({ type: "getUserProfile" })
	const [disabled, setDisabled] = useState(false)

	const largeScreen = useMediaQuery(customTheme.customTheme.breakpoints.up("xl"))
	const xsScreen = useMediaQuery(customTheme.customTheme.breakpoints.up("xs"))

	useEffect(() => {
		getAllCountries().then((data) => {
			if (data) setAllCountries(data)
		})
		setUserProfile(user)
	}, [])

	const handleBackClick = () => {
		if (setCreatingNew) setCreatingNew(false)
	}

	const handleCreateRoadCooperative = async () => {
		if (!checkAllMemberParameters()) {
			setLoading(false)
			return
		}

		setLoading(true)
		const roadCooperativeObj: any = {
			name: roadCooperative.RCName,
			businessId: roadCooperative.RCBusinessId,
			permissionUnitId: roadCooperative.RCPermissionUnit,
			municipality: roadCooperative.municipality
		}
		const roadCooperativeMemberObj = {
			name: roadCooperative.name,
			userEmail: userProfile.email
		}
		const roadCooperativeUserBillingInformationObj = {
			name: roadCooperative.name,
			email: userProfile.email,
			billingAddress: roadCooperative.address,
			countryCode: roadCooperative.countryCode,
			languageCode: roadCooperative.languageCode,
			meetingInviteType: parseInt(roadCooperative.meetingInviteType),
			phoneNumber: roadCooperative.telNumber,
			postalDistrict: roadCooperative.postalDistrict,
			postcode: roadCooperative.postalCode,
			invoiceSendingType: parseInt(roadCooperative.invoiceSendingType),
			contactPerson: roadCooperative.contactPerson,
			eInvoiceAddress: roadCooperative.eInvoiceAddress ?? null,
			eInvoiceOperator: roadCooperative.eInvoiceOperator ?? null,
			businessId: roadCooperative.businessId ?? null
		}

		//     createRoadCooperativeForMember({ variables: {
		//         roadCooperative: roadCooperativeObj,
		//         roadCooperativeMember: roadCooperativeMemberObj,
		//         roadCooperativeUserBillingInformation: roadCooperativeUserBillingInformationObj }})
		// }
		const requestBody = {
			type: roadCooperative.RCRole,
			roadCooperative: roadCooperativeObj,
			roadCooperativeMember: roadCooperativeMemberObj,
			roadCooperativeUserBillingInformation: roadCooperativeUserBillingInformationObj
		}

		const data = await createRoadCooperative(requestBody)
		if (data?.response?.status === 409) {
			toast.error(
				'Käyttöoikeusyksikkötunnus on jo varattu. Voit ohittaa tämän vaiheen kirjoittamalla "odottaa" tai "ei tiedossa" tekstikenttään. Mikäli tarvitset apua, ole meihin yhteydessä tuki-chatin kautta.',
				{ autoClose: false }
			)
			setDisabled(true)
		} else {
			selectRoadCooperative(roadCooperative?.RCName, data?.data?.createRoadCooperativeForMember?.id)
			setRoadCooperative("")
			window.location.reload()
		}
		setLoading(false)
	}

	const handleChangeMemberType = (v: string | null) => {
		if (!v) {
			return
		} else {
			setRoadCooperative({ ...roadCooperative, memberType: v })
		}
	}

	const checkRCParameters = () => {
		let alertText = ""
		if (roadCooperative.RCName == "") {
			alertText = alertText + "Tiekunnan nimi puuttuu. "
		}
		if (roadCooperative.RCRole == "") {
			alertText = alertText + "Roolisi tiekunnassa puuttuu. "
		}
		if (roadCooperative.municipality == "") {
			alertText = alertText + "Tiekunnan kunta tieto puuttuu. "
		}
		if (roadCooperative.RCPermissionUnit == "") {
			alertText =
				alertText +
				'Tiekunnan käyttöoikeusyksikkötunnus puuttuu. Mikäli käyttöoikeusyksikkötunnus ei ole saatavilla kirjoita kenttään "odottaa" tai "ei tiedossa" tekstikenttään.'
		}

		if (alertText != "") {
			setRCAlert(alertText)
			setTimeout(() => {
				setRCAlert("")
			}, 10000)
			return false
		}
		return true
	}

	const handleNextClick = () => {
		if (checkRCParameters()) setStep("personInfo")
	}

	const checkAllMemberParameters = () => {
		let alertText = ""
		if (roadCooperative.memberType === "yksityishenkilö") {
			if (roadCooperative.name == "") {
				alertText = alertText + "Nimi puuttuu. "
			}
		} else {
			if (roadCooperative.name == "") {
				alertText = alertText + "Yrityksen nimi puuttuu. "
			}
			if (roadCooperative.businessId == "") {
				alertText = alertText + "Y-tunnus puuttuu. "
			}
			if (roadCooperative.invoiceSendingType === 3) {
				if (roadCooperative.eInvoiceAddress == "") {
					alertText = alertText + "Verkkolaskuosoite puuttuu. "
				}
				if (roadCooperative.eInvoiceOperator == "") {
					alertText = alertText + "Operaattoritunnus puuttuu. "
				}
			}
		}
		if (roadCooperative.address == "") {
			alertText = alertText + "Postiosoite puuttuu. "
		}
		if (roadCooperative.postalDistrict == "") {
			alertText = alertText + "Postitoimipaikka puuttuu. "
		}
		if (roadCooperative.postalCode == "") {
			alertText = alertText + "Postinumero puttuu. "
		}
		if (roadCooperative.countryCode == "") {
			alertText = alertText + "Maa puuttuu. "
		}
		// if (roadCooperative.languageCode == "") {
		// 	alertText = alertText + "Kieli puuttuu. "
		// }
		if (roadCooperative.invoiceSendingType == "") {
			alertText = alertText + "Laskun toimitustapa puuttuu. "
		}
		if (roadCooperative.meetingInviteType == "") {
			alertText = alertText + "Kokouskutsun toimitustapa puuttuu. "
		}
		if (alertText != "") {
			setAlert(alertText)
			setTimeout(() => {
				setAlert("")
			}, 10000)
			return false
		}
		return true
	}

	const handleInfoClick = () => {
		const url = "https://storymaps.arcgis.com/stories/04cefb6993254e5ab4fdec5bc0f129cb"
		const link = document.createElement("a")
		link.href = url
		;(link.rel = "noreferrer"), (link.target = "_blank"), link.click()
	}

	return (
		<Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
			{loading && (
				<>
					<Backdrop
						sx={{ zIndex: 10000 }}
						open={loading}
						//   onClick={handleClose}
					>
						<Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
							<CircularProgress />
							<Typography sx={{ color: "#fff" }}>Luodaan tiekuntaa...</Typography>
						</Stack>
					</Backdrop>
				</>
			)}
			<Tooltip title="Kirjaudu ulos">
				<Fab
					sx={{ position: "fixed", top: screen.width > 1120 ? "10px" : "60px", right: "15px", zIndex: 2000 }}
					color="primary"
					size="small"
					onClick={logoutUser}
				>
					<LogoutIcon />
				</Fab>
			</Tooltip>
			<Paper
				sx={{
					justifyContent: "center",
					maxHeight: largeScreen ? "740px" : xsScreen ? "71vh" : "63vh",
					overflow: "hidden",
					overflowY: "auto",
					padding: "20px",
					textAlign: "center",
					display: "flex",
					width: step == "rcInfo" ? "630px" : largeScreen ? "630px" : "950px"
				}}
				elevation={10}
			>
				<Grid container sx={{ maxWidth: step == "rcInfo" ? "500px" : "900px" }} spacing={1}>
					<Grid item xs={12} sx={{ height: "50px" }}>
						<Box sx={{}}>
							<Typography sx={{ fontSize: "2rem" }}>{step === "rcInfo" ? "Lisää tiekunta" : "Omat tiedot"}</Typography>
						</Box>
					</Grid>
					{step == "rcInfo" ? (
						<>
							<Grid item xs={12}>
								<TextField
									required
									value={roadCooperative.RCName}
									onChange={(e: { target: { value: string } }) => setRoadCooperative({ ...roadCooperative, RCName: e.target.value })}
									label="Tiekunnan nimi"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} container direction="row">
								<Grid item xs={11}>
									<TextField
										value={roadCooperative.RCBusinessId}
										// onChange={(e: { target: { value: string } }) => setRoadCooperative({ ...roadCooperative, RCBusinessId: e.target.value })}
										onChange={(e: { target: { value: string } }) => {
											const newValue = e.target.value
											if (newValue.length <= 9) {
												setRoadCooperative({ ...roadCooperative, RCBusinessId: newValue })
											}
										}}
										label="Y-tunnus"
										fullWidth
									/>
								</Grid>
								<Grid item xs={1} sx={{ paddingTop: "12px" }}>
									<IconLinkButton
										url="https://tietopalvelu.ytj.fi/"
										title={
											<Typography sx={{ fontSize: "0.7rem", marginX: "2px", color: "white", fontWeight: "light" }}>
												Y-tunnus voi olla ainoastaan 9 merkkiä pitkä. Tarkista tarvittaessa oma y-tunnuksesi YTJ:n sivuilta
												klikkaamalla.
											</Typography>
										}
									></IconLinkButton>
								</Grid>
							</Grid>
							<Grid item container xs={12} alignItems="center" justifyContent="center">
								<Grid item xs={10}>
									<TextField
										required
										value={roadCooperative.RCPermissionUnit}
										onChange={(e: { target: { value: string } }) => {
											setRoadCooperative({ ...roadCooperative, RCPermissionUnit: e.target.value })
											setDisabled(false)
										}}
										label="Käyttöoikeusyksikkötunnus"
										fullWidth
									/>
								</Grid>
								<Grid item xs={2}>
									<Tooltip
										// sx={{ position: "absolute", marginTop: "12px", marginLeft: "500px" }}

										title='Tiekunnan yksilöivä tunnus. Mikäli tämä tieto ei ole saatavilla kirjoita tekstikenttään "odottaa" tai "ei tiedossa" '
									>
										<IconButton color="info">
											<InfoIcon />
										</IconButton>
									</Tooltip>
									<Tooltip title="Klikkaamalla pääset Metsäkeskuksen Tiekuntakarttaan, josta voit hakea oman tiekuntasi käyttöoikeusyksikkötunnuksen.">
										<IconButton onClick={handleInfoClick} sx={{ color: "#34aadc" }}>
											<OpenInNewIcon />
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<FormControl fullWidth sx={{ alignItems: "center", marginLeft: "4px" }}>
									<TextField
										label="Roolini tiekunnassa *"
										value={roadCooperative.RCRole}
										select
										fullWidth
										variant="outlined"
										onChange={(e: { target: { value: string } }) => setRoadCooperative({ ...roadCooperative, RCRole: e.target.value })}
									>
										<MenuItem value={"partner"}>Osakas</MenuItem>
										<MenuItem value={"externalOperator"}>Ulkopuolinen toimija</MenuItem>
									</TextField>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<MunicipalitySelect
									initialValue={roadCooperative.municipality}
									onMunicipalityChange={(value: any) => setRoadCooperative({ ...roadCooperative, municipality: value })}
									variant="outlined"
								/>
							</Grid>
							{RCalert ? (
								<Grid item xs={12} sx={{ height: "80px", marginTop: "10px" }}>
									<Alert sx={{ wordWrap: "break-word" }} severity="error">
										{RCalert}
									</Alert>
								</Grid>
							) : (
								<Grid item xs={12} sx={{ height: "80px", marginTop: "10px" }}>
									<LinkButton
										title="Pyydä tarjous tiekunnan perustamisesta"
										url="https://www.tievahti.fi/palvelut/tiekunnan-perustaminen/"
									></LinkButton>
								</Grid>
							)}
							<Grid item container xs={12} spacing={1} sx={{ paddingBottom: xsScreen ? "40px" : 0 }}>
								<Grid item xs={6} sx={{ textAlign: "left" }}>
									{creatingNew ? (
										<Button startIcon={<ArrowBackIcon />} fullWidth variant="outlined" color="cancel" onClick={handleBackClick}>
											Takaisin
										</Button>
									) : null}
								</Grid>
								<Grid item xs={6} sx={{ textAlign: "right" }}>
									<Button variant="contained" fullWidth endIcon={<ArrowForwardIcon />} onClick={handleNextClick}>
										Seuraava
									</Button>
								</Grid>
							</Grid>
						</>
					) : (
						<Grid item container xs={12} columnSpacing={4} rowSpacing={1}>
							<Grid item xs={12}>
								<ToggleButtonGroup
									value={roadCooperative.memberType}
									color="primary"
									onChange={(e: ReactElement, v: string | null) => handleChangeMemberType(v)}
									exclusive
									size="large"
									aria-label="recipient type"
								>
									<ToggleButton value="yritys" fullWidth sx={{ alignItems: "space-between", maxWidth: 300 }}>
										Yritys
										<BusinessIcon sx={{ marginLeft: 2, fontSize: 32 }} />
									</ToggleButton>
									<ToggleButton value="yksityishenkilö" fullWidth sx={{ alignItems: "space-between", maxWidth: 300 }}>
										<PersonIcon sx={{ marginRight: 2, fontSize: 32 }} />
										Henkilö
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
							<Grid item xs={12} sm={6} xl={12}>
								<Grid item xs={12}>
									{roadCooperative.memberType === "yritys" ? (
										<OrganisationUserInfo
											allCountries={allCountries}
											roadCooperative={roadCooperative}
											setRoadCooperative={setRoadCooperative}
											userProfile={userProfile}
										/>
									) : (
										<PersonUserInfo
											allCountries={allCountries}
											userProfile={userProfile}
											roadCooperative={roadCooperative}
											setRoadCooperative={setRoadCooperative}
										/>
									)}
								</Grid>
							</Grid>
							<Grid item xs={12} sm={6} xl={12} sx={{ alignSelf: "center" }}>
								<Grid item xs={12}>
									<FormLabel>Laskun toimitustapa *</FormLabel>
									<RadioGroup
										row
										onChange={(e: any) => setRoadCooperative({ ...roadCooperative, invoiceSendingType: e.target.value })}
										name="row-radio-buttons-group"
										sx={{ justifyContent: "center" }}
									>
										<FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Kirje</Typography>} />
										<FormControlLabel
											value={2}
											control={<Radio />}
											label={<Typography sx={{ fontWeight: "500" }}>Sähköposti</Typography>}
										/>
										<FormControlLabel
											value={3}
											control={<Radio />}
											disabled={roadCooperative.memberType === "yksityishenkilö"}
											label={<Typography sx={{ fontWeight: "500" }}>Verkkolasku</Typography>}
										/>
									</RadioGroup>
									{roadCooperative.invoiceSendingType == 3 && roadCooperative.memberType === "yritys" ? (
										<Stack
											direction="row"
											alignItems="center"
											alignContent="space-between"
											justifyContent="center"
											sx={{ width: "100%", marginBottom: "10px" }}
										>
											<TextField
												label="Verkkolaskuosoite"
												variant="outlined"
												fullWidth
												onChange={(e: any) => setRoadCooperative({ ...roadCooperative, eInvoiceAddress: e.target.value })}
												value={roadCooperative.eInvoiceAddress}
												required
												sx={{ marginRight: "8px" }}
											/>
											<TextField
												label="Operaattoritunnus"
												variant="outlined"
												fullWidth
												onChange={(e: any) => setRoadCooperative({ ...roadCooperative, eInvoiceOperator: e.target.value })}
												value={roadCooperative.eInvoiceOperator}
												required
												sx={{ marginLeft: "8px" }}
											/>
										</Stack>
									) : null}
								</Grid>
								<Grid item xs={12}>
									<FormLabel>Kokouskutsun toimitustapa *</FormLabel>
									<RadioGroup
										row
										name="row-radio-buttons-group"
										onChange={(e: any) => setRoadCooperative({ ...roadCooperative, meetingInviteType: e.target.value })}
										sx={{ justifyContent: "center" }}
									>
										<FormControlLabel value={1} control={<Radio />} label={<Typography sx={{ fontWeight: "500" }}>Kirje</Typography>} />
										<FormControlLabel
											value={2}
											control={<Radio />}
											label={<Typography sx={{ fontWeight: "500" }}>Sähköposti</Typography>}
										/>
									</RadioGroup>{" "}
									<br />
								</Grid>
								<Grid item xs={12} sx={{ justifyContent: "center", display: "flex", height: "88px" }}>
									{alert ? (
										<Alert sx={{ wordWrap: "break-word" }} severity="error">
											{alert}
										</Alert>
									) : null}
								</Grid>
							</Grid>
							<Grid item container xs={12} spacing={1} sx={{ paddingBottom: xsScreen ? "40px" : 0 }}>
								<Grid item xs={6} sx={{ textAlign: "left" }}>
									<Button fullWidth variant="outlined" color="cancel" startIcon={<ArrowBackIcon />} onClick={() => setStep("rcInfo")}>
										Takaisin
									</Button>
								</Grid>
								<Grid item xs={6} sx={{ textAlign: "right" }}>
									<Button disabled={disabled} fullWidth variant="contained" endIcon={<CheckIcon />} onClick={handleCreateRoadCooperative}>
										Luo tiekunta
									</Button>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Paper>
		</Box>
	)
}

const OrganisationUserInfo = ({ allCountries, roadCooperative, setRoadCooperative, userProfile }: any) => {
	return (
		<Grid item container xs={12} columnSpacing={2} rowSpacing={1} sx={{ justifyContent: "center" }}>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Yrityksen nimi"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, name: e.target.value })}
					value={roadCooperative.name}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Y-tunnus"
					multiline
					fullWidth
					maxLength={9}
					variant="outlined"
					// onChange={(e: any) => setRoadCooperative({ ...roadCooperative, businessId: e.target.value })}
					onChange={(e: any) => {
						const newValue = e.target.value
						if (newValue.length <= 9) {
							setRoadCooperative({ ...roadCooperative, businessId: newValue })
						}
					}}
					value={roadCooperative.businessId}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Puhelinnumero"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, telNumber: e.target.value })}
					value={roadCooperative.telNumber}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Postiosoite"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, address: e.target.value })}
					value={roadCooperative.address}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Postinumero"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, postalCode: e.target.value })}
					value={roadCooperative.postalCode}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Postitoimipaikka"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, postalDistrict: e.target.value })}
					value={roadCooperative.postalDistrict}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Yhteyshenkilö"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, contactPerson: e.target.value })}
					value={roadCooperative.contactPerson}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Maa"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, countryCode: e.target.value })}
					value={roadCooperative.countryCode}
					variant="outlined"
					select
					fullWidth
					required
				>
					{allCountries.map((country: Country) => {
						return (
							<MenuItem key={country.countryCode.toString()} value={country.countryCode}>
								{country.country} - {country.countryCode}
							</MenuItem>
						)
					})}
				</TextField>
			</Grid>
			{/* <Grid item xs={12} sm={6} sx={{ textAlign: "center" }}>
				<TextField
					label="Kieli"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, languageCode: e.target.value })}
					value={roadCooperative.languageCode}
					variant="outlined"
					select
					fullWidth
					required
				>
					<MenuItem value={"fi"}>Suomi</MenuItem>
					<MenuItem value={"sv"}>Ruotsi</MenuItem>
					<MenuItem value={"en"}>Englanti</MenuItem>
				</TextField>
			</Grid> */}
		</Grid>
	)
}

const PersonUserInfo = ({ allCountries, roadCooperative, setRoadCooperative, userProfile }: any) => {
	return (
		<Grid item container xs={12} columnSpacing={2} rowSpacing={1} sx={{ justifyContent: "center" }}>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Nimi"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, name: e.target.value })}
					value={roadCooperative.name}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Puhelinnumero"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, telNumber: e.target.value })}
					value={roadCooperative.telNumber}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Postiosoite"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, address: e.target.value })}
					value={roadCooperative.address}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Postinumero"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, postalCode: e.target.value })}
					value={roadCooperative.postalCode}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Postitoimipaikka"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, postalDistrict: e.target.value })}
					value={roadCooperative.postalDistrict}
					required
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Yhteyshenkilö"
					multiline
					fullWidth
					variant="outlined"
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, contactPerson: e.target.value })}
					value={roadCooperative.contactPerson}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Maa"
					fullWidth
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, countryCode: e.target.value })}
					value={roadCooperative.countryCode}
					variant="outlined"
					select
					required
				>
					{allCountries.map((country: Country) => {
						return (
							<MenuItem key={country.countryCode.toString()} value={country.countryCode}>
								{country.country} - {country.countryCode}
							</MenuItem>
						)
					})}
				</TextField>
			</Grid>
			{/* <Grid item xs={12} sm={6}>
				<TextField
					label="Kieli"
					fullWidth
					onChange={(e: any) => setRoadCooperative({ ...roadCooperative, languageCode: e.target.value })}
					value={roadCooperative.languageCode}
					variant="outlined"
					select
					required
				>
					<MenuItem value={"fi"}>Suomi</MenuItem>
					<MenuItem value={"sv"}>Ruotsi</MenuItem>
					<MenuItem value={"en"}>Englanti</MenuItem>
				</TextField>
			</Grid> */}
		</Grid>
	)
}

export default CreateRoadCooperative
