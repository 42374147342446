import React from "react"
import { Box, Table, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect, useState } from "react"

import { ApolloQueryResult, gql, OperationVariables, useQuery } from "@apollo/client"
import { TableBody, TableContainer, TableSortLabel } from "@mui/material"
import PartnersTableRow from "./PartnersTableRow"
import { CircularProgress } from "@mui/material"
import { Stack } from "@mui/material"
import { TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { Button } from "@mui/material"

import AddIcon from "@mui/icons-material/Add"
import { MutationButton } from "../../../../reusables/MutationButtonChipFab"

const GET_ROAD_COOPERATIVE_MEMBERS = gql`
	query GetRoadCooperativeMembers {
		roadCooperativeWithJWT {
			roadCooperativeMembersByRoadCooperativeId(orderBy: NAME_ASC) {
				nodes {
					name
					id
					userEmail
					establishmentsByRoadCooperativeMemberId {
						nodes {
							costCenterByCostCenterId {
								idColor
							}
							additionalInformation
							amount
							discretionalyCorrectionMultiplier
							distanceCorrectionMultiplier
							costCenterId
							lateralDirection
							lateralDirectionCorrectionMultiplier
							operatingDistance
							roadUnits
							roadUsageUnitId
							weightCorrectionMultiplier
							roadUsageUnitByRoadUsageUnitId {
								id
								roadUsageUnit
								roadUsageUnitType
								weight
							}
							costCenterByCostCenterId {
								id
							}
							propertyByPropertyId {
								costCenterByCostCenterId {
									idColor
								}
								costCenterId
								mmlPropertyId
								plotId
								propertyName
								id
							}
							roadCooperativeMemberByRoadCooperativeMemberId {
								id
								name
								userEmail
							}
							id
						}
					}
				}
			}
		}
	}
`

const GET_UPDATE_UNITS_VIEW_TYPE = gql`
	query GetUnitsViewType {
		roadCooperativeWithJWT {
			id
			roadCooperativeSettingsByRoadCooperativeId {
				nodes {
					id
					unitsPresentationType
				}
			}
		}
	}
`

const reduceCCMembers = (allPartners: PartnerFromDB[], activeCC: number | string | null): Partner[] | undefined => {
	if (allPartners == null) return
	const partnersList = allPartners.reduce((acc: Partner[], member: any) => {
		if (member.establishmentsByRoadCooperativeMemberId.nodes.length == 0) return acc
		const ccEstablishments = member.establishmentsByRoadCooperativeMemberId.nodes.filter((est: Establishment) => est.costCenterId == activeCC)
		const allCostCenters = member.establishmentsByRoadCooperativeMemberId.nodes.map((est: Establishment) => est.costCenterByCostCenterId.idColor)
		if (ccEstablishments.length == 0) return acc
		const newPartner = {
			...member,
			allCostCenters: allCostCenters,
			establishments: ccEstablishments
		}

		return [...acc, newPartner]
	}, [])

	return partnersList
}

const reduceRCMembers = (allPartners: PartnerFromDB[]): Partner[] | undefined => {
	if (allPartners == null) return

	const partnersList = allPartners.reduce((acc: Partner[], member: any) => {
		const allCostCenters = member.establishmentsByRoadCooperativeMemberId.nodes.map((est: Establishment) => est.costCenterByCostCenterId.idColor)
		if (member.establishmentsByRoadCooperativeMemberId?.nodes.length == 0) {
			const newPartner = {
				...member,
				allCostCenters: allCostCenters,
				establishments: []
			}
			return [...acc, newPartner]
		} else {
			const newPartner = {
				...member,
				allCostCenters: allCostCenters,
				establishments: member.establishmentsByRoadCooperativeMemberId?.nodes
			}
			return [...acc, newPartner]
		}
	}, [])

	return partnersList
}

type PartnerFromDB = {
	establishmentsByRoadCooperativeMemberId: { nodes: Establishment[] }
	name: string
	id: number
	userEmail: string
}

type Partner = {
	name: string
	id: number
	userEmail: string
	allCostCenters: CostCenter[]
	establishments: Establishment[]
}
////
type PartnerListItem = {
	name: string
	id: number
	userEmail: string
	allCostCenters: CostCenter[]
	establishments: Establishment[]
}
////

type TableFilterEnumType = "asc" | "desc"

const PartnersTable = ({
	activeCostCenterColor,
	activeCostCenterId,
	wholeRoadCooperative,
	handlePopoverClick,
	dataChange
}: {
	activeCostCenterId: number | null
	wholeRoadCooperative: boolean
	activeCostCenterColor?: string | undefined
	handlePopoverClick: any
	dataChange: boolean
}) => {
	const [noPartnerData, setNoPartnerData] = useState(false)

	const [allPartners, setAllPartners] = useState<any[]>([])
	const [selectedPartners, setSelectedPartners] = useState<Partner[]>([])

	const [unitDirection, setUnitDirection] = useState<TableFilterEnumType>("asc")
	const [memberDirection, setMemberDirection] = useState<TableFilterEnumType>("desc")
	const [filteredPartnersList, setFilteredPartnersList] = useState<any[]>([])
	const [unitsViewType, setUnitsViewType] = useState<UnitsViewType>("professional")
	const [searchQuery, setSearchQuery] = useState<string>("")

	useQuery(GET_UPDATE_UNITS_VIEW_TYPE, {
		onCompleted(data) {
			setUnitsViewType(data.roadCooperativeWithJWT.roadCooperativeSettingsByRoadCooperativeId.nodes[0].unitsPresentationType)
		}
	})

	const { data, refetch } = useQuery(GET_ROAD_COOPERATIVE_MEMBERS)

	useEffect(() => {
		refetch()
	}, [dataChange])

	useEffect(() => {
		if (!data) return
		if (data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.nodes.length == 0) {
			setNoPartnerData(true)
		}
		setAllPartners(data.roadCooperativeWithJWT.roadCooperativeMembersByRoadCooperativeId.nodes)
	}, [data])

	useEffect(() => {
		if (allPartners)
			if (wholeRoadCooperative) {
				const partners = reduceRCMembers(allPartners)
				if (partners) {
					setFilteredPartnersList(partners)
					setSelectedPartners(partners)
				}
			} else {
				const partners = reduceCCMembers(allPartners, activeCostCenterId)
				if (partners) {
					setFilteredPartnersList(partners)
					setSelectedPartners(partners)
				}
			}
	}, [allPartners, activeCostCenterId, wholeRoadCooperative, data])

	const handleUnitSort = () => {
		console.log(filteredPartnersList)
		const sortedList = [...filteredPartnersList]
		if (unitDirection === "asc") {
			setUnitDirection("desc")

			sortedList.sort((a, b) => {
				const aSum = a?.establishments.reduce((p: any, n: any) => p + n?.roadUnits ?? 0, 0) ?? 0
				const bSum = b?.establishments.reduce((p: any, n: any) => p + n?.roadUnits ?? 0, 0) ?? 0
				return aSum - bSum
			})
		} else {
			const sortFunc = (a: any, b: any) => {
				const aSum = a?.establishments.reduce((p: any, n: any) => p + n?.roadUnits ?? 0, 0) ?? 0
				const bSum = b?.establishments.reduce((p: any, n: any) => p + n?.roadUnits ?? 0, 0) ?? 0
				return bSum - aSum
			}

			setUnitDirection("asc")
			sortedList.sort((a, b) => sortFunc(a, b))
		}
		setFilteredPartnersList(sortedList)
	}

	const handleMemberSort = () => {
		const sortedList = [...filteredPartnersList]
		console.log(sortedList)
		if (memberDirection === "asc") {
			setMemberDirection("desc")
			sortedList.sort((a, b) => {
				return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
			})
		} else {
			setMemberDirection("asc")
			sortedList.sort((a, b) => {
				return b.name.toLowerCase().localeCompare(a.name.toLowerCase())
			})
		}
		setFilteredPartnersList(sortedList)
	}

	////

	useEffect(() => {
		const filteredPartners = selectedPartners.filter((partner: any) => {
			const name = partner?.name?.toLowerCase() ?? ""
			return name.includes(searchQuery.toLowerCase())
		})
		setFilteredPartnersList(filteredPartners)
	}, [searchQuery, selectedPartners])

	const handleFilterChange = (value: string) => {
		setSearchQuery(value) // Päivitetään hakuehto
	}

	/////

	return (
		<>
			<TableContainer sx={{ maxHeight: "100%" }}>
				<Table stickyHeader size="medium" padding="none">
					<TableHead>
						<TableRow>
							<TableCell colSpan={5} align="center" sx={{ borderBottom: "1px solid white", paddingBottom: "10px" }}>
								<TextField
									size="small"
									fullWidth
									label="Hae"
									color="primary"
									sx={{ maxWidth: "300px", width: screen.width < 600 ? 250 : 460 }}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e.target.value)}
								/>
							</TableCell>
						</TableRow>
					</TableHead>
				</Table>
			</TableContainer>
			<TableContainer sx={{ maxHeight: "calc(100% - 70px)" }}>
				<Table stickyHeader size="medium" padding="none">
					<TableHead>
						<TableRow>
							<TableCell align="center" sx={{ width: "20px" }}></TableCell>
							<TableCell align="center" sx={{ width: "20px" }}></TableCell>
							<TableCell align="left">
								<Typography sx={{ fontWeight: "700", letterSpacing: "0", fontSize: "0.875rem" }}>
									Osakas
									<TableSortLabel active={true} onClick={handleMemberSort} direction={memberDirection} />
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography sx={{ fontWeight: "700", letterSpacing: "0", fontSize: "0.875rem" }}>
									Tieyksiköt
									<TableSortLabel active={true} onClick={handleUnitSort} direction={unitDirection} />
								</Typography>
							</TableCell>
							<TableCell align="center" sx={{ width: "20px" }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody sx={{ height: "100%", overflow: "hidden" }}>
						{noPartnerData ? (
							<TableRow>
								<TableCell colSpan={7}>
									<Box
										sx={{
											height: "100%",
											width: "100%",
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
											paddingTop: "20px",
											paddingBottom: "20px"
										}}
									>
										<Typography sx={{ fontSize: "1rem", textAlign: "center", fontWeight: "400", padding: "10px" }}>
											Ei lisättyjä osakkaita.
										</Typography>
										<MutationButton variant="contained" onClick={handlePopoverClick}>
											Lisää osakkaita
											<AddIcon sx={{ paddingLeft: "10px" }} />
										</MutationButton>
									</Box>
								</TableCell>
							</TableRow>
						) : allPartners.length === 0 ? (
							<TableRow>
								<TableCell colSpan={7}>
									<Box
										sx={{
											height: "100%",
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										<CircularProgress />
									</Box>
								</TableCell>
							</TableRow>
						) : (
							filteredPartnersList.map((partner: Partner, index: number) => {
								return (
									<PartnersTableRow
										unitsViewType={unitsViewType}
										refetch={refetch}
										partner={partner}
										key={(partner.name + partner.id + index).toString()}
									/>
								)
							})
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}

export default PartnersTable
