import {
	Typography,
	Grid,
	Box,
	Stack,
	TableContainer,
	Paper,
	Fab,
	TableRow,
	TableCell,
	Tooltip,
	Dialog,
	DialogContent,
	Container,
	TextField,
	TableHead,
	Table,
	TableBody,
	Collapse,
	TableSortLabel,
	CircularProgress
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import CreateNoteDialog from "./CreateNoteDialog"
import { toast } from "react-toastify"
import CustomDialogTitle from "../reusables/CustomDialogTitle"
import DialogCloseButton from "../reusables/DialogCloseButton"
import CustomDialogActions from "../reusables/CustomDialogActions"
import { createUserNote, getUserNotes, updateNote, deleteNote } from "../../network/dataServices/noteDataService"
import EditIcon from "@mui/icons-material/Edit"
import DeleteChip from "../reusables/DeleteChip"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"

const AllNotes = ({ setOpenNotesDialog }: any) => {
	const [allNoteRoadCooperativeNames, setNoteAllRoadCooperativeNames] = useState<string[]>([])
	const [createNewNote, setCreateNewNote] = useState<boolean>(false)
	const [updateNoteTable, setUpdateNoteTable] = useState(false)
	const [updatedNote, setUpdatedNote] = useState<Note | undefined>()
	const [value, setValue] = useState(1)
	const [notesRoadCooperativeSortDirection, setNotesRoadCooperativeSortDirection] = useState("asc")
	const [notesTitleSortDirection, setNotesTitleSortDirection] = useState("asc")
	const [roadCooperatives, setRoadCooperatives] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [loadingMessage, setLoadingMessage] = useState("")
	const [openDeleteNote, setOpenDeleteNote] = useState(false)
	const [notes, setNotes] = useState<Note[]>([])

	const refetchNotes = async () => {
		const notes = await getUserNotes()
		const tempnotesrcnames: string[] = []
		const tempnotes: Note[] = []
		if (notes) {
			for (let i = 0, len = notes.data.length; i < len; i++) {
				tempnotes.push({
					id: notes.data[i].id,
					roadCooperativeName: notes.data[i].road_cooperative_name,
					title: notes.data[i].title,
					content: notes.data[i].content,
					type: "note",
					open: false
				})
				tempnotesrcnames.push(notes.data[i].road_cooperative_name)
			}
		}
		setNotes(tempnotes)
		setNoteAllRoadCooperativeNames(tempnotesrcnames)
	}

	const handleNoteDirectionRC = () => {
		if (notesRoadCooperativeSortDirection == "desc") {
			setNotesRoadCooperativeSortDirection("asc")
			const tempnotes = notes.sort((a, b) => {
				const aName = a.roadCooperativeName.toUpperCase()
				const bName = b.roadCooperativeName.toUpperCase()
				if (aName < bName) {
					return -1
				}
				if (aName > bName) {
					return 1
				}
				return 0
			})
			setNotes(tempnotes)
		} else {
			setNotesRoadCooperativeSortDirection("desc")
			const tempnotes = notes.sort((a, b) => {
				const aName = a.roadCooperativeName.toUpperCase()
				const bName = b.roadCooperativeName.toUpperCase()
				if (aName < bName) {
					return 1
				}
				if (aName > bName) {
					return -1
				}
				return 0
			})
			setNotes(tempnotes)
		}
	}

	const handleNoteDirectionTitle = () => {
		if (notesTitleSortDirection === "asc") {
			setNotesTitleSortDirection("desc")
			const tempnotes = notes.sort((a, b) => {
				const aName = a.title.toUpperCase()
				const bName = b.title.toUpperCase()
				if (aName < bName) {
					return -1
				}
				if (aName > bName) {
					return 1
				}
				return 0
			})

			setNotes(tempnotes)
		} else {
			setNotesTitleSortDirection("asc")
			const tempnotes = notes.sort((a, b) => {
				const aName = a.title.toUpperCase()
				const bName = b.title.toUpperCase()
				if (aName < bName) {
					return 1
				}
				if (aName > bName) {
					return -1
				}
				return 0
			})
			setNotes(tempnotes)
		}
	}
	const navigate = useNavigate()
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}

	const onHandleDeleteNote = async (id: number) => {
		setIsLoading(true)
		setLoadingMessage("Poistetaan muistiota...")
		const tempNotes = [...notes]
		for (let i = 0, len = tempNotes.length; i < len; i++) {
			if (tempNotes[i].id == id) {
				tempNotes.splice(i, 1)
				const deleteRes = await deleteNote(id)
				if (deleteRes != null) {
					toast.success("Muistio poistettu onnistuneesti")
				} else {
					toast.error("Muistion poisto epäonnistui!")
				}
				setOpenDeleteNote(false)
				setUpdateNoteTable(false)
				await getUserNotes()
				await refetchNotes()
				setIsLoading(false)
				setNotesRoadCooperativeSortDirection("desc")
				return
			}
		}
	}

	const addNote = async (newNote: Note) => {
		setIsLoading(true)
		setLoadingMessage("Tallennetaan muistiota...")
		const addRes = await createUserNote(newNote)
		if (addRes != null) {
			toast.success("Muistio lisätty onnistuneesti")
		} else {
			toast.error("Muistion lisääminen epäonnistui")
		}
		setCreateNewNote(false)
		setUpdateNoteTable(false)
		await getUserNotes()
		await refetchNotes()
		setIsLoading(false)
		setNotesRoadCooperativeSortDirection("desc")
		return
	}

	const openUpdateNoteDialog = (note: Note) => {
		setUpdatedNote(note)
		setUpdateNoteTable(true)
	}

	useEffect(() => {
		refetchNotes()
	}, [])

	useEffect(() => {
		handleNoteDirectionRC()
	}, [notes])

	useEffect(() => {
		const temp = []
		for (let i = 0, len = notes.length; i < len; i++) {
			temp.push(notes[i].roadCooperativeName)
		}
		setRoadCooperatives(temp)
	}, [notes])

	return (
		<>
			<Grid
				item
				xs={12}
				md={6}
				sx={{
					height: "100%",
					"@media screen and (max-width: 700px)": {
						margin: "0px",
						padding: "0px",
						maxWidth: "400px"
					}
				}}
			>
				<TableContainer
					elevation={1}
					sx={{
						justifyContent: "flex-start",
						height: "calc(100% - 50px)",
						overflow: "auto",
						alignItems: "flex-start",
						alignContent: "flex-start"
					}}
				>
					<Table>
						<TableHead>
							<TableCell sx={{ width: "1%" }}></TableCell>
							<TableCell sx={{ width: "60%", fontWeight: "700", paddingBottom: "3px", paddingTop: "0px" }}>
								Otsikko
								<TableSortLabel onClick={() => handleNoteDirectionTitle()} active={true} direction={notesTitleSortDirection}></TableSortLabel>
							</TableCell>
							<TableCell sx={{ width: "38%", fontWeight: "700", paddingBottom: "3px", paddingTop: "0px" }}>
								Tiekunta
								<TableSortLabel
									onClick={() => handleNoteDirectionRC()}
									active={true}
									direction={notesRoadCooperativeSortDirection}
								></TableSortLabel>
							</TableCell>
							<TableCell sx={{ width: "1%" }}></TableCell>
						</TableHead>
						<TableBody>
							{notes.map((note, index) => {
								return <NotesTableRow note={note} key={note.id} openUpdateNoteDialog={openUpdateNoteDialog}></NotesTableRow>
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<Grid item xs={12} md={12} sx={{ height: "40px", marginTop: "10px" }}>
					<Stack direction="row" justifyContent="center" alignContent="center">
						<Dialog sx={{ zIndex: "1491" }} open={createNewNote} maxWidth="md" fullWidth>
							<CreateNoteDialog setCreateNewNote={setCreateNewNote} addNote={addNote} allNoteRoadCooperativeNames={allNoteRoadCooperativeNames} />
						</Dialog>
						<Tooltip sx={{ zIndex: "1492" }} title="Luo uusi Muistio">
							<Fab onClick={() => setCreateNewNote(true)} color="primary" sx={{ width: "35px", height: "20px" }}>
								<AddIcon sx={{ fontSize: "20px" }} />
							</Fab>
						</Tooltip>
					</Stack>
				</Grid>
			</Grid>

			<Dialog sx={{ zIndex: "1491" }} open={updateNoteTable} maxWidth="md" fullWidth>
				<UpdateNoteTable
					setUpdateNoteTable={setUpdateNoteTable}
					refetchNotes={refetchNotes}
					note={updatedNote}
					onHandleDeleteNote={onHandleDeleteNote}
				/>
			</Dialog>
		</>
	)
}

const CustomLoadingDialog = ({ isLoading, loadingMessage }: any) => {
	return (
		<Dialog open={isLoading}>
			<CircularProgress sx={{ marginLeft: "auto", marginRight: "auto" }} />
			<Typography>{loadingMessage}</Typography>
		</Dialog>
	)
}

const NotesTableRow = ({ note, onHandleNoteInfo, openUpdateNoteDialog }: any) => {
	const [open, setOpen] = useState(false)

	const cellStyles = {
		cellPadding: {
			paddingTop: "5px",
			paddingBottom: "5px",
			paddingRight: "15px",
			paddingLeft: "15px"
		}
	}

	return (
		<>
			<React.Fragment key={"notes-" + note.id}>
				<TableRow item xs={12}>
					<TableCell
						open={NotesTableRow}
						sx={{ maxWidth: "20px", borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0", ...cellStyles.cellPadding }}
					>
						<IconButton size="small" onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell sx={{ borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0", ...cellStyles.cellPadding }}>{note.title} </TableCell>
					<TableCell sx={{ borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0", ...cellStyles.cellPadding }}>
						{note.roadCooperativeName}
					</TableCell>

					<TableCell sx={{ borderBottom: 0, borderTop: 1, borderTopColor: "#e0e0e0", ...cellStyles.cellPadding }}>
						<IconButton
							onClick={() => {
								openUpdateNoteDialog(note)
							}}
						>
							<EditIcon sx={{ width: "20px", color: "rgba(0, 0, 0, 0.54)" }} />
						</IconButton>
					</TableCell>
				</TableRow>

				<TableRow>
					<TableCell colSpan={5} sx={{ padding: 0, margin: 0, borderBottom: 0 }}>
						<Collapse in={open} unmountOnExit>
							{!note ? null : (
								<>
									<Grid container direction="row" justifyContent="center" alignItems="flex-start" padding="10px" marginBottom="3px">
										<Grid item xs sx={{ minWidth: "130px", maxWidth: "130px", overflow: "hidden" }}>
											<Box component="div" sx={{}}>
												{note.content}
											</Box>
										</Grid>
									</Grid>
								</>
							)}
						</Collapse>
					</TableCell>
				</TableRow>
			</React.Fragment>
		</>
	)
}

const UpdateNoteTable = ({ setUpdateNoteTable, updateNoteTable, refetchNotes, note, onHandleDeleteNote }: any) => {
	const [openDeleteNote, setOpenDeleteNote] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [loadingMessage, setLoadingMessage] = useState("")
	const [editTitle, setEditTitle] = useState(note.title)
	const [editRoadCooperativeName, setEditRoadCooperativeName] = useState(note.roadCooperativeName)
	const [editContent, setEditContent] = useState(note.content)

	const handleUpdateNote = async () => {
		const editNoteObj: any = {
			id: note.id,
			title: editTitle,
			content: editContent,
			type: "note",
			roadCooperativeName: editRoadCooperativeName,
			open: false
		}

		setIsLoading(true)
		setLoadingMessage("Muokataan muistiota...")
		const editRes = await updateNote(editNoteObj)
		if (editRes != null) {
			toast.success("tehtävä päivitetty onnistuneesti")
		} else {
			toast.error("Tehtävän muokkaaminen epäonnistui!")
		}
		setUpdateNoteTable(false)
		await refetchNotes()
		await getUserNotes()
		setIsLoading(false)
		return
	}

	return (
		<>
			<CustomLoadingDialog isLoading={isLoading} loadingMessage={loadingMessage} />
			<DialogCloseButton closeFunction={() => setUpdateNoteTable(false)} />
			<DeleteChip deleteFunction={() => setOpenDeleteNote(true)} />
			<CustomDialogTitle>Muokkaa muistiota</CustomDialogTitle>
			<Container
				maxWidth="md"
				sx={{
					overflow: "auto",
					justifyContent: "center",
					height: "100%",
					maxWidth: "80%",
					textAlign: "center",
					display: "flex"
				}}
				elevation={10}
			>
				<Stack direction="column" maxWidth="90%">
					<Stack direction="column" justifyContent="center" alignItems="center" padding="5px">
						<Grid>
							<TextField
								value={editTitle}
								style={{ width: "100%" }}
								label="Otsikko"
								onChange={(e: { target: { value: string } }) => {
									setEditTitle(e.target.value)
								}}
							></TextField>
							<TextField
								value={editRoadCooperativeName}
								style={{ width: "100%" }}
								label="Tiekunta"
								onChange={(e: { target: { value: string } }) => {
									setEditRoadCooperativeName(e.target.value)
								}}
							></TextField>
							<TextField
								value={editContent}
								// overflow-y="scroll"
								rows={9}
								cols="20"
								style={{ width: "100%" }}
								label="Sisältö"
								multiline
								onChange={(e: { target: { value: string } }) => {
									setEditContent(e.target.value)
								}}
							></TextField>
						</Grid>
					</Stack>
				</Stack>
			</Container>
			<CustomDialogActions>
				<Button variant="outlined" color="primary" onClick={() => setUpdateNoteTable(false)}>
					Sulje
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						handleUpdateNote()
					}}
				>
					Tallenna muutokset
				</Button>
			</CustomDialogActions>

			<Dialog sx={{ zIndex: "1491" }} open={openDeleteNote}>
				<CustomDialogTitle>Vahvista poistaminen</CustomDialogTitle>
				<DialogCloseButton closeFunction={() => setOpenDeleteNote(false)} />
				<DialogContent sx={{ textAlign: "center", justifyContent: "center", display: "flex", alignItems: "center" }}>
					{" "}
					Haluatko varmasti poistaa?
				</DialogContent>
				<CustomDialogActions>
					<Button
						variant="outlined"
						color="cancel"
						onClick={() => {
							setOpenDeleteNote(false)
						}}
					>
						Peruuta
					</Button>
					<Button
						variant="contained"
						color="close"
						onClick={() => {
							onHandleDeleteNote(note.id)
						}}
					>
						Poista
					</Button>
				</CustomDialogActions>
			</Dialog>
		</>
	)
}

export default AllNotes
