import React, { useEffect, useState } from "react"
import CreateRoadCooperative from "./CreateRoadCooperative"
import { getUserRoadCooperatives, logoutUser, selectRoadCooperative } from "../../network/dataServices/userDataService"
import { Tooltip, Stack, TextField, Paper, Typography, Button, Box, CircularProgress, Fab } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import SearchIcon from "@mui/icons-material/Search"
import LogoutIcon from "@mui/icons-material/Logout"
// import { gql, useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import { createDumbData } from "../../network/dataServices/dumbDataService"
type RoadCooperativeListItem = {
	name: string
	id: string
}

const SelectRoadCooperative = () => {
	const [allRoadCooperatives, setAllRoadCooperatives] = useState<{ name: string; id: string; type: string }[]>([])
	const [roadCooperativesLoaded, setRoadCooperativesLoaded] = useState<boolean>(false)
	const [roadCooperativeList, setRoadCooperativeList] = useState<RoadCooperativeListItem[]>([])
	const [creatingNewRC, setCreatingNewRC] = useState<boolean>(false)

	const handleSelectRoadCooperative = async (name: string, id: string) => {
		selectRoadCooperative(name, id)
	}

	// const handleOpenRoadCooperativeClick = async (blobName: string) => {
	// 	console.log(roadCooperativeList)
	// }

	const handleCreateDumpData = async () => {
		console.log("Uusi dumppidata")

		const addRes = await createDumbData()
		if (addRes != null) {
			toast.success("Dumpdatan lisätty onnistuneesti")
		} else {
			toast.error("Dumpdatan lisääminen epäonnistui!")
		}
		window.location.reload()
	}

	const handleFilterChange = (value: string) => {
		if (!value) setRoadCooperativeList(allRoadCooperatives)
		else {
			const temp = []
			for (let i = 0, len = allRoadCooperatives.length; i < len; i++) {
				if (allRoadCooperatives[i].name.toUpperCase().includes(value.toUpperCase())) {
					temp.push(allRoadCooperatives[i])
				}
			}
			setRoadCooperativeList(temp)
		}
	}

	useEffect(() => {
		getUserRoadCooperatives().then((data) => {
			if (data) {
				console.log(data)
				const sortedRoadCooperatives = data.data.sort((a: RoadCooperative, b: RoadCooperative) => {
					const nameA = a.name?.toUpperCase() ?? ""
					const nameB = b.name?.toUpperCase() ?? ""
					if (nameA < nameB) {
						return -1
					}
					if (nameA > nameB) {
						return 1
					}
					return 0
				})
				setAllRoadCooperatives(sortedRoadCooperatives)
				setRoadCooperativeList(sortedRoadCooperatives)
				const storedRoadCooperative = localStorage.getItem("selectedRoadCooperative")
				if (storedRoadCooperative) {
					const roadCooperative = JSON.parse(storedRoadCooperative)

					// Etsitään tiekunta listasta ja valitaan se automaattisesti
					const matchingRoadCooperative = sortedRoadCooperatives.find((rc: any) => rc.id === roadCooperative.id)

					if (matchingRoadCooperative) {
						handleSelectRoadCooperative(matchingRoadCooperative.name, matchingRoadCooperative.id)

						// Poistetaan localStoragesta, jotta tieto ei jää kummittelemaan
						localStorage.removeItem("selectedRoadCooperative")
					}
				}
			}
			setRoadCooperativesLoaded(true)
		})
	}, [])

	if (!roadCooperativesLoaded)
		return (
			<Box
				sx={{
					width: "100vw",
					height: "100vh",
					color: "primary.contrastText",
					justifyContent: "center",
					alignContent: "center",
					alignItems: "center",
					display: "flex"
				}}
			>
				<CircularProgress color="inherit" />
			</Box>
		)
	else if ((allRoadCooperatives.length === 0 && process.env.NODE_ENV !== "development") || creatingNewRC)
		return (
			<Box sx={{ height: "auto", width: "100%", paddingTop: "5vh", alignItems: "center" }}>
				<div className="login-title"></div>
				<CreateRoadCooperative creatingNew={creatingNewRC} setCreatingNew={setCreatingNewRC} />
			</Box>
		)
	else
		return (
			<Box sx={{ height: "auto", width: "100%", display: "flex", flexDirection: "column", paddingTop: "5vh", alignItems: "center" }}>
				<Tooltip title="Kirjaudu ulos">
					<Fab
						sx={{ position: "fixed", top: screen.width > 1120 ? "10px" : "60px", right: "15px", zIndex: 2000 }}
						color="primary"
						size="small"
						onClick={logoutUser}
					>
						<LogoutIcon />
					</Fab>
				</Tooltip>

				<div className="login-title"></div>

				<Paper
					sx={{
						height: "400px",
						padding: "20px",
						width: "min(90vw, 650px)",
						textAlign: "center",
						paddingX: "10px",
						paddingBottom: "50px",
						display: "flex",
						flexDirection: "column"
					}}
					elevation={10}
				>
					<Typography sx={{ fontSize: "2rem", height: "60px" }}>Valitse tiekunta</Typography>

					<Stack
						spacing={2}
						direction={"row"}
						sx={{ alignItems: "center", alignSelf: "center", height: "60px", width: "min(90vw, 500px)", marginBottom: "10px" }}
					>
						<SearchIcon />
						<TextField
							fullWidth
							label="Etsi tiekunta..."
							color="primary"
							sx={{ width: screen.width < 600 ? 250 : 460 }}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e.target.value)}
						/>
						<Tooltip title="Luo uusi tiekunta">
							<AddIcon fontSize="large" color="primary" onClick={() => setCreatingNewRC(true)} sx={{ cursor: "pointer" }}></AddIcon>
						</Tooltip>
					</Stack>
					{process.env.NODE_ENV === "development" ? (
						<Box>
							<Button onClick={handleCreateDumpData}>Luo dumppidata</Button>
							<br />
							<br />
						</Box>
					) : null}

					<Box
						sx={{
							width: "min(90vw, 500px)",
							maxHeight: "300px",
							overflow: "auto",
							alignSelf: "center"
						}}
					>
						{roadCooperativeList.length !== 0 ? (
							roadCooperativeList.map((rc: { name: string; id: string }) => (
								<React.Fragment key={rc.id}>
									<Button
										fullWidth
										sx={{ marginBottom: "4px", width: "calc(83%)" }}
										variant="contained"
										onClick={() => handleSelectRoadCooperative(rc.name, rc.id)}
									>
										{rc.name}
									</Button>
								</React.Fragment>
							))
						) : (
							<Typography>Ei tiekuntadataa</Typography>
						)}
					</Box>
				</Paper>
			</Box>
		)
}

export default SelectRoadCooperative
