import React, { useEffect, useState } from "react"
import { MapContainer } from "../Map/MapContainer"

import { gql, useQuery } from "@apollo/client"
import { Button, Paper, Dialog, Tooltip, IconButton } from "@mui/material"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import MenuOpenIcon from "@mui/icons-material/MenuOpen"
import HomePageDrawer from "./HomePageDrawer/HomePageDrawer"
import CreateRCDialog from "./CreateRCDialog"
import RoadCooperativeSelectField from "./RoadCooperativeSelectField"
import CheckRoadCooperativeStatus from "./CheckRoadCooperativeStatus"
import { Box } from "@mui/material"
import OnboardingHelper from "./OnboardingHelper"
import MunicipalityButton from "../reusables/MunicipalityLogo"
import MunicipalityButtonDisabled from "../reusables/MunicipalityLogoDisabled"
import MunicipalityButtonWaiting from "../reusables/MunicipalityLogoWaiting"
import BankBalanceBlock from "./BankBalanceBlock"
import { useNavigate } from "react-router"
import MunicipalityLogoNotFound from "../reusables/MunicipalityLogoNotFound"
import MunicipalityPermissionDialog from "./municipalityUtils/MunicipalityPermissionDialog"
import userProfileStorage from "../../utils/providers/userProfileProvider/userProfileProvider"

const GET_COST_CENTERS_ALL_DATA = gql`
	query GetCostCentersAllData {
		roadCooperativeWithJWT {
			costCentersByRoadCooperativeId {
				nodes {
					name
					idColor
					id
					roadLinksByCostCenterId {
						nodes {
							linkId
							linkLength
							coordinates {
								lat
								lng
							}
							id
						}
					}
					propertiesByCostCenterId {
						nodes {
							mmlPropertyId
							plotId
							propertyName
							id
							propertyTag
						}
					}
				}
			}
		}
	}
`
declare global {
	interface ActiveCostCenterInterface {
		name: string
		id: number | undefined
		color?: string | undefined
	}
}

const GET_ROAD_COOPERATIVE_DATA = gql`
	query GetRoadCooperativeData {
		roadCooperativeWithJWT {
			totalUnits
			municipality
		}
	}
`

const GET_MUNICIPALITY_PERMISSION = gql`
	query AllAcceptableRoadCooperatives {
		allAcceptableRoadCooperatives {
			... on accepted_road_cooperative_id {
				accepted
				id
				roadCooperativeId
			}
			... on NoMunicipality {
				message
			}
		}
	}
`

const defaultRoadCooperativeStatusValues: RoadCooperativeStatusValuesType = {
	createRoad: false,
	createMembers: false,
	createEstablishments: false
}

type Cooperative = {
	accepted: boolean | null
	id: string
	roadCooperativeId: string
}

const HomePage = () => {
	// Jos activeCostCenterin id on undefined ja name on 'tiekunta' niin valitaan koko tiekunta näkymään.
	const [activeCostCenter, setActiveCostCenter] = useState<ActiveCostCenterInterface>({ id: undefined, name: "tiekunta", color: "#7FC646" })
	const [showDrawer, setShowDrawer] = useState(false)
	const [roadCooperativeStatusValues, setRoadCooperativeStatusValues] = useState<RoadCooperativeStatusValuesType>(defaultRoadCooperativeStatusValues)
	const permission = userProfileStorage({ type: "getActiveRoadCooperative" }).permission
	const { data: RCGeoJsonData, refetch: refetchRCGeoJson } = useQuery(GET_COST_CENTERS_ALL_DATA)
	const navigate = useNavigate()

	useEffect(() => {
		if (!RCGeoJsonData) return
		// Valitaan kartalla näytettävä data sekä drawerin näytettävä data
		const rc = RCGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes
		if (!RCGeoJsonData?.roadCooperativeWithJWT?.costCentersByRoadCooperativeId) return
		if (rc == null || rc.length == 0) return
		if (rc.length == 1) {
			// jos kustannuspaikkoja on vain yksi, valitaan se kustannuspaikka näkymään
			const cc = RCGeoJsonData.roadCooperativeWithJWT.costCentersByRoadCooperativeId.nodes[0]
			setActiveCostCenter({ id: cc.id, name: cc.name })
		} else {
			// jos Kustannuspaikkoja on useita, valitaan tiakunta näkymä
			setActiveCostCenter({ id: undefined, name: "tiekunta", color: "#7FC646" })
		}
	}, [RCGeoJsonData])

	const handleMunicipalityLogoClick = () => {
		navigate("/petition")
	}

	const [openMunicipalityPopUp, setOpenMunicipalityPopUp] = useState(false)

	const handleDisabledMunicipalityLogoClick = () => {
		setOpenMunicipalityPopUp(true)
	}

	const { data: rcData } = useQuery(GET_ROAD_COOPERATIVE_DATA)
	const { data: rcAcceptData, loading: rcAcceptLoading } = useQuery(GET_MUNICIPALITY_PERMISSION, {
		fetchPolicy: "network-only"
	})

	// Poistetaan kuntakoodi municipality:sta
	let municipalityName = ""
	if (rcData && rcData.roadCooperativeWithJWT && rcData.roadCooperativeWithJWT.municipality) {
		const namePart = rcData.roadCooperativeWithJWT.municipality.split(" - ")
		municipalityName = namePart[0] // Get the first part of the split result
	}

	const [filteredCooperatives, setFilteredCooperatives] = useState<Cooperative[]>([])

	const [isQueryCompleted, setIsQueryCompleted] = useState(false)

	const [noMunicipalityCreated, setNoMunicipalityCreated] = useState(false)

	useEffect(() => {
		if (rcAcceptLoading) {
			setIsQueryCompleted(false)
			return
		}
		if (rcAcceptData && rcAcceptData.allAcceptableRoadCooperatives) {
			const cooperatives = rcAcceptData.allAcceptableRoadCooperatives
			// Tarkastetaan onko kunnan käyttäjää luotu kuntaportaaliin.
			const noMunicipalityMessage = cooperatives.length === 1 && cooperatives[0].message === "No municipality created"
			if (noMunicipalityMessage) {
				setNoMunicipalityCreated(true)
			} else {
				setFilteredCooperatives(cooperatives)
				setIsQueryCompleted(true)
			}
		}
	}, [rcAcceptLoading, rcAcceptData])

	return (
		<div>
			<div className="page-content-grid">
				{permission === "edit" && <OnboardingHelper />}
				<HomePageDrawer
					roadCooperativeStatusValues={roadCooperativeStatusValues}
					setRoadCooperativeStatusValues={setRoadCooperativeStatusValues}
					showDrawer={showDrawer}
					setShowDrawer={setShowDrawer}
					refetchRCGeoJson={refetchRCGeoJson}
					activeCostCenter={activeCostCenter}
					setActiveCostCenter={setActiveCostCenter}
				/>
				<div className="map-grid-container-full">
					<Button
						color="primary"
						variant="contained"
						sx={{
							position: "fixed",
							marginTop: "10px",
							right: "15px",
							zIndex: 2000,
							"@media screen and (max-width: 460px)": {
								paddingRight: "0px",
								paddingLeft: "0px"
							}
						}}
						onClick={() => setShowDrawer(!showDrawer)}
					>
						{showDrawer ? (
							<>
								SULJE <MenuOpenIcon sx={{ transform: "rotate(180deg)" }} />{" "}
							</>
						) : (
							<>
								AVAA <MenuOpenIcon />{" "}
							</>
						)}
					</Button>
					{/* <Grid
						variant="contained"
						sx={{
							position: "fixed",
							marginTop: "10px",
							left: "50%",
							zIndex: 2000,
							"@media screen and (max-width: 460px)": {
								paddingRight: "0px",
								paddingLeft: "0px",
							},
						}}
					>
						<LinkButton title="Tilaa tievahdilta palveluita" url="https://www.tievahti.fi/palvelut/"></LinkButton>
					</Grid> */}

					{(permission === "edit" || permission === "municipality") && (
						<>
							<BankBalanceBlock />
							{noMunicipalityCreated ? (
								<MunicipalityLogoNotFound municipalityName={municipalityName} />
							) : (
								isQueryCompleted &&
								(filteredCooperatives.length > 0 ? (
									filteredCooperatives.map((cooperative) => {
										if (cooperative.accepted === true) {
											return (
												<MunicipalityButton
													key={cooperative.id}
													municipalityName={municipalityName}
													handleMunicipalityLogoClick={handleMunicipalityLogoClick}
												/>
											)
										} else if (cooperative.accepted === false) {
											return null // Do not render anything
										} else if (cooperative.accepted === null && cooperative.id !== null) {
											return <MunicipalityButtonWaiting key={cooperative.id} municipalityName={municipalityName} />
										} else {
											return (
												<MunicipalityButtonDisabled
													key={cooperative.id}
													municipalityName={municipalityName}
													handleDisabledMunicipalityLogoClick={handleDisabledMunicipalityLogoClick}
												/>
											)
										}
									})
								) : (
									<MunicipalityButtonDisabled
										municipalityName={municipalityName}
										handleDisabledMunicipalityLogoClick={handleDisabledMunicipalityLogoClick}
									/>
								))
							)}
						</>
					)}

					<Box
						// Vasen yläkulma laatikko
						sx={{
							position: "absolute",
							margin: "5px",
							display: "flex",
							flexDirection: "column"
						}}
					>
						<RoadCooperativeCreateAndSelectBlock permission={permission} />
						<CheckRoadCooperativeStatus
							roadCooperativeStatusValues={roadCooperativeStatusValues}
							setRoadCooperativeStatusValues={setRoadCooperativeStatusValues}
						/>
					</Box>
					<MapContainer
						// Kartta komponentti
						mapFeatures={{ showRoadLinks: true, showProperties: true, showPropertyInfoByClick: true }}
						selectedCostCenterId={activeCostCenter.id}
					/>
				</div>
			</div>
			<Dialog sx={{ zIndex: "1002" }} open={openMunicipalityPopUp}>
				<MunicipalityPermissionDialog setOpenMunicipalityPopUp={setOpenMunicipalityPopUp} />
			</Dialog>
		</div>
	)
}

const RoadCooperativeCreateAndSelectBlock = ({ permission }: any) => {
	const [createNewRC, setCreateNewRC] = useState<boolean>(false)

	return (
		<>
			<Dialog open={createNewRC} maxWidth="lg">
				<CreateRCDialog setCreateNewRC={setCreateNewRC} />
			</Dialog>

			<Paper
				elevation={5}
				sx={{
					zIndex: 1,
					width: "310px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					padding: "10px",
					paddingBottom: "15px",
					borderRadius: "10px",
					marginBottom: "5px",
					"@media screen and (max-width: 413px)": {
						display: "none"
					},
					"@media screen and (max-width: 455px)": {
						width: "270px"
					}
				}}
			>
				<RoadCooperativeSelectField />
				{permission !== "municipality" && (
					<Tooltip title="Luo uusi tiekunta">
						<IconButton
							sx={{
								"@media screen and (max-width: 455px)": {
									display: "none"
								}
							}}
							color={"primary"}
							onClick={() => setCreateNewRC(true)}
						>
							<AddCircleIcon></AddCircleIcon>
						</IconButton>
					</Tooltip>
				)}
			</Paper>
		</>
	)
}

export default HomePage
