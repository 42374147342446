import axios from "axios"
import React from "react"
import { FeatureCollection, Feature } from "geojson"
import { useEffect } from "react"
import { useState } from "react"

const MapSearchBox = () => {
	const [searchInput, setSearchInput] = useState("")
	const [searchResults, setSearchResults] = useState<string[]>([])
	const [showSearchResult, setShowSearchResult] = useState<string[]>([])

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInput(e.target.value)
	}

	useEffect(() => {
		setSearchResults(["tarkenna hakua"])
		let list: string[] = []
		if (searchInput.length < 4) return

		const resultFunction = async () => {
			const result: { data: FeatureCollection } = await axios.get(
				`https://julkinen.vayla.fi/viitekehysmuunnin/muunna?katunimi=${searchInput}*&palautusarvot=1,2,3,4,5,6`
			)
			console.log(result.data)
			result.data.features.map((r: Feature) => {
				if (r.properties) list = list.concat(r.properties.katunimi)
			})

			setSearchResults(list)
			console.log(list)
		}
		resultFunction()
	}, [searchInput])

	useEffect(() => {
		console.log(searchResults)
		setShowSearchResult(searchResults)
	}, [searchResults])

	useEffect(() => {
		const inputField = document.getElementById("mapInputField")
		const mapSearchContent = document.getElementById("mapSearchContent")

		inputField?.addEventListener("focusin", () => {
			mapSearchContent?.classList.add("active-search")
			mapSearchContent?.classList.remove("map-search-content")
		})

		inputField?.addEventListener("focusout", () => {
			mapSearchContent?.classList.add("map-search-content")
			mapSearchContent?.classList.remove("active-search")
		})
	}, [])

	return (
		<div className="map-search-box">
			<div className="map-search-input-layout">
				<input id="mapInputField" type="text" className="map-search-input-field" value={searchInput} onChange={(event) => handleInputChange(event)} />
			</div>
			<div id="mapSearchContent" className="map-search-content">
				<button className="search-casual-button">hae</button>

				<ul>
					{showSearchResult.map((katu: string) => {
						return <li key={katu}>{katu}</li>
					})}
				</ul>
			</div>
		</div>
	)
}

export default MapSearchBox
