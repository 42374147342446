import React, { useState } from "react"
import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Dialog } from "@mui/material"
import { Typography } from "@mui/material"
import { Button } from "@mui/material"
import { DialogContent } from "@mui/material"
import CustomDialogActions from "./CustomDialogActions"

type DialogCloseButtonProps = {
	closeFunction: (functionParams?: any) => void
	functionParams?: any
	showCloseAlert?: boolean
	alertText?: string
}

const DialogCloseButton = ({
	closeFunction,
	showCloseAlert = false,
	alertText = "Haluatko varmasti sulkea näkymän?",
	functionParams
}: DialogCloseButtonProps) => {
	const [openAlert, setOpenAlert] = useState(false)

	const handleCloseDialog = () => {
		try {
			if (functionParams) {
				closeFunction(functionParams)
			} else {
				closeFunction()
			}
		} catch (error) {
			console.error("Error in DialogCloseButton close function")
		}
	}

	const handleAlert = () => {
		if (showCloseAlert) {
			setOpenAlert(true)
		} else {
			handleCloseDialog()
		}
	}

	return (
		<>
			<Dialog open={openAlert} maxWidth="xs">
				<DialogContent>
					<Typography textAlign="center">{alertText}</Typography>
				</DialogContent>
				<CustomDialogActions>
					<Button variant="outlined" color="cancel" onClick={() => setOpenAlert(false)}>
						Peruuta
					</Button>
					<Button variant="contained" color="close" onClick={handleCloseDialog}>
						Sulje
					</Button>
				</CustomDialogActions>
			</Dialog>
			<IconButton sx={{ position: "absolute", right: 5, top: 5 }} onClick={handleAlert}>
				<CloseIcon />
			</IconButton>
		</>
	)
}

export default DialogCloseButton
