const waitUntilMapLoaded = (map: any, func: (...params: any) => any, params?: any, i = 10, ...params2: any) => {
	let count = 0
	// console.log(params, " ---PARAMS ----- ", i," -- ",  count);
	const waitFunc = () => {
		// console.log(i, " -- ", count)
		setTimeout(() => {
			if (map && map.current._fullyLoaded) {
				if (params && !params2) {
					func(params)
				} else if (params2) {
					func(params, params2)
				} else {
					func()
				}
			} else {
				if (count < i) {
					count++
					return waitFunc()
				}
			}
		}, 200)
	}

	if (count < i) {
		return waitFunc()
	}
}

export default waitUntilMapLoaded
