import React, { useMemo } from "react"
import { ApolloClient, HttpLink, from, ApolloProvider, ApolloLink } from "@apollo/client"
import { cache } from "../apolloClientInMemoryCache"
import authToken from "../../../utils/authToken"
import config from "../../../utils/config"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import { toast } from "react-toastify"

const getErrorMessage = (errors: any) => {
	// Määrittämällä tänne virhe viestin. viesti lisätään toastiin aina kun kyseinen virhe tapahatuu riippumatta muista tostin määrityksistä.
	let message = ""
	for (const error in errors) {
		if (errors[error].message == "Unauthorized mutation") {
			message = message + " Käyttäjän oikeudet eivät riitä toiminnon suorittamiseen."
		}
		// lisää tänne muita virhe viestejä mikäli tarttee
	}
	return message
}

type customQueryMessagesType = {
	query: string
	successMessage?: string
	errorMessage?: string
	loadingMessage?: string
}

const disableAutoToastList = [
	"createmeetingparticipants",
	"createfiletag",
	"deletefiletagbyid",
	"createtag",
	"updatetagbyid",
	"deletetagbyid",
	"updatemeetingbyid",
	"updateuserreadstatusextended",
	"createchat",
	"createchatcomment",
	"createroadcooperativeformember",
	"createroadcooperativeforexternaloperator",
	"updateroadcooperativebyidpermissionunit"
]

const customQueryMessages: customQueryMessagesType[] = [
	{ query: "createestablishment", successMessage: "Yksikkö luotu", errorMessage: "Yksikön luonti epäonnistui. ", loadingMessage: "Luodaan Yksikköä. " },
	{
		query: "updateestablishmentbyid",
		successMessage: "Yksikkö päivitetty",
		errorMessage: "Yksikön päivitys epäonnistui. ",
		loadingMessage: "Päivitetään yksikköä... "
	},
	{
		query: "createmeeting",
		successMessage: "Uusi kokous luotu",
		errorMessage: "Kokouksen luonti epäonnistui. ",
		loadingMessage: "Päivitetään kokouksen tietoja... "
	},
	{
		query: "deletemeetingbyid",
		successMessage: "Kokouksen tiedot poistettu.",
		errorMessage: "Kokouksen poistaminen epäonnistui. ",
		loadingMessage: "Poistetaan kokouksen tieoja... "
	}
	// lisää tähän omia custom messageja.
]

const getQueryMessage = (queryName: string, response: any) => {
	let message = ""
	if (customQueryMessages.some((query) => query.query == queryName)) {
		const currentCustomQuery = customQueryMessages.find((query) => query.query == queryName)
		message = response.errors
			? (currentCustomQuery?.errorMessage + getErrorMessage(response.errors) ?? `tietojen päivitys epäonnistui. ` + getErrorMessage(response.errors))
			: (currentCustomQuery?.successMessage ?? "Onnistunut tietojen päivitys")
	} else if (queryName.includes("update") || queryName.includes("delete") || queryName.includes("create")) {
		switch (true) {
			case queryName.includes("update"):
				message = response.errors ? "Tietojen päivitys epäonnistui. " + getErrorMessage(response.errors) : "Tiedot päivitetty"
				break
			case queryName.includes("create"):
				message = response.errors ? "Tietojen luonti epäonnistui. " + getErrorMessage(response.errors) : "Tiedot luotu"
				break
			case queryName.includes("delete"):
				message = response.errors ? "Tietojen poisto epäonnistui. " + getErrorMessage(response.errors) : message + "Tiedot poistettu"
				break
		}
	}
	return message
}

const setDataLoadingToast = (operation: any) => {
	const operationName = operation.query.definitions[0]?.selectionSet.selections[0].name?.value.toLowerCase()
	if (disableAutoToastList.includes(operationName)) return
	let message = ""
	if (customQueryMessages.some((query) => query.query == operationName)) {
		const currentCustomQuery = customQueryMessages.find((query) => query.query == operationName)
		message = currentCustomQuery?.loadingMessage ?? "Päivitetään tietoja. "
	} else if (operationName.includes("update") || operationName.includes("delete") || operationName.includes("create")) {
		switch (true) {
			case operationName.includes("update"):
				message = "päivitetään tietoja. "
				break
			case operationName.includes("create"):
				message = "Luodaan tietoja. "
				break
			case operationName.includes("delete"):
				message = "Poistetaan tietoja. "
				break
		}
	}
	if (message != "") toast.loading(message, { role: "global", toastId: "apolloQueryToast" })
}

const dataLoggerLink = new ApolloLink((operation: any, forward) => {
	setDataLoadingToast(operation)

	return forward(operation).map((response) => {
		if (response) {
			const data = response.data
			if (data) {
				const keys = Object.keys(data)
				for (const key in keys) {
					const queryName = keys[key].toLowerCase()
					const message = getQueryMessage(queryName, response)
					if (message != "") {
						toast.update("apolloQueryToast", {
							isLoading: false,
							render: message,
							autoClose: 5000,
							type: response.errors ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
							closeOnClick: true
						})
					}
				}
			} else {
				toast.update("apolloQueryToast", {
					isLoading: false,
					render: "Tietojen päivityksessä tapahtui virhe",
					autoClose: 5000,
					type: response.errors ? toast.TYPE.ERROR : toast.TYPE.SUCCESS,
					closeOnClick: true
				})
			}
		}
		return response
	})
})

export default dataLoggerLink
