import axios from "axios"
import authToken from "../../utils/authToken"
import axiosClient from "../axiosClient/axiosClient"

const getAllCountries = async () => {
	try {
		const res = await axios({
			method: "GET",
			url: "https://data.stat.fi/api/classifications/v2/classifications/valtio_2_20120101/classificationItems?content=data&format=json&lang=fi"
		})
		const countries = []
		for (let i = 0, len = res.data.length; i < len; i++) {
			countries.push({ countryCode: res.data[i].code, country: res.data[i].classificationItemNames[0].name })
		}
		return countries
	} catch (error) {
		console.error(error)
		return null
	}
}

const refreshToken = async () => {
	try {
		const tokenRes = await axiosClient.get("/api/refreshToken")
		authToken.setToken(tokenRes.data.token)
		return tokenRes
	} catch (error) {
		console.error(error)
		authToken.deleteToken
		window.location.reload()
		return null
	}
}

const sendInviteToApp = async (email: string) => {
	try {
		const sendRes = await axiosClient({
			method: "POST",
			url: "/api/email/sendInviteToTievahti",
			data: { email: email }
		})
		return sendRes
	} catch (error) {
		console.error(error)
		return null
	}
}

export { getAllCountries, refreshToken, sendInviteToApp }
