const propertiesSorting = (properties: any, setProperties: any) => {
	let tempproperties: any[] = [...properties]
	if (tempproperties) {
		tempproperties = tempproperties.sort((a: { mmlPropertyId: string }, b: { mmlPropertyId: string }) => {
			if (a?.mmlPropertyId == null) return -1
			if (b?.mmlPropertyId == null) return 1
			const aArr = a.mmlPropertyId.split("-")
			const bArr = b.mmlPropertyId.split("-")
			const length = aArr > bArr ? aArr.length : bArr.length
			for (let i = 0; i < length; i++) {
				if (Number(aArr[i]) > Number(bArr[i]) || bArr[i] === undefined) {
					return 1
				}
				if (Number(aArr[i]) < Number(bArr[i]) || aArr[i] === undefined) {
					return -1
				}
			}
			return 0
		})
	}
	setProperties(tempproperties)
}

export const establishmentSortingbyMmlPropertyId = (properties: any, setProperties: any) => {
	let tempproperties: any[] = [...properties]
	if (tempproperties) {
		tempproperties = tempproperties.sort(
			(a: { propertyByPropertyId: { mmlPropertyId: string } }, b: { propertyByPropertyId: { mmlPropertyId: string } }) => {
				if (a?.propertyByPropertyId?.mmlPropertyId == null) return -1
				if (b?.propertyByPropertyId?.mmlPropertyId == null) return 1
				const aArr = a.propertyByPropertyId.mmlPropertyId.split("-")
				const bArr = b.propertyByPropertyId.mmlPropertyId.split("-")
				const length = aArr > bArr ? aArr.length : bArr.length
				for (let i = 0; i < length; i++) {
					if (Number(aArr[i]) > Number(bArr[i]) || bArr[i] === undefined) {
						return 1
					}
					if (Number(aArr[i]) < Number(bArr[i]) || aArr[i] === undefined) {
						return -1
					}
				}
				return 0
			}
		)
	}
	setProperties(tempproperties)
}

export default propertiesSorting
